import { Oauth2Scheme } from '~auth/runtime'

const DEFAULTS = {
  token: {
    property: 'id_token'
  }
}

export default class appleId extends Oauth2Scheme {
  constructor (auth, options, ...defaults) {
    super(auth, options, ...defaults, DEFAULTS)
  }

  async login (params) {
    const response = await window.AppleID.auth.signIn()
      .catch((error) => {
        this.$auth.callOnError(error, { method: 'login' })
        return Promise.reject(error)
      })

    const token = getProp(response.authorization, this.options.token.property)

    if (!token || !token.length) {
      return
    }
    this.token.set(token)
  }

  fetchUser () {
  }

  async logout () {
    await this.$auth.reset()
  }

  mounted () {
    const { tokenExpired, refreshTokenExpired } = this.check(true)
    if (refreshTokenExpired || (tokenExpired && this.options.autoLogout)) {
      this.$auth.reset()
    }
    this.requestHandler.initializeRequestInterceptor(
      this.options.endpoints.token
    )
    return this.$auth.fetchUserOnce()
  }

  check (checkStatus) {
    const response = {
      valid: false,
      tokenExpired: false,
      refreshTokenExpired: false,
      isRefreshable: true
    }
    const token = this.token.sync()
    this.refreshToken.sync()

    if (!token) {
      return response
    }

    if (!checkStatus) {
      response.valid = true
    }

    return response
  }
}

// from runtime.js
function getProp (holder, propName) {
  if (!propName || !holder || typeof holder !== 'object') {
    return holder
  }
  if (propName in holder) {
    return holder[propName]
  }
  const propParts = Array.isArray(propName)
    ? propName
    : (propName + '').split('.')
  let result = holder
  while (propParts.length && result) {
    result = result[propParts.shift()]
  }
  return result
}
