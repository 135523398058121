export const state = () => ({
  shareUrl: '',
  showInstructorModal: false
})

export const getters = {
  shareUrl: state => state.shareUrl,
  showInstructorModal: state => state.showInstructorModal
}

export const mutations = {
  setShareUrl (state, value) {
    state.shareUrl = value
  },
  setShowInstructorModal (state, value) {
    state.showInstructorModal = value
  }
}

export const actions = {
  async postSimulatorFlight ({ commit }, { args }) {
    return await this.$axios.$post('/user/simulator/', args).then((response) => {
      if (response?.share_url) {
        commit('setShareUrl', response?.share_url || '')
        commit('setShowInstructorModal', true)
      }
      return response
    })
  },
  async patchSimulatorFlight ({ commit }, { postId, args }) {
    return await this.$axios.$patch(`/user/simulator/${postId}/`, args).then((response) => {
      return response
    })
  }
}
