
import Button from '~/components/global/Button.vue'

export default {
  name: 'ConfirmEdit',
  components: { Button },
  computed: {
    open () {
      return this.$store.state.modals.editPostConfirm.open
    },
    post () {
      return this.$store.state.modals.editPostConfirm.post
    }
  },
  methods: {
    editPost () {
      const url = this.post.type === 'simulator'
        ? `/posts/simulator/edit/${this.post.id}`
        : `/posts/edit/${this.post.id}`

      this.$router.push(url)
      this.toggle()
    },
    toggle () {
      this.$store.commit('toggleModalState', { key: 'editPostConfirm', value: false })
    }
  }
}
