
import { mapActions, mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import AirplaneItem from './AirplaneItem'
import Button from '@/components/global/Button'
import InputWithIcon from '@/components/global/Form/InputWithIcon'

export default {
  name: 'SelectEditAirplaneType',
  components: {
    AirplaneItem,
    Button,
    InputWithIcon
  },
  props: {
    airplane: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      selectedAirplane: null,
      id: null,
      search: ''
    }
  },
  computed: {
    ...mapGetters('aircraft-type', {
      aircraftsCount: 'getCount',
      aircrafts: 'getItems'
    })
  },
  watch: {
    airplane (value) {
      if (value?.type) {
        this.selectedAirplane = this.airplane
      }
    }
  },
  mounted () {
    this.id = uuidv4()
  },
  created () {
    this.fetchAircrafts()
  },
  methods: {
    handleInput (value) {
      this.fetchAircrafts(value)
    },
    ...mapActions('aircraft-type', [
      'fetchAircrafts'
    ]),
    onSelectedAirplane (airplane) {
      this.selectedAirplane = airplane
    }
  }
}
