export const state = () => ({
  count: 0,
  items: [],
  response: {}
})

export const getters = {
  getItems: state => state.items,
  getCount: state => state.count
}

export const mutations = {
  setItems (state, value) {
    state.items = value
  },

  setCount (state, value) {
    state.count = value
  },

  setResponse (state, value) {
    state.response = value
  }
}

export const actions = {
  async fetchAircrafts ({ commit }, q = '') {
    await this.$axios.$get('/aircraft-type/', {
      params: {
        q
      }
    })
      .then((response) => {
        commit('setItems', response.results || {})
        commit('setCount', response.count || 0)
        commit('setResponse', response || {})
      })
  },
  async addAircraft ({ commit, dispatch }, data) {
    return await this.$axios.$post('/aircraft-type/', data)
      .then((res) => {
        dispatch('fetchAircrafts')
        return res
      })
  },
  async getAircraft ({ commit }, id) {
    return await this.$axios.$get(`/aircraft-type/${id}/`)
      .then(res => res)
  },
  async patchAircraft ({ commit, dispatch }, { id, data }) {
    return await this.$axios.$patch(`/aircraft-type/${id}/`, data)
      .then((res) => {
        dispatch('fetchAircrafts')
        return res
      })
  }
}
