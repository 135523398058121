
export default {
  name: 'DropZone',
  data () {
    return {
      active: false,
      inActiveTimeout: null
    }
  },
  methods: {
    setActive () {
      this.active = true
      clearTimeout(this.inActiveTimeout)
    },
    setInactive () {
      this.inActiveTimeout = setTimeout(() => {
        this.active = false
      }, 50)
    },
    onDrop (e) {
      this.setInactive()
      this.$emit('files-dropped', [...e.dataTransfer.files])
    },
    preventDefaults (e) {
      e.preventDefault()
    }
  }
}
