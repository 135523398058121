
import FacebookIcon from '~/assets/images/icons/FacebookIcon.svg?inline'
import InstagramIcon from '~/assets/images/icons/InstagramIcon.svg?inline'
import TwitterIcon from '~/assets/images/icons/TwitterIcon.svg?inline'
import YoutubeIcon from '~/assets/images/icons/YoutubeIcon.svg?inline'

const menus = [
  {
    name: 'NAVIGATION',
    items: [
      { name: 'Home', href: '/' },
      { name: 'Features', href: '/features' },
      { name: 'Status', href: 'https://following.statuspage.io/' },
      { name: 'Create an Account', href: '/register' },
      { name: 'Download App', href: '#', target: '_blank' }
    ]
  },
  {
    name: 'ABOUT US',
    items: [
      { name: 'About Us', href: '/about-us' },
      { name: 'Privacy Policy', href: '/privacy-policy' },
      { name: 'Terms of Service', href: '/terms-of-service' },
      { name: 'Feedback', href: 'https://feedback.followingpilots.com/' },
      { name: 'Support', href: 'https://support.followingpilots.com/' }
    ]
  }
]

export default {
  name: 'HomeFooter',
  components: {
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    YoutubeIcon
  },
  data () {
    return {
      currentYear: new Date().getFullYear(),
      menus
    }
  }
}
