
import { simulatorTypes } from '@/config/simulator-types'
import OptionPicker from '@/components/manual-flight/OptionPicker'

export default {
  name: 'SimulatorType',
  components: {
    OptionPicker
  },
  props: {
    selected: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      simulatorTypes
    }
  },
  methods: {
    onSelected (value) {
      this.$emit('select', value)
    },
    close () {
      this.$emit('select', this.selected)
      this.$emit('cancel')
    }
  }
}
