
import Button from '~/components/global/Button.vue'
import CheckIcon from '~/components/my-profile/CheckIcon.vue'

export default {
  name: 'CopyField',
  components: { Button },
  props: {
    url: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      disabled: false
    }
  },
  methods: {
    copyText () {
      navigator.clipboard.writeText(this.url)
      if (this.isCoppied) {
        this.$toast.success('The URL has been copied to the clipboard', {
          icon: CheckIcon
        })
      }
      this.disabled = true
    },
    async isCoppied () {
      const text = await navigator.clipboard.readText().then(() => {
        this.disabled = false
      })

      return text === this.link
    },
    selectAllText () {
      this.$refs.input.select()
      this.copyText()
    }
  }
}
