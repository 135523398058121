
import { mapActions, mapMutations } from 'vuex'
import { datesToDecimal, substractTwoDates } from '@/utils/date'
import { parseEndpointError } from '@/utils/helpers'

import CheckIcon from '@/components/my-profile/CheckIcon'
import ErrorIcon from '@/components/my-profile/ErrorIcon'
import InputWithLabel from '@/components/global/Form/InputWithLabel'
import SelectInstructor from '@/components/select-instructor/index.vue'
import SimpleTextarea from '@/components/global/Form/SimpleTextarea'
import TimeSelector from '@/components/manual-flight/TimeSelector/index'

export default {
  name: 'AddGroundTime',
  components: {
    InputWithLabel,
    SelectInstructor,
    SimpleTextarea,
    TimeSelector
  },
  data () {
    return {
      endTime: '',
      instructor: null,
      instructionTime: 0,
      modals: {
        time: false
      },
      notes: '',
      startTime: '',
      submitButtonActive: true,
      submitButtonText: 'Save',
      substractTwoDates
    }
  },
  methods: {
    ...mapActions('user/ground-time', [
      'postGroundTime'
    ]),
    ...mapMutations([
      'setModalData'
    ]),
    handleEndDate (value) {
      this.endTime = value
      this.instructionTime = parseFloat(datesToDecimal(this.endTime, this.startTime)).toFixed(1)
    },
    handleStartDate (value) {
      this.startTime = value
      this.instructionTime = parseFloat(datesToDecimal(this.endTime, this.startTime)).toFixed(1)
    },
    async handleSubmit () {
      if (!this.submitButtonActive) {
        return
      }

      const args = {
        notes: this.notes,
        ground_instruction_time: parseFloat(this.instructionTime).toFixed(1)
      }

      if (this.startTime instanceof Date) {
        args.start_time = this.startTime.toISOString()
      }

      if (this.endTime instanceof Date) {
        args.end_time = this.endTime.toISOString()
      }

      if (this.startTime instanceof Date && this.endTime instanceof Date) {
        args.total_time = parseFloat(datesToDecimal(this.endTime, this.startTime)).toFixed(1)
      }

      if (this.instructor) {
        args.signature = {
          instructor_id: this.instructor?.id,
          instructor_number: this.instructor?.instructor_number,
          certificate_expiration: this.instructor?.certificate_expiration
        }
      }

      this.submitButtonActive = false
      this.submitButtonText = 'Saving...'

      try {
        await this.postGroundTime({ args })
          .then((res) => {
            if (this.instructor && res?.share_url) {
              this.setModalData({
                key: 'instructorModal',
                value: {
                  open: true,
                  data: {
                    shareUrl: res.share_url,
                    title: 'Ground time Posted'
                  }
                }
              })
            } else {
              this.$toast.success('Ground time has been added successfully.', {
                icon: CheckIcon
              })
            }

            this.$emit('close')
          })
          .catch((e) => {
            const error = e?.response?.data ? parseEndpointError(e?.response?.data) : (e.message || 'Something went wrong')

            this.$toast.error(error, {
              icon: ErrorIcon
            })
          })
      } catch (e) {
        this.$toast.error(e, {
          icon: ErrorIcon
        })
      } finally {
        this.submitButtonActive = true
        this.submitButtonText = 'Save'
      }
    },
    onSelectInstructor (value) {
      this.instructor = value
    }
  }
}
