import { parseEndpointError } from '~/utils/helpers'

export const state = () => ({
  challenges: {}
})

export const getters = {
  getChallenges: state => state.challenges
}

export const mutations = {
  setChallenges (state, value) {
    state.challenges = value
  }
}

export const actions = {
  async fetchChallenges ({ commit }) {
    await this.$axios.$get('/challenges/')
      .then((response) => {
        commit('setChallenges', response || {})
      }).catch((e) => {
        const error = parseEndpointError(e.response.data)

        return {
          success: false,
          message: error
        }
      })
  }
  // async fetchUserChallenges ({ commit }, query) {
  //   await this.$axios.$get('/challenges/')
  //     .then((response) => {
  //       commit('setChallenges', response || {})
  //     }).catch((e) => {
  //       const error = parseEndpointError(e.response.data)

  //       return {
  //         success: false,
  //         message: error
  //       }
  //     })
  // }
}
