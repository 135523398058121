import { VuexPersistence } from 'vuex-persist'

export default ({ store }) => {
  new VuexPersistence({
    modules: [
      'auth',
      'index',
      'feed',
      'notificationsWebSocket',
      'user/aircraft',
      'user/certificate'
    ]
  }).plugin(store)
}
