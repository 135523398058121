export const state = () => ({
  count: 0,
  people: [],
  response: {}
})

export const getters = {
  getPeople: state => state.people,
  getCount: state => state.count,
  getResponse: state => state.response
}

export const mutations = {
  setPeople (state, value) {
    state.people = value
  },

  setCount (state, value) {
    state.count = value
  },

  setResponse (state, value) {
    state.response = value
  }
}

export const actions = {
  async fetchPeople ({ commit, state }, { params = {}, loadNext = false }) {
    let url = '/user/public/search/'
    if (loadNext && state.response?.next) {
      url = state.response.next
    }

    await this.$axios.$get(url, { params })
      .then((response) => {
        if (loadNext) {
          commit('setPeople', [...state.people, ...response.results])
        } else {
          commit('setPeople', response?.results || [])
        }

        commit('setCount', response.count || 0)
        commit('setResponse', response || {})
      })
  }
}
