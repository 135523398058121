
import { mapActions } from 'vuex'
import Button from '~/components/global/Button.vue'
import CheckIcon from '~/components/my-profile/CheckIcon'

export default {
  name: 'DeletePostComment',
  components: { Button },
  computed: {
    open () {
      return this.$store.state.modals.deletePostComment.open
    },
    comment () {
      return this.$store.state.modals.deletePostComment.comment
    },
    post () {
      return this.$store.state.modals.deletePostComment.post
    },
    isChild () {
      return this.$store.state.modals.deletePostComment.isChild
    }
  },
  methods: {
    ...mapActions('user/post/comment', [
      'deleteComment'
    ]),
    onDeleteConfirm () {
      this.deleteComment({
        postId: this.post,
        commentId: this.comment,
        isChildComment: this.isChild,
        context: this
      })
        .then(() => {
          this.toggle(false)
          this.$toast.success('Comment deleted', {
            icon: CheckIcon
          })
        })
    },
    toggle (state) {
      this.$store.commit('toggleModalState', { key: 'deletePostComment', value: state })
    }
  }
}
