
export default {
  name: 'NavigationItemPrimary',
  props: {
    icon: {
      type: String,
      default: 'add'
    }
  }
}
