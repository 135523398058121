
import { mapActions } from 'vuex'
import Button from '~/components/global/Button.vue'

export default {
  name: 'DeletePost',
  components: { Button },
  computed: {
    open () {
      return this.$store.state.modals.deletePost.open
    },
    post () {
      return this.$store.state.modals.deletePost.post
    },
    itemType () {
      return this.post && this.post.flights?.length ? 'flight' : 'post'
    }
  },
  methods: {
    ...mapActions('user/post/', [
      'deletePost'
    ]),
    onDeleteConfirm () {
      this.deletePost({ postId: this.post.id, context: this }).then((resp) => {
        this.toggle(false)
      })
    },
    toggle (state) {
      this.$store.commit('toggleModalState', { key: 'deletePost', value: state })
    }
  }
}
