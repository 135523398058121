export const state = () => ({
  count: 0,
  posts: [],
  response: {}
})

export const getters = {
  getPosts: state => state.posts,
  getCount: state => state.count,
  getNext: state => state.response?.next
}

export const mutations = {
  setPosts (state, value) {
    state.posts = value
  },

  setCount (state, value) {
    state.count = value
  },

  setResponse (state, value) {
    state.response = value
  },
  deletePost (state, { postId, context }) {
    if (context && context.$store && context.$store.state) {
      context.$store.state.feed.feed = context.$store.state.feed.feed.filter((post) => {
        return postId !== post.id
      })
    }
  }
}

export const actions = {
  async fetchPosts ({ commit, state }) {
    let url = '/user/post/?limit=20&offset=0'
    if (state.response?.next) {
      url = state.response.next
    }

    return await this.$axios.$get(url)
      .then((response) => {
        commit('setCount', response.count || 0)
        commit('setResponse', response || {})

        if (state.response?.next) {
          commit('setPosts', [...state.posts, ...response.results])
        } else {
          commit('setPosts', response.results)
        }

        return response
      })
  },
  async addPost ({ commit }, { args }) {
    return await this.$axios.$post('/user/post/', args).then((response) => {
      return response
    })
  },
  async reportPost ({ commit }, params) {
    return await this.$axios.$post('/abuse/', params).then((response) => {
      return response
    })
  },
  async deletePost ({ commit }, { postId, context }) {
    return await this.$axios.$patch(`/user/post/${postId}/`, {
      deleted: true
    }).then((response) => {
      commit('deletePost', { postId, context })
      return response
    })
  },
  async patchPost ({ commit }, { postId, args }) {
    return await this.$axios.$patch(`/user/post/${postId}/`, args).then((response) => {
      return response
    })
  }
}
