
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'SimpleTextarea',
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
      default: ''
    },
    textareaClass: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      id: null
    }
  },
  mounted () {
    this.id = uuidv4()
  },
  methods: {
    handleInput (e) {
      this.$emit('input', e.target.value)
    }
  }
}
