export const gearConfig = [
  {
    value: 'tricycle',
    label: 'Tricycle'
  },
  {
    value: 'tailwheel',
    label: 'Tail Wheel'
  },
  {
    value: 'amphibian',
    label: 'Amphibian'
  },
  {
    value: 'floats',
    label: 'Floats'
  },
  {
    value: 'skids',
    label: 'Skids'
  },
  {
    value: 'skis',
    label: 'Skis'
  },
  {
    value: 'tandem',
    label: 'Tandem'
  },
  {
    value: 'other',
    label: 'Other'
  },
  {
    value: 'mono_wheel',
    label: 'Mono Wheel'
  },
  {
    value: 'none',
    label: 'None'
  }
]
