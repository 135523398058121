import { snakeCaseToTitle, parseEndpointError } from '@/utils/helpers'

export const userGetters = {
  homeAirportIdentifier: state => state.auth.user.home_airport?.identifier,

  homeAirportCity: state => state.auth.user.home_airport?.city,

  homeAirportName: state => state.auth.user.home_airport?.name,

  currentAirport: state => state.auth.user.current_airport || null,

  currentAirportCheckinTime: state => state.auth.user.current_airport_checkin_time || 0,

  currentAirportName: state => state.auth.user.home_airport?.name,

  getFullName: state => state.auth.user?.first_name + ' ' + state.auth.user?.last_name,

  pictureUrl: state => state.auth.user?.picture_url,

  firstName: state => state.auth.user?.first_name,

  followersCount: state => state.auth.user?.followers_count,

  followingCount: state => state.auth.user?.following_count,

  lastName: state => state.auth.user?.last_name,

  loggedInUser: state => state.auth.loggedIn || false,

  preferences: state => state.auth.user.preferences,

  userEmail: state => state.auth?.email,

  userBio: state => state.auth.user?.bio,

  userCity: state => state.auth.user.city || '',

  userId: state => state.auth.user.id || 0,

  user: state => state.auth.user || {},

  username: state => state.auth.user?.username
}

export const userMutations = {
  user (state, user) {
    state.auth.loggedIn = !!user
    state.auth.user = user || {}
  },

  removeUserAvatar (state) {
    state.auth.user.picture_url = null
  },
  setFollowersCount (state, followersCount) {
    state.auth.user.followers_count = followersCount
  },
  setFollowingsCount (state, followingCount) {
    state.auth.user.following_count = followingCount
  },
  setCurrentAirport (state, currentAirport) {
    state.auth.user.current_airport = currentAirport
  }

}

export const userActions = {
  async deleteUserAvatar ({ commit }) {
    return await this.$axios.delete('/user/profile/picture/')
      .then(() => {
        commit('removeUserAvatar')
        return {
          success: true,
          message: 'Profile picture deleted successfully'
        }
      }).catch((e) => {
        return {
          success: false,
          message: e.message
        }
      })
  },
  async userLogin ({ commit }, { login, password }) {
    return await this.$axios.post('/user/login/', {
      login,
      password
    })
  },
  async uploadUserAvatar ({ commit }, formData) {
    return await this.$axios.post('/user/profile/picture/', formData)
      .then((response) => {
        commit('user', response.data)
        return {
          success: true,
          message: 'Profile picture uploaded successfully'
        }
      }).catch((e) => {
        const data = e.response.data
        const values = Object.keys(data).map(key => `${snakeCaseToTitle(key)}: ${data[key]}`)
        const error = [].concat.apply([], values).join(', ')

        return {
          success: false,
          message: error
        }
      })
  },
  async updateSettings ({ commit, state }, data) {
    let successMessage = 'Settings updated successfully'
    if (data?.successMessage) {
      successMessage = data.successMessage
    }

    return await this.$axios.patch('/user/profile/', data)
      .then((response) => {
        commit('user', response.data)
        return {
          success: true,
          message: successMessage
        }
      }).catch((e) => {
        const error = parseEndpointError(e.response.data)

        return {
          success: false,
          message: error
        }
      })
  },
  async updatePrivacySetting ({ commit, dispatch }, { key, value }) {
    const settings = {}
    settings[key] = value

    const data = {
      preferences: settings
    }

    return await dispatch('updateSettings', data)
  },
  async updateUserAirport ({ commit, state }, airport) {
    return await this.$axios.patch('/user/profile/', {
      current_airport_id: airport.id
    })
      .then((response) => {
        commit('user', response.data)
        return {
          success: true,
          message: 'Settings updated successfully'
        }
      }).catch((e) => {
        const error = parseEndpointError(e.response.data)
        return {
          success: false,
          message: error
        }
      })
  },

  async getUser ({ commit }, { userId }) {
    return await this.$axios.$get(`/user/public/${userId}/profile/`)
  },
  async followUser ({ commit, state }, userId) {
    return await this.$axios.$post('/user/following/', {
      user_id: userId
    }).then((response) => {
      return {
        data: response,
        success: true,
        message: 'Follow request sent'
      }
    }).catch((e) => {
      const error = parseEndpointError(e.response.data)

      return {
        success: false,
        message: error
      }
    })
  },
  async unfollowUser ({ commit, state }, userId) {
    return await this.$axios.$delete(`/user/following/${userId}/`).then((response) => {
      return {
        data: response,
        success: true,
        message: 'Unfollowed successfully'
      }
    }).catch((e) => {
      const error = parseEndpointError(e.response.data)

      return {
        success: false,
        message: error
      }
    })
  },
  async blockUser ({ commit, state }, userId) {
    return await this.$axios.$post('/user/blocked/', {
      blocked_id: userId
    }
    ).then(() => {
      return {
        success: true,
        message: 'Blocked successfully'
      }
    }).catch((e) => {
      const error = parseEndpointError(e.response.data)
      return {
        success: false,
        message: error
      }
    })
  },
  async reportUser ({ commit, state }, params) {
    return await this.$axios.$post('/abuse/',
      params
    ).then(() => {
      return {
        success: true,
        message: 'Blocked successfully'
      }
    }).catch((e) => {
      const error = parseEndpointError(e.response.data)
      return {
        success: false,
        message: error
      }
    })
  }

}
