export const imageFileTypes = [
  'image/jpeg',
  'image/pjpeg',
  'image/png'
]

export function validFileType ({ file, fileTypes }) {
  return fileTypes.includes(file?.type)
}

export function fileProvider () {
  let files = []

  function getFiles () {
    return files
  }
  function addFiles (newFiles) {
    const newUploadableFiles = [...newFiles].map((file) => {
      if (file?.guid) {
        file.id = file.guid
        file.name = file?.caption || ''
        file.file = file?.url || ''
        return file
      }
      return new UploadableFile(file)
    }).filter(file => !fileExists(file.id))
    files = files.concat(newUploadableFiles)
  }

  function fileExists (otherId) {
    return files.some(({ id }) => id === otherId)
  }

  function removeFile (file) {
    const index = files.indexOf(file)

    if (index > -1) { files.splice(index, 1) }
  }

  return { getFiles, addFiles, removeFile }
}

class UploadableFile {
  constructor (file) {
    this.file = file
    this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`
    this.type = file.type.startsWith('video/') ? 'video' : 'image'
    this.url = URL.createObjectURL(file)
    this.status = null
  }
}
