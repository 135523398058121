
import { mapActions } from 'vuex'
import success from './success.vue'

const ReportReasons = [
  'Misleading or spam', 'Bullying or offensive', 'Sexually inappropriate', 'Political'
]
export default {
  name: 'ReportAbuse',
  components: { success },

  data () {
    return {
      ReportReasons,
      successOpen: false
    }
  },
  computed: {
    open () {
      return this.$store.state.modals.report.open
    },
    user () {
      return this.$store.state.modals.report.user
    },
    post () {
      return this.$store.state.modals.report.post
    },
    comment () {
      return this.$store.state.modals.report.comment
    },
    itemType () {
      return this.$store.state.modals.report.type
    }
  },
  methods: {
    ...mapActions('user/post', [
      'reportPost'
    ]),
    ...mapActions('user/post/comment', [
      'reportComment'
    ]),
    ...mapActions([
      'reportUser'
    ]),
    report (reason) {
      if (this.itemType === 'post') {
        this.reportPost({
          ...(this.user && { user: this.user.id }),
          ...(this.post && { post: this.post.id }),
          category: reason.toLowerCase()
        }).then(() => {
          this.toggle(false)
          this.successOpen = true
        })
      }
      if (this.itemType === 'user') {
        this.reportUser({
          ...(this.user && { user: this.user.id }),
          category: reason.toLowerCase()
        }).then(() => {
          this.toggle(false)
          this.successOpen = true
        })
      }
      if (this.itemType === 'comment') {
        this.reportComment({
          ...(this.user && { user: this.user.id }),
          ...(this.comment && { comment: this.comment }),
          category: reason.toLowerCase()
        }).then(() => {
          this.toggle(false)
          this.successOpen = true
        })
      }
    },
    toggle (state) {
      this.$store.commit('toggleModalState', { key: 'report', value: state })
    }
  }
}
