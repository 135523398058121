
import { mapActions, mapGetters } from 'vuex'
import InputWithIcon from '@/components/global/Form/InputWithIcon'
import FriendsTagItem from '@/components/global/FriendsTagItem'
import Spinner from '@/components/global/Spinner'

export default {
  name: 'TagFriendModal',
  components: {
    InputWithIcon,
    FriendsTagItem,
    Spinner
  },
  props: {
    friendsTagProp: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      friendsTag: [],
      people: [],
      keyword: '',
      loading: true
    }
  },
  computed: {
    ...mapGetters('user/following', {
      items: 'getFollowing'
    })
  },
  created () {
    this.friendsTag = this.friendsTagProp
    this.fetchFollowing().then(() => {
      this.loading = false
    })
  },
  methods: {
    ...mapActions('user/following', [
      'fetchFollowing'
    ]),
    async search (keyword) {
      await this.fetchFollowing(keyword)
    },
    onSelected (item) {
      const existingItemIndex = this.friendsTag.findIndex(existingItem => existingItem.id === item.id)

      if (existingItemIndex > -1) {
        this.friendsTag.splice(existingItemIndex, 1)
      } else {
        this.friendsTag.push(item)
      }

      this.$emit('friendTags', this.friendsTag)
    },
    isSelected (id) {
      const items = this.friendsTag.filter(item => id === item.id)

      return items.length > 0
    }
  }
}
