
export default {
  name: 'Button',
  props: {
    href: {
      required: false,
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'button' // button, submit
    },
    id: {
      type: String,
      default: null,
      required: false
    },
    name: {
      type: String,
      default: null,
      required: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: 'sm',
      validator: size => ['xs', 'sm', 'md', 'lg', 'xl'].includes(size)
    },
    typeView: {
      type: String,
      default: 'primary', // primary, ghost, nude
      validator: typeView => ['primary', 'ghost', 'nude', 'tab'].includes(typeView)
    },
    state: {
      type: String,
      default: 'disabled',
      validator: state => ['enabled', 'disabled'].includes(state)
    },
    outline: Boolean,
    icon: Boolean,
    round: Boolean
  },
  computed: {
    sizeClasses () {
      const borderRadius = this.typeView === 'tab' ? 'rounded-full' : null
      const sizeMappings = {
        xs: `h-7 text-11px leading-4 px-4 ${borderRadius ?? 'rounded-lg'}`,
        sm: `h-9 text-13px leading-5 px-4 ${borderRadius ?? 'rounded-[10px]'}`,
        md: `h-10 text-15px leading-6 h-10 px-4 ${borderRadius ?? 'rounded-xl'}`,
        lg: `h-12 text-15px leading-6 h-12 px-4 py-3 ${borderRadius ?? 'rounded-xl'}`,
        xl: `h-[54px] text-15px leading-6 h-12 px-4 py-3.5 ${borderRadius ?? 'rounded-2xl'}`
      }

      return sizeMappings[this.size] || sizeMappings.sm
    },
    typesViewClasses () {
      const color = this.color
      const state = this.state
      const typesViewMappings = {
        primary: `bg-${color} text-white border-${color} hover:bg-white hover:text-${color}`,
        ghost: `bg-white text-${color} border-${color} hover:bg-${color} hover:text-white`,
        nude: `text-${color} border-transparent px-0 h-auto hover:decoration-1`,
        tab: (() => {
          if (state === 'enabled') {
            return `bg-${color} bg-opacity-10 border-${color} text-${color} hover:bg-${color} hover:text-white rounded-full`
          } else {
            return `bg-neutral-100 border-neutral-100 text-neutral-800 hover:bg-${color} hover:text-white rounded-full`
          }
        })()
      }

      return typesViewMappings[this.typeView] || typesViewMappings.primary
    },
    btnClasses () {
      return `${this.sizeClasses} ${this.typesViewClasses}`
    },
    buttonType () {
      if (this.href) {
        return 'NuxtLink'
      } else {
        return 'button'
      }
    }
  }
}
