
export const state = () => ({
  stats: {},
  response: {},
  error: false
})

export const getters = {
  stats: state => state.stats,
  getResponse: state => state.response,
  error: state => state.error
}

export const mutations = {
  setStats (state, value) {
    state.stats = value
  },

  setResponse (state, value) {
    state.response = value
  },
  setError (state, value) {
    state.error = value
  }
}

export const actions = {
  async fetchStats ({ commit }, userId) {
    await this.$axios.$get(`/user/public/${userId}/stats/`)
      .then((response) => {
        commit('setStats', response || {})
        commit('setResponse', response || {})
      }).catch(() => {
        commit('setStats', {})
        commit('setResponse', null)
        commit('setError', true)
      })
  }
}
