
import CheckIcon from '~/components/my-profile/CheckIcon.vue'

export default {
  name: 'AccountMenuItem',
  props: {
    linkUrl: {
      type: String,
      default: '#'
    },
    linkText: {
      type: String,
      default: 'Menu Item'
    },
    linkIcon: {
      type: String,
      default: 'account'
    },
    linkColor: {
      type: String,
      default: ''
    },
    linkAction: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleClick () {
      if (this.linkAction === 'share') {
        this.copyText(`https://followingpilots.com${this.linkUrl}`)
      }
    },
    copyText (val) {
      navigator.clipboard.writeText(val)
      if (this.isCoppied) {
        this.$toast.success('The profile URL has been copied to the clipboard', {
          icon: CheckIcon
        })
      }
    },
    async isCoppied () {
      const text = await navigator.clipboard.readText()
      return text === this.link
    }
  }
}
