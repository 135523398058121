export const state = () => ({
  data: {},
  feed: [],
  nextUrl: null,
  prevUrl: null,
  count: 0
})

export const getters = {
  getFeed: state => state.feed,
  getNextUrl: state => state.nextUrl
}

export const mutations = {
  setCount: (state, count) => {
    state.count = count
  },
  setData: (state, data) => {
    state.data = data
  },
  setFeed: (state, data) => {
    state.feed = data
  },
  setNextUrl: (state, nextUrl) => {
    state.nextUrl = nextUrl
  },
  setPrevUrl: (state, prevUrl) => {
    state.prevUrl = prevUrl
  },
  addPost (state, value) {
    state.feed.unshift(value)
  },
  addReaction (state, { reaction, postId }) {
    state.feed.forEach((post, index) => {
      if (post.id !== postId) {
        return
      }

      state.feed[index].reactions.push(reaction)
    })
  },
  deleteReaction (state, { postId, userId }) {
    state.feed.forEach((post, index) => {
      if (post.id !== postId) {
        return
      }

      state.feed[index].reactions.forEach((reaction, rIndex) => {
        if (reaction.user.id === userId) {
          state.feed[index].reactions.splice(rIndex, 1)
        }
      })
    })
  },
  updatePost (state, post) {
    state.feed.forEach((p, index) => {
      if (p.id !== post.id) {
        return
      }

      state.feed[index] = post
    })
  }
}

export const actions = {
  async fetchFeed ({ commit, state }, loadNext) {
    let url = '/feed/?offset=0&limit=20'
    if (loadNext && state.nextUrl) {
      url = state.nextUrl
    }

    return await this.$axios.$get(url).then((response) => {
      commit('setCount', response.count || 0)
      commit('setData', response || {})
      commit('setNextUrl', response?.next)
      commit('setPrevUrl', response?.previous)

      if (loadNext) {
        commit('setFeed', [...state.feed, ...response.results])
      } else {
        commit('setFeed', response.results)
      }

      return response
    })
  },
  pushPost ({ commit }, { post }) {
    commit('addPost', post)
  },
  updatePost ({ commit }, { post }) {
    commit('updatePost', post)
  }
}
