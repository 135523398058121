
import { required } from 'vuelidate/lib/validators'
import { parseEndpointError } from '~/utils/helpers'
import Alert from '~/components/global/Alert'
import Email from '~/components/auth/fields/Email'
import Or from '~/components/auth/Or'
import SocialProviders from '~/components/auth/SocialProviders'

export default {
  name: 'LoginForm',
  components: {
    Alert,
    Email,
    Or,
    SocialProviders
  },
  props: {
    centered: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  auth: 'guest',
  data () {
    return {
      submitted: false,
      loading: false,
      password: '',
      showPassword: false,
      passwordId: 'current-password',
      error: []
    }
  },
  validations: {
    password: { required }
  },
  methods: {
    toggleShow () {
      this.showPassword = !this.showPassword
    },
    async login () {
      this.submitted = true
      this.loading = true

      this.$v.$touch()
      if (this.$v.$invalid) {
        this.loading = false
        return
      }

      await this.$auth.loginWith('local', {
        data: {
          login: this.$store.state.auth.email,
          password: this.password
        }
      }).then(async (res) => {
        const token = res.data.token

        await this.$auth.setUserToken(token, this.$auth.strategy.refreshToken)
        const user = await this.$auth.fetchUser()
        if (user.data) {
          await this.$auth.setUser(user.data)
        }

        // if redirect available
        if (this.$store.state?.signinRedirect) {
          // this.$router.push(this.$store.state.signinRedirect)
          window.location.href = this.$store.state.signinRedirect
        } else {
          this.$router.push('/feed/')
          // window.location.href = '/feed/'
        }
      }).catch((e) => {
        // eslint-disable-next-line
        console.error('Error', e)
        if (e.response) {
          this.error = e?.response?.data ? parseEndpointError(e.response.data) : (e?.message || 'Something went wrong.')
        } else {
          this.error = 'Something went wrong. Please try again later.'
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
