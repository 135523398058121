
import { mapActions } from 'vuex'
import { aircraftClasses } from '@/config/aircraft-classes'
import { gearConfig as getGearConfigs } from '@/config/gear-config'
import { engineTypes } from '@/config/engine-types'
import InputWithLabel from '@/components/global/Form/InputWithLabel'
import Modal from '@/components/global/Modal'
import SelectNative from '@/components/global/Form/SelectNative'
import ErrorIcon from '@/components/my-profile/ErrorIcon'

export default {
  name: 'AddAirplaneModal',
  components: {
    InputWithLabel,
    Modal,
    SelectNative
  },
  data () {
    return {
      modal: false,
      make: '',
      shortName: '',
      model: '',
      gearConfig: '',
      aircraftClass: '',
      engineType: '',
      engineTypes,
      getGearConfigs,
      aircraftClasses
    }
  },
  methods: {
    ...mapActions('aircraft-type', [
      'addAircraft'
    ]),
    handleSubmit () {
      if (this.make === '') {
        this.$toast.error('Make field is required', {
          icon: ErrorIcon
        })
        return
      }

      if (this.model === '') {
        this.$toast.error('Model field is required', {
          icon: ErrorIcon
        })
        return
      }

      if (this.gearConfig === '') {
        this.$toast.error('Gear config is required', {
          icon: ErrorIcon
        })
        return
      }

      if (this.aircraftClass === '') {
        this.$toast.error('Aircraft class is required', {
          icon: ErrorIcon
        })
        return
      }

      if (this.engineType === '') {
        this.$toast.error('Engine type is required', {
          icon: ErrorIcon
        })
        return
      }

      this.addAircraft({
        make: this.make,
        model: this.model,
        short_name: this.shortName,
        gear_config: this.gearConfig,
        aircraft_class: this.aircraftClass,
        engine_type: this.engineType
      }).then((res) => {
        this.$emit('select', res)
        this.modal = false
      })
    },
    handleGearConfig (value) {
      this.gearConfig = value
    },
    handleAircraftClass (value) {
      this.aircraftClass = value
    },
    handleEngineType (value) {
      this.engineType = value
    }
  }
}
