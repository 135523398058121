
import CheckIcon from '@/assets/images/icons/check-blue-bg.svg?inline'

export default {
  name: 'SmallCheckbox',
  components: { CheckIcon },
  props: {
    option: {
      type: String,
      default: ''
    },
    selected: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    }
  }
}
