
import { datesToDecimal, substractTwoDates } from '@/utils/date'

import { privacy } from '@/config/privacy'
import AddNewAircraft from '@/components/my-profile/MyAirplanes/AddNewAircraft'
import AddLogbook from '@/components/manual-flight/AddLogbook'
import AddLogbookDetails from '@/components/manual-flight/AddLogbook/Details'
import ChooseAirplane from '@/components/manual-flight/ChooseAirplane'
import ChooseAirport from '@/components/manual-flight/ChooseAirport'
import CreatePost from '@/components/feed/create-post'
import Modal from '@/components/global/Modal'
import RouteField from '@/components/manual-flight/RouteField'
import ShareWith from '@/components/manual-flight/ShareWith'
import TimeSelector from '@/components/manual-flight/TimeSelector/index'
export default {
  name: 'ManualFlight',
  components: {
    AddLogbook,
    AddLogbookDetails,
    AddNewAircraft,
    ChooseAirplane,
    ChooseAirport,
    CreatePost,
    Modal,
    RouteField,
    ShareWith,
    TimeSelector
  },
  data () {
    return {
      approaches: [],
      substractTwoDates,
      step: 1,
      aircraft: null,
      instructor: null,
      privacy,
      shareWith: privacy[0],
      startTime: '',
      endTime: '',
      airports: {
        from: null,
        to: null
      },
      flightOptions: [],
      modals: {
        addNewAircraft: false,
        chooseAircraft: false,
        chooseFromAirport: false,
        chooseToAirport: false,
        chooseViaAirport: false,
        time: false,
        shareWith: false
      },
      options: [],
      ccFlight: 'no',
      showCreatePost: false,
      flightNumber: '',
      notes: '',
      routes: [],
      showRouteField: false
    }
  },
  methods: {
    getData () {
      return {
        approaches: this.approaches,
        aircraft: this.aircraft,
        shareWith: this.shareWith,
        airports: this.airports,
        options: this.options,
        startTime: this.startTime,
        endTime: this.endTime,
        ccFlight: this.ccFlight,
        flightOptions: this.flightOptions,
        flightNumber: this.flightNumber,
        notes: this.notes,
        instructor: this.instructor,
        routes: this.routes
      }
    },
    updateAircraft (aircraft) {
      this.aircraft = aircraft
      this.modals.chooseAircraft = false
    },
    updateDestinationAirport (airport) {
      this.airports.to = airport
    },
    updateFromAirport (airport) {
      this.airports.from = airport
    },
    handleStartDate (value) {
      this.startTime = value
    },
    handleEndDate (value) {
      this.endTime = value
    },
    handeOptions (value) {
      this.options = value
    },
    handeFiTime (value) {
      this.fiTime = value
    },
    handleCcFlight (value) {
      this.ccFlight = value
    },
    updateShareWith (value) {
      const selected = this.privacy.filter(item => item.label === value)
      if (selected.length > 0) {
        this.shareWith = selected[0]
      }
    },
    handleSubmit () {
      this.step = 4
      this.showCreatePost = true
    },
    handleFlightOptions (values) {
      this.flightOptions = values
      this.step = 3
    },
    goBack () {
      this.step = 3
      this.showCreatePost = false
    },
    timeInDecimals () {
      return datesToDecimal(this.endTime, this.startTime)
    },
    handleFlightNumber (value) {
      this.flightNumber = value
    },
    handleNotes (value) {
      this.notes = value
    },
    handleInstructor (value) {
      this.instructor = value
    },
    setApproaches (approaches) {
      this.approaches = approaches
    },
    setAirplaneType (airplaneType) {
      this.aircraft = airplaneType
    },
    onRouteInput (value) {
      this.routes = value
    }
  }
}
