
import AvatarImage from '@/components/global/Avatar/AvatarImage'
export default {
  name: 'AvatarWithInfo',
  components: {
    AvatarImage
  },
  props: {
    fullName: {
      type: String,
      default: ''
    },
    imgUrl: {
      type: String,
      default: ''
    },
    linkToProfile: {
      type: String,
      required: true
    }
  }
}
