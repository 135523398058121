import { parseEndpointError } from '~/utils/helpers'

export const state = () => ({
  businessLocation: null,
  businessLocationFeed: [],
  businessLocationOverview: null
})

export const getters = {
  getBusinessLocationFeed: state => state.businessLocationFeed,
  getBusinessLocation: state => state.businessLocation,
  getBusinessLocationOverview: state => state.businessLocationOverview
}

export const mutations = {
  setBusinessLocationFeed: (state, feed) => {
    state.businessLocationFeed = feed
  },
  setBusinessLocation: (state, businessLocation) => {
    state.businessLocation = businessLocation
  },
  setBusinessLocationOverview: (state, businessLocationOverview) => {
    state.businessLocationOverview = businessLocationOverview
  }
}
export const actions = {
  async fetchBusinessLocation ({ commit }, locationId) {
    try {
      return await this.$axios.$get(`/business-locations/${locationId}/`).then((response) => {
        commit('setBusinessLocation', response || {})
        return response
      })
    } catch (e) {
      throw parseEndpointError(e.response.data)
    }
  },
  follow ({ commit }, locationId) {

  }

}
