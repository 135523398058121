

export default {
  name: 'ImageContent',
  props: {
    imgUrl: {
      type: String,
      default: 'https://placehold.jp/3d4070/ffffff/36x36.png'
    }
  }
}
