
import copyField from './copy-field.vue'
import OverlayInfo from './overlay-info.vue'
import { getAircraftData } from '@/utils/helpers'
import InstagramIcon from '~/assets/sprite/svg/instagram.svg?inline'

const baseUrl = 'https://followingpilots.com/'
export default {
  name: 'Share',
  components: { copyField, OverlayInfo, InstagramIcon },
  computed: {
    open () {
      return this.$store.state.modals.share.open
    },
    post () {
      return this.$store.state.modals.share.post
    },
    user () {
      return this.$store.state.modals.share.user
    },
    type () {
      return this.$store.state.modals.share.user ? 'profile' : 'post'
    },
    flight () {
      if (this.post && this.post.flights) { return this.post.flights[0] } return null
    },
    info () {
      if (this.flight) {
        return [{
          value: `${this.flight?.total_time}h`,
          label: 'Time'
        }, {
          value: `${this.flight.distance} mi`,
          label: 'Distance'
        }, {
          value: `${this.flight.speed} kt`,
          label: 'Speed'
        }]
      } else {
        return [
          {
            value: '0 mi',
            label: 'Distance'
          },
          {
            value: '0m',
            label: 'Time'
          },

          {
            value: '0 kt',
            label: 'Speed'
          }
        ]
      }
    },
    flightData () {
      if (this.post && this.flight) {
        return {
          departure: this.flight?.takeoff_airport?.identifier || '',
          arrival: this.flight?.landing_airport?.identifier || '',
          plane: this.getAirplaneData(this.flight?.aircraft || '')
        }
      } else {
        return null
      }
    },
    postId () {
      return this.post.id
    },
    shareURL () {
      if (this.type === 'post') {
        return `${baseUrl}posts/${this.postId}/`
      } else {
        return `${baseUrl}${this.type}/${this.type === 'post' ? this.postId : this.user.username}/`
      }
    }
  },
  mounted () {
    const onEscape = (e) => {
      if (this.open && e.key === 'Escape') {
        this.toggle(false)
      }
    }
    // eslint-disable-next-line nuxt/no-globals-in-created
    window.document.addEventListener('keydown', onEscape)
    this.$once('hook:destroyed', () => {
      // eslint-disable-next-line nuxt/no-globals-in-created
      window.document.removeEventListener('keydown', onEscape)
    })
  },
  methods: {
    getAirplaneData (aircraft) {
      if (!aircraft) {
        return ''
      }

      const aircraftData = getAircraftData(aircraft)

      return aircraftData?.planeName
    },
    shareOnFacebook () {
      const url = 'https://www.facebook.com/sharer/sharer.php?u=' + this.shareURL
      window.open(url, '_blank')
    },
    shareOnTwitter () {
      const url = 'https://twitter.com/intent/tweet?url=' + this.shareURL
      window.open(url, '_blank')
    },
    shareOnWhatsApp () {
      const url = 'whatsapp://send?text=' + this.shareURL
      window.open(url, '_blank')
    },
    toggle () {
      this.$store.commit('toggleModalState', { key: 'share', value: false })
    }
  }
}
