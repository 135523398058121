
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'ToggleWithLabel',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      id: null
    }
  },
  computed: {
    hasSlotContentClass () {
      return this.$slots.default ? 'ml-auto' : ''
    }
  },
  mounted () {
    this.id = uuidv4()
  },
  methods: {
    handleInput (e) {
      this.$emit('input', e.target.checked)
    }
  }
}
