
import { mapActions, mapGetters, mapMutations } from 'vuex'
import * as tus from 'tus-js-client'

import { allowedFileTypes } from '@/config/drop-zone'
import { privacy } from '@/config/privacy'
import { parseEndpointError, pluralize } from '@/utils/helpers'
import { fileProvider } from '@/helpers/files'

import AirportCard from '~/components/global/AirportCard'
import AvatarImage from '@/components/global/Avatar/AvatarImage'
import SelectButton from '@/components/feed/create-post/SelectButton'
import Modal from '@/components/global/Modal'
import FilesPlaceholder from '@/components/global/FilesPlaceholder'
import DropZone from '@/components/global/DropZone'
import TagFriendModal from '@/components/global/TagFriendModal'
import SelectMenuItem from '@/components/feed/create-post/SelectMenuItem'
import Button from '@/components/global/Button'
import ManualFlightCard from '@/components/feed/create-post/ManualFlightCard'
import ErrorIcon from '~/components/my-profile/ErrorIcon'
import Textarea from '@/components/feed/create-post/Textarea'

const { getFiles, addFiles, removeFile } = fileProvider()

export default {
  name: 'CreatePost',
  components: {
    AvatarImage,
    SelectButton,
    Modal,
    FilesPlaceholder,
    DropZone,
    TagFriendModal,
    AirportCard,
    SelectMenuItem,
    Button,
    ManualFlightCard,
    Textarea
  },
  props: {
    isManualFlight: {
      type: Boolean,
      default: false
    },
    manualFlightData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      allowedFileTypes,
      privacy,
      imageFields: false,
      text: '',
      files: [],
      selected: {},
      menuItems: [],
      modals: {
        newPostModal: false,
        visiblilityMoadl: false,
        friendsTagModal: false,
        locationModal: false
      },
      candidates: [],
      location: {},
      phrase: '',
      selectedFriends: [],
      bounceTimer: null,
      submitButtonActive: true,
      pluralize
    }
  },
  computed: {
    ...mapGetters([
      'firstName',
      'getFullName',
      'pictureUrl'
    ]),
    canBeSubmitted () {
      if (!this.submitButtonActive) {
        return true
      }

      return !(this.text.length > 0 || this.files.length > 0 || this.selectedFriends.length > 0 || this.location?.gps_code)
    }
  },
  mounted () {
    const active = privacy.filter(item => item.isActive)
    this.selected = active && active.length && active[0]
    this.menuItems = [...privacy]

    if (this.isManualFlight) {
      this.showModal(true)
      this.setSelected(this.manualFlightData.shareWith?.label)
    }
  },
  methods: {
    ...mapMutations([
      'setModalData'
    ]),
    closeModal () {
      this.modals.newPostModal = false
      this.$emit('close')
    },
    setFriends (val) {
      this.selectedFriends = val
    },
    showModal (val = true) {
      this.modals.newPostModal = val
    },
    selectPhotos () {
      this.$refs.uploadPhotos.click()
    },
    onInputChange (e) {
      addFiles(e.target.files)
      this.files = getFiles()
      e.target.value = null
    },
    onAddFiles (e) {
      addFiles(e)
      this.files = getFiles()
    },
    removeFile (e) {
      removeFile(e)
      this.files = getFiles()
    },
    async uploadFile (file, url) {
      const formData = new FormData()
      formData.append('file', file.file)
      return await fetch(url, { method: 'POST', body: formData })
    },
    goToPrev () {
      for (const modal in this.modals) {
        if (modal !== 'newPostModal') {
          this.modals[modal] = false
        }
      }
    },
    onClickOutside () {
      this.isActive = false
    },
    setSelected (options) {
      const activeIndex = this.menuItems.findIndex(item => item.label === options)
      this.selected = this.menuItems[activeIndex]

      this.menuItems.map((item) => {
        item.isActive = item.label === options
        return item
      })
    },
    async search (name = '') {
      const { data: { results } } = await this.$axios.get('/airports/', { params: { limit: 20, q: name } })
      this.candidates = results
    },
    onSelected (airport) {
      if (this.location?.id === airport?.id) {
        this.location = {}
      } else {
        this.location = airport
        this.goToPrev()
      }
    },
    triggerSearch () {
      clearTimeout(this.bounceTimer)
      this.bounceTimer = setTimeout(() => this.search(this.phrase), 500)
    },
    openLocationModal () {
      this.phrase = ''
      this.candidates = []
      this.search('')
      this.modals.locationModal = true
    },
    ...mapActions('feed', [
      'fetchFeed',
      'pushPost'
    ]),
    ...mapActions('user/flight', [
      'postFlight',
      'patchFlight'
    ]),
    async handlePost () {
      if (this.isManualFlight) {
        await this.handleManualFlightPost()
        return
      }

      if (
        this.text.length === 0 &&
        this.files.length === 0 &&
        this.selectedFriends.length === 0 &&
        !this.location?.id
      ) {
        return
      }

      this.submitButtonActive = false

      const date = new Date()

      const args = {
        text: this.text,
        privacy: this.selected.slug,
        type: 'manual',
        time: date.toISOString()
      }

      const airportId = this.location?.id || 0
      if (airportId !== 0) {
        args.airport_tag = airportId
      }

      if (this.selectedFriends.length > 0) {
        args.tag_users = this.selectedFriends.map(friend => friend.username)
      }

      let postId = 0
      let post

      await this.$axios.$post('/user/post/', args).then((response) => {
        postId = response.id
        post = response
        this.text = ''
        this.location = {}
        this.candidates = []
      })

      const fetchFeed = () => {
        this.fetchFeed().then(() => this.showModal(false))
      }

      if (this.files.length > 0) {
        const token = this.$auth.strategy.token.get()
        const userToken = token.split(' ')[1]
        const args = {
          endpoint: `${this.$config.axios.browserBaseURL}/user/post/${postId}/photo/`,
          headers: {
            'X-Client': 'following-pilots-client (web v1)',
            Authorization: `Token ${userToken}`
          },
          metadata: {},
          onSuccess: () => fetchFeed()
        }

        for (const file of this.files) {
          args.metadata = {
            filename: file.name,
            filetype: file.type,
            caption: file.name
          }

          const upload = new tus.Upload(file.file, args)
          upload.findPreviousUploads().then((previousUploads) => {
            if (previousUploads.length) {
              upload.resumeFromPreviousUpload(previousUploads[0])
            }

            upload.start()
          })
        }
      } else {
        this.showModal(false)
        this.pushPost({
          post
        })
      }
    },
    async handleManualFlightPost () {
      if (
        this.text.length === 0 &&
        this.files.length === 0 &&
        this.selectedFriends.length === 0 &&
        !this.location?.id &&
        this.manualFlightData.airports.from === null &&
        this.manualFlightData.airports.to === null &&
        typeof this.manualFlightData.aircraft.type === 'undefined'
      ) {
        return
      }

      this.submitButtonActive = false

      const date = new Date()

      const args = {
        aircraft_id: this.manualFlightData.aircraft.id,
        takeoff_airport: this.manualFlightData.airports.from?.id,
        landing_airport: this.manualFlightData.airports.to?.id,
        notes: this.manualFlightData.notes,
        post: {
          text: this.text,
          privacy: this.selected.slug,
          type: 'manual',
          time: date.toISOString()
        }
      }

      if (this.manualFlightData.startTime instanceof Date) {
        args.start_time = this.manualFlightData.startTime.toISOString()
      }

      if (this.manualFlightData.endTime instanceof Date) {
        args.end_time = this.manualFlightData.endTime.toISOString()
      }

      const airportId = this.location?.id || 0
      if (airportId !== 0) {
        args.post.airport_tag = airportId
      }

      if (this.selectedFriends.length > 0) {
        args.post.tag_users = this.selectedFriends.map(friend => friend.username)
      }

      if (this.manualFlightData.routes.length > 0) {
        args.intermediate_airports = this.manualFlightData.routes.join(',')
      }

      if (this.manualFlightData.approaches.length > 0) {
        args.approaches = this.manualFlightData.approaches.map((approach) => {
          return {
            type: approach
          }
        })
      }

      if (this.manualFlightData.instructor) {
        args.signature = {
          instructor_id: this.manualFlightData.instructor?.id,
          instructor_number: this.manualFlightData.instructor?.instructor_number,
          certificate_expiration: this.manualFlightData.instructor?.certificate_expiration
        }
      }

      this.manualFlightData.flightOptions.forEach((option) => {
        if (option.selected) {
          args[option.label] = option.value
        }
      })

      this.manualFlightData.options.forEach((option) => {
        if (option.value > 0) {
          args[option.slug] = option.value
        }
      })

      if (this.manualFlightData.flightNumber !== '') {
        args.flight_number = this.manualFlightData.flightNumber
      }

      let postId = 0
      let shouldContinue = false

      try {
        await this.postFlight({ args })
          .then((response) => {
            postId = response.post_id
            this.text = ''
            this.location = {}
            this.candidates = []
            shouldContinue = true

            if (this.manualFlightData.instructor) {
              this.setModalData({
                key: 'instructorModal',
                value: {
                  open: true,
                  data: {
                    shareUrl: response?.share_url || '',
                    title: 'Manual Flight Posted'
                  }
                }
              })
            }
          })
          .catch((e) => {
            const error = parseEndpointError(e.response.data)

            this.$toast.error(error, {
              icon: ErrorIcon
            })
          })
      } catch (e) {
        this.$toast.error(e, {
          icon: ErrorIcon
        })
        return
      }

      if (!shouldContinue) {
        return
      }

      const fetchFeed = () => {
        this.fetchFeed().then(() => {
          this.showModal(false)
          this.$emit('close')
        })
      }

      if (this.files.length > 0) {
        const token = this.$auth.strategy.token.get()
        const userToken = token.split(' ')[1]
        const args = {
          endpoint: `${this.$config.axios.browserBaseURL}/user/post/${postId}/photo/`,
          headers: {
            'X-Client': 'following-pilots-client (web v1)',
            Authorization: `Token ${userToken}`
          },
          metadata: {},
          onSuccess: () => {
            this.$emit('close')
            fetchFeed()
          }
        }

        for (const file of this.files) {
          args.metadata = {
            filename: file.name,
            filetype: file.type,
            caption: file.name
          }

          const upload = new tus.Upload(file.file, args)
          upload.findPreviousUploads().then((previousUploads) => {
            if (previousUploads.length) {
              upload.resumeFromPreviousUpload(previousUploads[0])
            }

            upload.start()
          })
        }
      } else {
        fetchFeed()
        this.$emit('close')
      }
    }
  }
}
