
export default {
  name: 'Preloader',
  props: {
    size: {
      type: Object,
      default () {
        return {
          h: '4',
          w: '4'
        }
      }
    }
  },
  data () {
    return {
      sizeClasses: [`h-${this.size.h}`, `w-${this.size.w}`]
    }
  }
}
