
import EditAirplaneModal from '@/components/my-profile/MyAirplanes/AddNewAircraft/EditAirplaneModal'

export default {
  name: 'EditAirplane',
  components: {
    EditAirplaneModal
  },
  props: {
    airplaneId: {
      type: Number,
      required: true
    },
    airplane: {
      type: Object,
      default: () => {}
    },
    icon: {
      type: String,
      default: 'pen-outline'
    },
    customClass: {
      type: String,
      default: 'w-5 h-5 text-natural-800 hover:text-primary'
    }
  },
  data () {
    return {
      modal: false
    }
  },
  methods: {
    handleSelected (airplane) {
      this.$emit('select', airplane)
    }
  }
}
