
const moreNavigationItems = [{
  id: 0,
  name: 'Challenges',
  icon: 'trophy',
  url: '/challenges'

},
{
  id: 1,
  name: 'Currency',
  icon: 'ticket-star',
  url: '/currency'

},
{
  id: 2,
  name: 'Endorsements',
  icon: 'verified',
  url: '/endorsement'

},
{
  id: 3,
  name: 'Profile',
  icon: 'account-circle',
  url: '/profile'

}]
export default {
  name: 'NavigationMenuMore',
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      items: moreNavigationItems,
      selected: -1
    }
  },
  methods: {
    onSelect (item) {
      this.$router.push(item.url)
      this.$emit('close')
    }
  }
}
