import { notificationActions, notificationMutations } from '~/store/settings/notifications'
import { userActions, userGetters, userMutations } from '~/store/user'

export const state = () => ({
  auth: {
    email: '',
    loggedIn: false,
    user: {}
  },
  profile: {
    home_airport_name: '',
    home_airport_id: ''
  },
  modals: {
    editPostConfirm: {
      open: false,
      post: {}
    },
    deletePost: {
      open: false,
      post: {}
    },
    deletePostComment: {
      open: false,
      post: {},
      comment: {},
      user: {},
      type: 'comment'
    },
    share: {
      open: false,
      post: {},
      user: {}
    },
    report: {
      type: '',
      open: false,
      post: {},
      user: {},
      comment: {}
    },
    blockUser: {
      open: false,
      user: {}
    },
    instructorModal: {
      open: false,
      data: {}
    }
  },
  signinRedirect: '',
  profileTabIndex: 0,
  isResetPassword: false
})

export const getters = {
  ...userGetters,
  profileTabIndex: state => state.profileTabIndex,
  isResetPassword: state => state.isResetPassword
}

export const mutations = {
  setAuthEmail (state, email) {
    state.auth.email = email
  },

  setAuthFirstName (state, value) {
    state.auth.user.first_name = value
  },

  setAuthLastName (state, value) {
    state.auth.user.last_name = value
  },

  setProfileHomeAirportName (state, value) {
    state.profile.home_airport_name = value
  },

  setProfileHomeAirportId (state, value) {
    state.profile.home_airport_id = value
  },
  toggleModalState (state, data) {
    state.modals[data.key].open = data.value
  },
  setModalData (state, payload) {
    state.modals[payload.key] = payload.value
  },
  setSigninRedirect (state, redirectURL) {
    state.signinRedirect = redirectURL
  },
  setProfileTabIndex (state, tab) {
    state.profileTabIndex = tab
  },
  setIsResetPassword (state, val) {
    state.isResetPassword = val
  },
  ...notificationMutations,
  ...userMutations
}

export const actions = {
  ...notificationActions,
  ...userActions,
  // WILL RUN TO CEHCK USER AUTH and set user DATA
  async nuxtServerInit ({ commit }, { req }) {
    let auth = null
    if (req.headers.cookie) {
      // cookie found
      try {
        const { data } = await this.$axios.post('/user/profile/')
        auth = data
      } catch (err) {
        auth = null
      }
    }
    commit('user', auth)
  }
}
