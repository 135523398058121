
export default {
  name: 'Spinner',
  props: {
    center: {
      required: false,
      type: Boolean,
      default: true
    }
  }
}
