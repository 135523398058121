
import Header from '@/components/header/Header'

export default {
  name: 'Feed',
  components: {
    Header
  },
  mounted () {
    this.$websocket()
  }
}
