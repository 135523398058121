
export default {
  name: 'Modal',
  props: {
    open: {
      type: [Boolean],
      default: false
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'lg',
      validator: size => ['xs', 'md', 'lg', '2lg', 'xl'].includes(size)
    },
    overflow: {
      type: Boolean,
      default: true
    },
    buttonStyle: {
      type: String,
      default: 'close',
      validator: buttonStyle => ['arrowleft', 'close'].includes(buttonStyle)
    },
    backDrop: {
      type: Boolean,
      default: true
    },
    customSizeClasses: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showModal: false,
      showBackdrop: false,
      showContent: false,
      backdropLeaving: false,
      cardLeaving: false
    }
  },
  computed: {
    leaving () {
      return this.backdropLeaving || this.cardLeaving
    },
    sizeClasses () {
      const sizeMappings = {
        xs: 'max-w-[375px]',
        md: 'max-w-[427px]',
        lg: 'max-w-[500px]',
        '2lg': 'max-w-[684px]',
        xl: 'max-w-[1100px]'
      }
      return `${sizeMappings[this.size] || sizeMappings.lg} ${this.overflow ? 'overflow-y-auto' : ''}`
    }
  },
  watch: {
    open: {
      handler (newValue) {
        if (newValue) {
          this.show()
        } else {
          this.close()
        }
      },
      immediate: true
    }
  },
  mounted () {
    const onEscape = (e) => {
      if (this.open && e.key === 'Escape') {
        this.$emit('close')
      }
    }
    // eslint-disable-next-line nuxt/no-globals-in-created
    window.document.addEventListener('keydown', onEscape)
    this.$once('hook:destroyed', () => {
      // eslint-disable-next-line nuxt/no-globals-in-created
      window.document.removeEventListener('keydown', onEscape)
    })
  },
  methods: {
    show () {
      this.showModal = true
      this.showBackdrop = true
      this.showContent = true
    },
    close () {
      this.showModal = false
      this.showBackdrop = false
      this.showContent = false
    }
  }
}
