
import { getColumnName } from '@/utils/logbook'

export default {
  name: 'IncreaseDecrease',
  props: {
    maxValue: {
      type: Number,
      default: 1000
    },
    option: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      getColumnName
    }
  }
}
