import { parseEndpointError } from '~/utils/helpers'

export const state = () => ({
  medical: {},
  flightReview: {},
  cfi: {}
})

export const getters = {
  getMedical: state => state.medical,
  getFlightReview: state => state.flightReview,
  getCFI: state => state.cfi
}

export const mutations = {
  setMedical (state, value) {
    state.medical = value
  },
  setFlightReview (state, value) {
    state.flightReview = value
  },
  setCFI (state, value) {
    state.cfi = value
  }
}

export const actions = {
  async fetchCurrency ({ commit }) {
    await this.$axios.$get('/user/currency/')
      .then((response) => {
        response.results.forEach((result) => {
          switch (result.type) {
            case 'medical':
              commit('setMedical', result || {})
              break
            case 'cfi':
              commit('setCFI', result || {})
              break
            case 'flight-review':
              commit('setFlightReview', result || {})
              break
          }
        })
      })
  },
  async changeBirthday ({ commit, state }, birthdate) {
    birthdate = new Date(birthdate * 1000)
    return await this.$axios.$patch('/user/profile/', {
      birth_date: birthdate.toISOString().split('T')[0]
    }).then(() => {
      return {
        success: true,
        message: 'Updated Birthdate'
      }
    }).catch((e) => {
      const error = parseEndpointError(e.response.data)

      return {
        success: false,
        message: error
      }
    })
  },
  async changeMedicalExamination ({ commit, state }, { classType, examDate, expirationDate }) {
    return await this.$axios.$get('/user/currency/medical/').then(async (medRes) => {
      return await this.$axios.$patch('/user/currency/medical/', {
        currency_class: classType,
        start_date: examDate,
        expiration: expirationDate
      }).then(() => {
        return {
          success: true,
          message: 'Updated Examination Information'
        }
      }).catch((e) => {
        const error = parseEndpointError(e.response.data)

        return {
          success: false,
          message: error
        }
      })
    }).catch(async () => {
      return await this.$axios.$post('/user/currency/', {
        type: 'medical',
        currency_class: classType,
        start_date: examDate,
        expiration: expirationDate
      }).then(() => {
        return {
          success: true,
          message: 'Updated Examination Information'
        }
      }).catch((e) => {
        const error = parseEndpointError(e.response.data)

        return {
          success: false,
          message: error
        }
      })
    })
  },
  async changeFlightReview ({ commit, state }, { reviewDate, expirationDate }) {
    return await this.$axios.$get('/user/currency/flight-review/').then(async () => {
      return await this.$axios.$patch('/user/currency/flight-review/', {
        start_date: reviewDate,
        expiration: expirationDate
      }).then(() => {
        return {
          success: true,
          message: 'Updated Examination Information'
        }
      }).catch((e) => {
        const error = parseEndpointError(e.response.data)

        return {
          success: false,
          message: error
        }
      })
    }).catch(async () => {
      return await this.$axios.$post('/user/currency/', {
        type: 'flight-review',
        currency_class: '',
        start_date: reviewDate,
        expiration: expirationDate
      }).then(() => {
        return {
          success: true,
          message: 'Updated Examination Information'
        }
      }).catch((e) => {
        const error = parseEndpointError(e.response.data)

        return {
          success: false,
          message: error
        }
      })
    })
  },
  async changeCFI ({ commit, state }, { reviewDate, expirationDate }) {
    return await this.$axios.$get('/user/currency/cfi/').then(async () => {
      return await this.$axios.$patch('/user/currency/cfi/', {
        expiration: expirationDate
      }).then(() => {
        return {
          success: true,
          message: 'Updated CFI Information'
        }
      }).catch((e) => {
        const error = parseEndpointError(e.response.data)

        return {
          success: false,
          message: error
        }
      })
    }).catch(async () => {
      return await this.$axios.$post('/user/currency/', {
        type: 'cfi',
        currency_class: '',
        start_date: null,
        expiration: expirationDate
      }).then(() => {
        return {
          success: true,
          message: 'Updated Examination Information'
        }
      }).catch((e) => {
        const error = parseEndpointError(e.response.data)

        return {
          success: false,
          message: error
        }
      })
    })
  }
}
