
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'InputWithLabel',
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    maxlength: {
      type: [Number, String],
      default: 0
    },
    type: {
      type: String,
      default: 'text',
      validator: type => ['text', 'email', 'number'].includes(type)
    }
  },
  data () {
    return {
      id: null
    }
  },
  mounted () {
    this.id = uuidv4()
  },
  methods: {
    handleInput (e) {
      this.$emit('input', e.target.value)
    }
  }
}
