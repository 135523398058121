import moment from 'moment'

export function unixTimeStampToISODate (timestamp) {
  return new Date(timestamp * 1000).toISOString()
}

export function unixTimeStampToLocalDate (timestamp) {
  if (isNaN(timestamp) || timestamp < 0) {
    timestamp = 0
  }
  const date = new Date(timestamp * 1000).toDateString().split(' ')
  return `${date[1]} ${date[2]}, ${date[3]}`
}

export function isSameDayDates (date1, date2) {
  if (!date1 || !date2) {
    return false
  }
  if (
    date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
  ) {
    return true
  }
  return false
}
/**
 *
 * @param {*} days no of days to go back
 * @returns UNIX TS from specified days back
 */
export function goBackInDate (days) {
  if (isNaN(days)) {
    return 0
  }
  if (days === 0) {
    return getDayStartUnixTs(new Date())
  }
  const today = new Date()
  const backDate = new Date(new Date().setDate(today.getDate() - days))
  return getDayStartUnixTs(backDate)
}

export function geThisMondayUnixTs () {
  const weekDay = new Date().getDay()
  const monday = 1
  // it's  a sunday
  if (weekDay === 0) {
    return goBackInDate(6)
  } else {
    return goBackInDate(weekDay - monday)
  }
}

export function getThisSundayUnixTs () {
  const weekDay = new Date().getDay()
  const sunday = 0
  return goBackInDate(weekDay - sunday)
}

export function getLastMondayUnixTs () {
  const weekDay = new Date().getDay()
  const monday = 1
  // it's  a sunday
  if (weekDay === 0) {
    return goBackInDate(13)
  } else {
    return goBackInDate(7 + (weekDay - monday))
  }
}

export function getLastSundayUnixTs () {
  const weekDay = new Date().getDay()
  const sunday = 0
  return goBackInDate((weekDay - sunday) + 7)
}

export function getLastSuturdayUnixTs () {
  const weekDay = new Date().getDay()
  const saturday = 6
  return goBackInDate((weekDay - saturday) + 6)
}

export function getDayEndUnixTs (date) {
  const dayDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  return (dayDate.getTime() / 1000) + 86400
}

export function getDayStartUnixTs (date) {
  const dayDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  return (dayDate.getTime() / 1000)
}
// getting today
export function getTodayDayEndTimeStamp () {
  const now = new Date()
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  // adding 86400 secs to get end of day TS
  return (today.getTime() / 1000) + 86339
}

export function getThisMonthStartUnixTs () {
  const now = new Date()
  const thisMonth = new Date(now.getFullYear(), now.getMonth())
  return thisMonth.getTime() / 1000
}

export function getLastMonthStartUnixTs () {
  const now = new Date()
  const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1)
  return lastMonth.getTime() / 1000
}

export function getLastMonthEndUnixTs () {
  const now = new Date()
  const lastMonth = new Date(now.getFullYear(), now.getMonth())
  return lastMonth.getTime() / 1000
}

export function weeksBetween (d1, d2) {
  return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000))
}

export function allWeeksBetween (startDate, endDate) {
  const dates = []
  const addDays = function (days) {
    const date = new Date(this.valueOf())
    date.setDate(date.getDate() + days)
    return date
  }
  // now our Sunday check
  let currentDate = startDate
  if (currentDate.getDay() > 0) {
    currentDate.setDate(currentDate.getDate() - currentDate.getDay())
  }

  while (currentDate <= endDate) {
    const endWeekDate = addDays.call(currentDate, 6)
    dates.push({
      begin: currentDate,
      end: endWeekDate,
      weekNumber: moment(currentDate.getTime()).week()
      // weekNumber: getWeekNumber(currentDate.getTime())
    })
    currentDate = addDays.call(currentDate, 7)
  }
  return dates
}

export function getWeekNumber (Ts) {
  const date = new Date(Ts)
  const sDate = new Date(date.getFullYear(), 0, 1)
  const days = Math.floor((date - sDate) /
      (24 * 60 * 60 * 1000))
  return Math.ceil(days / 7)
}

export function monthsBetween (d1, d2) {
  const fromYear = d1.getFullYear()
  const fromMonth = d1.getMonth()
  const toYear = d2.getFullYear()
  const toMonth = d2.getMonth()
  const months = []

  for (let year = fromYear; year <= toYear; year++) {
    let monthNum = year === fromYear ? fromMonth : 0
    const monthLimit = year === toYear ? toMonth : 11

    for (; monthNum <= monthLimit; monthNum++) {
      const month = monthNum
      months.push({ year, month })
    }
  }
  return months
}
