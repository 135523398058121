import { pluralize } from '@/utils/helpers'

export function formatDate (inputDate) {
  if (!(inputDate instanceof Date)) {
    return ''
  }

  let date, month

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  date = inputDate.getDate()

  month = inputDate.getMonth()
  month = monthNames[month]

  const year = inputDate.getFullYear()
  let minutes = inputDate.getMinutes()
  if (minutes < 10) {
    minutes = '0' + minutes
  }

  let hours = inputDate.getHours()
  const ampm = hours >= 12 ? 'PM' : 'AM'

  hours = inputDate.getHours() > 12 ? (inputDate.getHours() - 12) : inputDate.getHours()
  if (hours < 10) {
    hours = `0${hours}`
  }

  date = date
    .toString()
    .padStart(2, '0')

  return `${month} ${date}, ${year} at ${hours}:${minutes} ${ampm}`
}

export function setHours (dt, hours, minutes, ampm) {
  if (!(dt instanceof Date)) {
    return dt
  }

  if (hours === '' || minutes === '' || ampm === '') {
    return dt
  }

  if (ampm === 'PM') {
    hours = parseInt(hours) + 12
  }

  dt.setHours(parseInt(hours))
  dt.setMinutes(parseInt(minutes))
  return dt
}

export function millisecondsToTime (milliseconds) {
  const minutes = parseInt((milliseconds / (1000 * 60)) % 60)
  const hours = parseInt((milliseconds / (1000 * 60 * 60)) % 24)
  const days = parseInt((milliseconds / (1000 * 60 * 60 * 24)) % 7)
  let time = ''
  let word = ''

  if (days > 0) {
    word = pluralize(days, 'day')
    time += `${word} `
  }

  word = pluralize(hours, 'hr')
  time += `${word} ${minutes} min`

  return time
}

export function millisecondsToHrsAndMin (milliseconds) {
  const minutes = parseInt((milliseconds / (1000 * 60)) % 60)
  const hours = parseInt((milliseconds / (1000 * 60 * 60)) % 24)

  return `${hours}.${minutes}`
}

export function substractTwoDates (date1, date2) {
  if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
    return ''
  }

  const diff = date1.getTime() - date2.getTime()
  return millisecondsToTime(Math.ceil(diff))
}

export function datesToMinute (date1, date2) {
  if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
    return ''
  }

  const diff = Math.abs(date1.getTime() - date2.getTime())
  return millisecondsToHrsAndMin(Math.ceil(diff))
}

export function datesToDecimal (date1, date2) {
  const t = datesToMinute(date1, date2)
  if (t === '') {
    return ''
  }

  const result = t.split('.')
    .map(val => parseInt(val, 10))
    .reduce((previousValue, currentValue, index, array) => previousValue + currentValue / Math.pow(60, index))

  return result.toFixed(1)
}
