
export default {
  props: {
    airport: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    select () {
      this.$emit('selected', { ...this.airport })
    }
  }
}
