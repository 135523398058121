import { render, staticRenderFns } from "./TagFriendModal.vue?vue&type=template&id=7180e2c4&"
import script from "./TagFriendModal.vue?vue&type=script&lang=js&"
export * from "./TagFriendModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Spinner: require('/opt/buildhome/repo/components/global/Spinner.vue').default,FriendsTagItem: require('/opt/buildhome/repo/components/global/FriendsTagItem.vue').default,Button: require('/opt/buildhome/repo/components/global/Button.vue').default})
