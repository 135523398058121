
import { mapActions, mapGetters } from 'vuex'
import ChooseInstructor from '@/components/endorsements/request/choose-instructor'
import Profile from '~/components/endorsements/profile.vue'

export default {
  name: 'SelectInstructor',
  components: {
    ChooseInstructor,
    Profile
  },
  props: {
    selected: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loading: true,
      modal: false
    }
  },
  computed: {
    ...mapGetters('instructor', {
      instructors: 'getInstructors'
    })
  },
  methods: {
    ...mapActions('instructor', [
      'fetchInstructors'
    ]),
    openInstructorsModal () {
      this.fetchInstructors()
      this.modal = true
    },
    onSelectInstructor (value) {
      this.$emit('select', value)
      this.modal = false
    }
  }
}
