
import Button from '~/components/global/Button.vue'

export default {
  name: 'DeletePostSuccess',
  components: { Button },
  props: {
    open: {
      type: [Boolean],
      default: false
    }
  }
}
