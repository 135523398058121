export const engineTypes = [
  {
    value: 'piston',
    label: 'Piston'
  },
  {
    value: 'jet',
    label: 'Jet'
  },
  {
    value: 'non_powered',
    label: 'Non Powered'
  },
  {
    value: 'turbo_fan',
    label: 'Turbo Fan'
  },
  {
    value: 'turbo_jet',
    label: 'Turbo Jet'
  },
  {
    value: 'turbo_prop',
    label: 'Turbo Prop'
  },
  {
    value: 'turbo_shaft',
    label: 'Turbo Shaft'
  },
  {
    value: 'electric',
    label: 'Electric'
  },
  {
    value: 'other',
    label: 'Other'
  }
]
