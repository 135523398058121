
export default {
  name: 'OptionPicker',
  props: {
    data: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    infinite: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    numberList () {
      const middle = (index) => {
        if (!this.infinite) {
          return index
        }

        const val = this.findNearestIndex(this.data, this.value, Math.floor(this.data.length / 2))

        if (val === -1) {
          return index
        }

        return val
      }

      return [{
        currentIndex: this.value !== '' && this.data.includes(this.value)
          ? middle(this.data.indexOf(this.value))
          : (this.infinite ? Math.floor(this.data.length / 2) : 0),
        flex: 1,
        list: this.data,
        onClick: this.onClick,
        textAlign: 'center',
        className: 'cursor-pointer'
      }]
    }
  },
  methods: {
    onClick (i, index) {
      this.$emit('input', this.data[index])
    },
    findNearestIndex (array, value, startIndex) {
      let leftIndex = startIndex
      let rightIndex = startIndex

      while (leftIndex >= 0 || rightIndex < array.length) {
        if (leftIndex >= 0 && array[leftIndex] === value) {
          return leftIndex
        }

        if (rightIndex < array.length && array[rightIndex] === value) {
          return rightIndex
        }

        leftIndex--
        rightIndex++
      }

      // If the value is not found, return -1
      return -1
    }
  }
}
