
import backgroundImagePath from '~/assets/images/homepage/plane.jpg'
import SocialProviders from '~/components/auth/SocialProviders'

export default {
  name: 'HomeJoin',
  components: {
    SocialProviders
  },
  data () {
    return {
      backgroundImagePath
    }
  }
}
