
import { mapGetters } from 'vuex'

import LogoMain from '~/assets/images/logo-main.svg?inline'
import BellIcon from '~/assets/images/icons/BellIcon.svg?inline'
import SearchField from '~/components/header/SearchField'
import Notify from '~/components/header/Notify'
import Account from '~/components/header/Account'
// import MobileMenu from '@/components/header/MobileMenu'

export default {
  name: 'Header',
  components: {
    LogoMain,
    SearchField,
    Notify,
    BellIcon,
    Account
    // MobileMenu
  },
  props: {
    fullNav: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      siteName: this.title
    }
  },
  computed: {
    ...mapGetters({
      fullName: 'getFullName',
      pictureUrl: 'pictureUrl'
    })
  }
}
