import { savedLogbookColumns, savedLogbookCustomDateRange, saveLogbookDateRange, saveLogbookFiltersInCache } from '~/utils/local-storage'
import { createRequestPayload, getNoOfFiltersApplied, initCustomDateRange, initDateRange, initFilters, getAllowedColumnsForFilter, getViewableColumns, initColumns, getDefaultsForCustomDate, updateViewingOrder } from '~/utils/logbook'

export const state = () => ({
  logbook: [],
  aicraftTypes: [],
  selectedColumns: initColumns(),
  filters: initFilters(),
  dateRange: initDateRange(),
  customDate: initCustomDateRange(),
  mappingData: null,
  // modal states
  filterOpen: false,
  columnsOpen: false,
  exportOpen: false,
  importOpen: false,
  dateOptionsOpen: false,
  grapModalOpen: false,
  importFlights: false
})

export const getters = {

  getLogbook: (state) => { return state.logbook },
  // get All Columns From Logbook
  getAllColumns: (state) => {
    if (state.logbook && state.logbook.length > 0) {
      return Object.keys(state.logbook[0])
    }
    return []
  },
  // get authorized columns
  getAuthorizedCols: (state) => {
    return getViewableColumns(state.getAllColumns())
  },
  // columns to show
  getSelectedCols: (state) => {
    return state.selectedColumns
  },
  getFilters: (state) => {
    return state.filters
  },
  getNoOfAppledFilters: (state) => {
    return getNoOfFiltersApplied(state.filters)
  },
  getAircraftTypes: (state) => {
    return state.aicraftTypes
  },
  getAppliedDateRange: (state) => {
    return state.dateRange
  },
  getColumnsForFiltersModal: (state) => {
    return getAllowedColumnsForFilter().filter((col) => {
      return state.selectedColumns.includes(col)
    })
  },
  getCustomDate: (state) => {
    return state.customDate
  },
  mappingData: (state) => {
    return state.mappingData
  }

}

export const mutations = {

  setLogbook: (state, data) => {
    state.logbook = data
  },
  setFilterOpen: (state, data) => {
    /**
     * @param data -> bool true/false
     */
    state.filterOpen = data || false
  },
  setColumnsOpen: (state, data) => {
    /**
     * @param data -> bool true/false
     */
    state.columnsOpen = data || false
  },
  setExportOpen: (state, data) => {
    state.exportOpen = data || false
  },
  setImportOpen: (state, data) => {
    state.importFlights = data || false
  },
  setDateOptionsOpen: (state, data) => {
    state.dateOptionsOpen = data
  },
  /**
   *
   * @param {*} data array of selected columns
   */
  setSelectedColumns: (state, data) => {
    updateViewingOrder(data)
    state.selectedColumns = data || []
    // when the user updates selected columns, sync with cache
    savedLogbookColumns(data || [])
  },
  /**
   *
   * @param {*} data array { name : state}
   */
  setFilters: (state, data) => {
    state.filters = { ...data }
  },
  /**
   *
   * @param {*} data array of aircraftTypes
   */
  setAircraftTypes: (state, data) => {
    state.aicraftTypes = data
  },
  setAppliedDateSetting: (state, data) => {
    state.dateRange = data
  },
  setCustomDate: (state, data) => {
    state.customDate = data
  },
  setGraphModalOpen: (state, data) => {
    state.grapModalOpen = data
  },
  setMappingData: (state, data) => {
    state.mappingData = data
  }
}

export const actions = {
  /**
   *
   * @param {*} payload parameters of request, add filters to payload
   */
  async fetchLogbook ({ commit, state }) {
    // getting meta for request
    const filters = { ...state.filters } || {}
    const dateRange = state.dateRange
    const customDateRange = { ...state.customDate } || {}
    // creating payload from meta
    const payload = createRequestPayload(filters, dateRange, customDateRange)
    // sending API request
    await this.$axios.$get('/user/logbook/', { params: payload }).then((response) => {
      commit('setLogbook', response.results || [])
    })
  },

  async applyFilters ({ commit, dispatch }, filters) {
    // saving user settings
    await commit('setFilters', filters)
    saveLogbookFiltersInCache(filters)
    dispatch('fetchLogbook')
  },

  async applyDateRange ({ commit, dispatch }, dateRange) {
    // if custom date range selectd, save  start/end date

    if (dateRange.option === 'Custom Date') {
      if (!dateRange.customDate?.startDate?.year || !dateRange.customDate?.endDate?.year) {
        return
      }
      savedLogbookCustomDateRange(dateRange.customDate)
    } else {
      commit('setCustomDate', getDefaultsForCustomDate())
      savedLogbookCustomDateRange(getDefaultsForCustomDate())
    }
    commit('setAppliedDateSetting', dateRange.option)
    saveLogbookDateRange(dateRange.option)

    await dispatch('fetchLogbook')
  },
  async importFligts ({ commit, dispatch }, file) {
    const formData = new FormData()
    formData.append('csv', file, 'import.csv')
    return await this.$axios.$post('/v2/flight/import/', formData)
  },
  async fetchPendingImports ({ commit, dispatch }) {
    return await this.$axios.$get('/v2/flight/import/')
  },
  async mapImportsData ({ commit, dispatch }, mappingData) {
    return await this.$axios.$post(`/v2/flight/import/${mappingData.id}/mapping/`, mappingData).then((response) => {
      return response.imported_aircraft
    })
  }
}
