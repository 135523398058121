
import { mapActions, mapGetters } from 'vuex'
import AirportCard from '~/components/sign/AirportCard'
import InputWithIcon from '@/components/global/Form/InputWithIcon'
import Spinner from '@/components/global/Spinner'

export default {
  name: 'ChooseAirport',
  components: {
    AirportCard,
    InputWithIcon,
    Spinner
  },
  props: {
    selected: {
      type: Object,
      required: false,
      default: () => { }
    }
  },
  data () {
    return {
      selectedAiport: this.selected,
      bounceTimer: null,
      loading: true,
      search: ''
    }
  },
  computed: {
    ...mapGetters('airports', {
      airports: 'getAirports',
      count: 'getCount'
    })
  },
  created () {
    if (this.airports.length === 0) {
      this.fetchAirports({ params: {} }).then(() => {
        this.loading = false
      })
    } else {
      this.loading = false
    }
  },
  methods: {
    ...mapActions('airports', [
      'fetchAirports'
    ]),
    handleInput (value) {
      clearTimeout(this.bounceTimer)
      this.bounceTimer = setTimeout(() => this.fetchAirports({
        params: {
          q: this.search
        }
      }), 500)
      this.$emit('input', value)
    },
    onSelected (airport) {
      this.selectedAiport = airport
      this.$emit('select', airport)
      // this.$emit('cancel')
    },
    close () {
      this.$emit('save', this.selectedAiport)
      this.$emit('select', this.selected)
      this.$emit('cancel')
    }
  }
}
