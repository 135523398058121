
import HomeAppStore from '~/components/homepage/HomeAppStore.vue'
import HomeFooter from '~/components/homepage/HomeFooter.vue'
import HomeJoin from '~/components/homepage/HomeJoin.vue'
import HomeNav from '~/components/homepage/HomeNav.vue'

export default {
  components: {
    HomeFooter,
    HomeAppStore,
    HomeJoin,
    HomeNav
  }
}
