
import AddFlight from './add-flight.vue'
import item from './item.vue'
import MoreMenu from './more-menu.vue'
import PrimaryItem from './primary-item.vue'
const NavigationItems = [
  {
    id: 0,
    name: 'Feed',
    icon: 'home',
    url: '/feed'

  },
  {
    id: 1,
    name: 'Explore',
    icon: 'explore',
    url: '/explore'

  },
  {
    id: 2,
    size: 'lg',
    name: '',
    icon: 'add',
    url: '/',
    primary: true

  },
  {
    id: 3,
    name: 'Logbook',
    icon: 'logbook',
    url: '/logbook'

  },
  {
    id: 4,
    name: 'More',
    icon: 'more-horizontal'
  }
]
export default {
  name: 'FooterMobile',
  components: { item, PrimaryItem, MoreMenu, AddFlight },
  data () {
    return {
      selected: 0,
      items: NavigationItems,
      modals: {
        more: false,
        add: false
      }
    }
  },
  computed: {
    currentRoute () {
      return this.$route.path
    }
  },
  methods: {
    onSelect (item) {
      if (this.currentRoute.includes(item.url) && item.url === '/feed') {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }

      this.selected = item.id
      if (item.id === 4) {
        this.modals.more = true
      } else if (item.id === 2) {
        this.modals.add = true
      } else {
        this.$router.push(item.url)
      }
    },
    updateOpen (value) {
      this.modals.add = value
    }
  }
}
