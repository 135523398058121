export const state = () => ({
  data: {},
  students: [],
  nextUrl: '',
  prevUrl: '',
  count: 0
})
export const getters = {
  getStudents: state => state.students
}

export const mutations = {
  setCount: (state, count) => {
    state.count = count
  },
  setData: (state, data) => {
    state.data = data
  },
  setStudents: (state, data) => {
    state.students = data
  },
  setNextUrl: (state, nextUrl) => {
    state.nextUrl = nextUrl
  },
  setPrevUrl: (state, prevUrl) => {
    state.prevUrl = prevUrl
  },
  addStudent (state, value) {
    state.students.unshift(value)
  },
  updateStudent (state, updatedStudent) {
    state.students = state.students.map((student) => {
      if (updatedStudent.id === student.id) {
        return updatedStudent
      } else {
        return student
      }
    })
  },
  deleteStudent (state, student) {
    state.students = state.students.filter((std) => {
      return student.id !== std.id
    })
  }
}

export const actions = {
  async fetchStudents ({ commit }, offset = 0, limit = 0) {
    await this.$axios.$get('user/student/', {
      params: {
        offset: offset || 0,
        limit: limit || 0
      }
    }).then((response) => {
      commit('setCount', response.count || 0)
      commit('setData', response || {})
      commit('setStudents', response.results || [])
      commit('setNextUrl', response.next || '')
      commit('setPrevUrl', response.previous || '')
    })
  },

  async addStudent ({ commit }, student) {
    return await this.$axios.$post('user/student/', student).then((response) => {
      commit('addStudent', response || {})
    })
  },
  async updateStudent ({ commit }, student) {
    return await this.$axios.$patch(`user/student/${student.id}/`, student).then((response) => {
      commit('updateStudent', response || {})
    })
  },
  async deleteStudent ({ commit }, studetnId) {
    return await this.$axios.$patch(`user/student/${studetnId}/`, {
      deleted: true
    }).then((response) => {
      commit('deleteStudent', response)
    })
  }

}
