
import { formatDate } from '@/utils/date'
export default {
  name: 'ManualFlightCard',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      formatDate
    }
  }
}
