
import { truncateText } from '@/utils/helpers'

import BaseNotificationItem from '@/components/notification/BaseNotificationItem'

export default {
  name: 'NotificationItem',
  components: {
    BaseNotificationItem
  },
  props: {
    isToast: {
      type: Boolean,
      default: false
    },
    notification: {
      type: Object,
      required: true
    }
  },
  methods: {
    parseCommentVerb (notification) {
      let text = `${notification.notification.verb}`
      if (notification.notification?.action_object?.text) {
        text += ` "${truncateText(notification.notification.action_object.text, 20)}"`
      }

      return text
    },
    parseTaggedNotificationVerb (notification) {
      let text = 'tagged you in a post'
      if (notification.notification?.action_object?.text) {
        text += ` "${truncateText(notification.notification.action_object.text, 20)}"`
      }

      return text
    },
    toastClicked (val) {
      this.$emit('toastClicked', val)
    }
  }
}
