export const actions = {
  async postGroundTime ({ commit }, { args }) {
    return await this.$axios.$post('/user/ground-time/', args).then((response) => {
      return response
    })
  },
  async patchGroundTime ({ commit }, { postId, args }) {
    return await this.$axios.$patch(`/user/ground-time/${postId}/`, args).then((response) => {
      return response
    })
  }
}
