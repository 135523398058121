import { render, staticRenderFns } from "./index.vue?vue&type=template&id=84c861b2&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AvatarImage: require('/opt/buildhome/repo/components/global/Avatar/AvatarImage.vue').default,FilesPlaceholder: require('/opt/buildhome/repo/components/global/FilesPlaceholder.vue').default,FilePreview: require('/opt/buildhome/repo/components/global/FilePreview.vue').default,DropZone: require('/opt/buildhome/repo/components/global/DropZone.vue').default,Button: require('/opt/buildhome/repo/components/global/Button.vue').default,Modal: require('/opt/buildhome/repo/components/global/Modal/index.vue').default,Button: require('/opt/buildhome/repo/components/global/Button.vue').default,TagFriendModal: require('/opt/buildhome/repo/components/global/TagFriendModal.vue').default,AirportCard: require('/opt/buildhome/repo/components/global/AirportCard.vue').default})
