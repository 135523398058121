export default function ({ $axios, app, redirect, $config: { baseURL } }) {
  $axios.onRequest((config) => {
    config.headers = {
      'X-Client': 'following-pilots-client (web v1)',
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
    $axios.setBaseURL(baseURL)

    const url = config.url
    const excludedRoutes = [
      '/user/social-login/facebook/',
      '/user/social-login/google-ios/',
      '/user/social-login/apple-id/',
      'https://graph.facebook.com/v6.0/me?fields=id,name,picture{url}'
    ]
    if (config.removeAuthHeader) {
      config.headers.Authorization = ''
    }

    if (!excludedRoutes.includes(url)) {
      const token = app.$auth.strategy.token.get()
      if (typeof token !== 'undefined' && token.length) {
        const userToken = token.split(' ')[1]
        $axios.setToken(userToken, 'Token')
      }
    }
  })

  $axios.onError((error) => {
    if (error.response && error.response.status === 401) {
      const auth = app.$auth
      if (auth.loggedIn) {
        auth.strategy.token.reset()
        auth.getStrategy().logout()
        auth.reset()
        // eslint-disable-next-line no-console
        console.error(error)
        redirect('/login')
      }
    }

    return Promise.reject(error)
  })
}
