import { parseEndpointError } from '@/utils/helpers'

export const state = () => ({
  feedAirport: null,
  airportLeaderboard: null
})

export const getters = {
  feedAirport: state => state.feedAirport,
  airportLeaderboard: state => state.airportLeaderboard
}

export const mutations = {
  setFeedAirport: (state, airport) => {
    state.feedAirport = airport
  },

  setAirportLeaderboard: (state, leaderboard) => {
    state.airportLeaderboard = leaderboard
  },

  setFeedAirportFollowing: (state, following) => {
    if (state.feedAirport) {
      state.feedAirport.followed = following
    }
  }
}

export const actions = {
  async getAirportById ({ commit }, airportId) {
    try {
      return await this.$axios.$get(`/airports/${airportId}/`)
    } catch (e) {
      throw parseEndpointError(e.response.data)
    }
  },

  async getAirports ({ commit }, geoPosition) {
    try {
      const response = await this.$axios.$get('/airports/', { params: { geo_position: geoPosition } })
      return response.results
    } catch (e) {
      throw parseEndpointError(e.response.data)
    }
  },

  async getFollowingAirports ({ commit }, limit = 10) {
    try {
      const response = await this.$axios.$get('/airports/', { params: { followed: true, limit } })
      return response.results
    } catch (e) {
      throw parseEndpointError(e.response.data)
    }
  },

  async getAirportLeaderboard ({ commit }, airportId) {
    try {
      const response = await this.$axios.$get(`/airports/${airportId}/leaderboard/`)
      commit('setAirportLeaderboard', response || null)
      return response
    } catch (e) {
      throw parseEndpointError(e.response.data)
    }
  },

  async followAirport ({ commit }, airportId) {
    try {
      return await this.$axios.$post('/airports/follow/', { airport_id: airportId })
    } catch (e) {
      throw parseEndpointError(e.response.data)
    }
  },

  async unfollowAirport ({ commit }, airportId) {
    try {
      return await this.$axios.$delete(`/airports/${airportId}/unfollow/`)
    } catch (e) {
      throw parseEndpointError(e.response.data)
    }
  },

  async searchAirports ({ commit }, query, limit = 20) {
    try {
      const response = await this.$axios.$get('/airports/', { params: { q: query, limit } })
      return response.results
    } catch (e) {
      throw parseEndpointError(e.response.data)
    }
  }
}
