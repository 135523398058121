import { Device } from '@capacitor/device'

export default async function ({ store, redirect, route }) {
  if (await store.$auth.loggedIn) {
    return redirect('/feed')
  } else if (route.name === 'index') {
    const deviceInfo = await Device.getInfo()
    if (deviceInfo.platform !== 'web') {
      return redirect('/login')
    }
  }
}
