
export const state = () => ({
  data: {},
  endorsementTypes: [],
  nextUrl: '',
  prevUrl: '',
  count: 0
})

export const getters = {
  getEndorsementTypes: state => state.endorsementTypes
}

export const mutations = {
  setCount: (state, count) => {
    state.count = count
  },
  setData: (state, data) => {
    state.data = data
  },
  setEndorsementTypes: (state, data) => {
    state.endorsementTypes = data
  },
  setNextUrl: (state, nextUrl) => {
    state.nextUrl = nextUrl
  },
  setPrevUrl: (state, prevUrl) => {
    state.prevUrl = prevUrl
  },
  addEndorsementType (state, value) {
    state.endorsementTypes.unshift(value)
  }
}

export const actions = {
  async fetchTypes ({ commit }, searchString = null, offset = 0, limit = 0) {
    await this.$axios.$get('endorsement/', {
      params: {
        offset: offset || 0,
        limit: limit || 0,
        q: searchString
      }
    }).then((response) => {
      commit('setCount', response.count || 0)
      commit('setData', response || {})
      commit('setEndorsementTypes', response.results || [])
      commit('setNextUrl', response.next || '')
      commit('setPrevUrl', response.previous || '')
    })
  },
  async fetchTemplate ({ commit }, id, studentId) {
    return await this.$axios.$get(`endorsement/${id}/template/`, {
      student_id: studentId
    })
  }
}
