
import OptionPicker from '@/components/manual-flight/OptionPicker'

export default {
  name: 'PickTime',
  components: {
    OptionPicker
  },
  props: {
    date: {
      type: [String, Date],
      default: ''
    }
  },
  computed: {
    hour () {
      if (!(this.date instanceof Date)) {
        const dateNow = new Date()
        let hours = dateNow.getHours()
        if (hours === 0) {
          this.handleHour('12')
          return '12'
        }

        if (hours > 12) {
          hours = hours - 12
        }
        const parsedHours = hours < 10 ? `0${hours}` : hours.toString()
        this.handleHour(parsedHours)
        return parsedHours
      }

      let hours = this.date.getHours()
      if (hours === 0) {
        this.handleHour('12')
        return '12'
      }

      if (hours > 12) {
        hours = hours - 12
      }

      const parsedHours = hours < 10 ? `0${hours}` : hours.toString()
      this.handleHour(parsedHours)
      return parsedHours
    },
    time () {
      if (!(this.date instanceof Date)) {
        const dateNow = new Date()
        const minutes = dateNow.getMinutes()
        const parsedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString()
        this.handleMinute(parsedMinutes)
        return parsedMinutes
      }

      const minutes = this.date.getMinutes()
      const parsedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString()
      this.handleMinute(parsedMinutes)
      return parsedMinutes
    },
    ampm () {
      if (!(this.date instanceof Date)) {
        const dateNow = new Date()
        const parsedAmPm = dateNow.getHours() > 12 ? 'PM' : 'AM'
        this.handleAmPm(parsedAmPm)
        return parsedAmPm
      }

      const parsedAmPm = this.date.getHours() > 12 ? 'PM' : 'AM'
      this.handleAmPm(parsedAmPm)
      return parsedAmPm
    }
  },
  methods: {
    generateValues (maxValue = 12, ignoreZero = false) {
      return [...Array(maxValue).keys()].map((value) => {
        let number = value + 1
        if (ignoreZero) {
          number = value
        }

        return number < 10 ? `0${number}` : number.toString()
      })
    },
    extendedData (maxValue = 12, ignoreZero = false, array = []) {
      const repeats = 10
      if (array.length === 0) {
        array = this.generateValues(maxValue, ignoreZero)
      }
      let result = []
      for (let i = 0; i < repeats; i++) {
        result = result.concat(array)
      }
      return result
    },
    handleHour (value) {
      this.$emit('hour', value)
    },
    handleMinute (value) {
      this.$emit('minute', value)
    },
    handleAmPm (value) {
      this.$emit('ampm', value)
    }
  }
}
