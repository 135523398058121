
import SelectMenuItem from '@/components/feed/create-post/SelectMenuItem'

export default {
  name: 'SelectButton',
  components: {
    SelectMenuItem
  },
  props: {
    menu: {
      type: Array,
      required: true
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  data () {
    return {
      isActive: false,
      selected: {},
      menuItems: []
    }
  },
  computed: {
    sizeClasses () {
      const sizeMappings = {
        md: 'w-[224px]',
        lg: 'w-[calc(100vw-55px)] sm:w-[360px]'
      }

      return sizeMappings[this.size] || sizeMappings.md
    }
  },
  mounted () {
    const active = this.menu.filter(item => item.isActive)
    this.selected = active && active.length && active[0]
    this.menuItems = [...this.menu]
  },
  methods: {
    onClickOutside () {
      this.isActive = false
    },
    setSelected (options) {
      const activeIndex = this.menuItems.findIndex(item => item.label === options)
      this.selected = this.menuItems[activeIndex]

      this.menuItems.map((item) => {
        item.isActive = item.label === options
        return item
      })
    }
  }
}
