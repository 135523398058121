
import { privacy } from '@/config/privacy'
import OptionPicker from '@/components/manual-flight/OptionPicker'

export default {
  name: 'ShareWith',
  components: {
    OptionPicker
  },
  props: {
    selected: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      privacy
    }
  },
  methods: {
    onSelected (value) {
      this.$emit('select', value)
    },
    close () {
      this.$emit('select', this.selected)
      this.$emit('cancel')
    }
  }
}
