
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AirplaneItem from './AirplaneItem'
import InputWithIcon from '@/components/global/Form/InputWithIcon'
import Spinner from '@/components/global/Spinner'

export default {
  name: 'ChooseAirplane',
  components: {
    AirplaneItem,
    InputWithIcon,
    Spinner
  },
  props: {
    isSimulator: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data () {
    return {
      loading: true,
      search: ''
    }
  },
  computed: {
    ...mapGetters('user/aircraft', {
      count: 'getCount',
      allAircraft: 'getRawAircraft'
    })
  },
  mounted () {
    this.fetchAircraft().then(() => {
      this.loading = false
    })
  },
  methods: {
    ...mapActions('user/aircraft', [
      'fetchAircraft'
    ]),
    ...mapMutations('user/aircraft', [
      'setCount'
    ]),
    aircraft (searchKeyword) {
      const aircraft = this.allAircraft(searchKeyword)

      if (aircraft.length === 0) {
        return []
      }

      if (this.isSimulator) {
        const simulatorAircraft = aircraft?.filter(aircraft => aircraft.is_simulator)
        this.setCount(simulatorAircraft.length)
        return simulatorAircraft
      }

      const realAircraft = aircraft?.filter(aircraft => !aircraft.is_simulator)
      if (realAircraft.length === 0) {
        return []
      }

      this.setCount(realAircraft.length)
      return realAircraft
    },
    handleInput (value) {
      this.$emit('input', value)
    },
    onSelectedAirplane (airplane) {
      this.$emit('select', airplane)
      this.$emit('cancel')
    },
    close () {
      this.$emit('select', this.selectedAirplane)
      this.$emit('cancel')
    }
  }
}
