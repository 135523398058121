export const certifications = [
  {
    name: 'Airplane Pilot Certificates',
    id: 'AIRPLANE',
    licenses: [
      { name: 'Student', value: 'SP', selected: false },
      { name: 'Sport', value: 'SPORT', selected: false },
      { name: 'Recreational', value: 'REC', selected: false },
      { name: 'Private', value: 'PPL', selected: false },
      { name: 'Commercial', value: 'CPL', selected: false },
      { name: 'Instrument', value: 'IFR', selected: false },
      { name: 'CFI', value: 'CFI', selected: false },
      { name: 'CFII', value: 'CFII', selected: false },
      { name: 'ATP', value: 'ATP', selected: false },
      { name: 'Tailwheel', value: 'TAIL', selected: false },
      { name: 'Multi-Engine', value: 'MEL', selected: false }
    ]
  },
  {
    name: 'Helicopter Pilot Certificates',
    id: 'HELICOPTER',
    licenses: [
      { name: 'Student', value: 'SP', selected: false },
      { name: 'Private', value: 'PPL', selected: false },
      { name: 'Commercial', value: 'CPL', selected: false },
      { name: 'ATP', value: 'ATP', selected: false },
      { name: 'Instrument', value: 'IFR', selected: false },
      { name: 'CFI', value: 'CFI', selected: false },
      { name: 'CFII', value: 'CFII', selected: false }
    ]
  },
  {
    name: 'Balloon Pilot Certificates',
    id: 'BALLOON',
    licenses: [
      { name: 'Student', value: 'SP', selected: false },
      { name: 'Private', value: 'PPL', selected: false },
      { name: 'Commercial', value: 'CPL', selected: false },
      { name: 'CFI', value: 'CFI', selected: false }
    ]
  },
  {
    name: 'Others',
    id: 'OTHER',
    licenses: [
      { name: 'Flight Attendant', value: 'FA', selected: false }
    ]
  }
]
