
export default {
  name: 'LazyVideo',
  props: {
    format: {
      type: String,
      default: 'video/mp4'
    },
    poster: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      required: true
    }
  }
}
