export const privacy = [
  {
    isActive: true,
    label: 'Public',
    description: 'Anyone on Following',
    icon: 'globe',
    slug: 'public'
  },
  {
    isActive: false,
    label: 'Followers',
    description: 'People who follow you on Following',
    icon: 'account-group',
    slug: 'friends'
  },
  {
    isActive: false,
    label: 'Only Me',
    description: 'Only show to you privately',
    icon: 'lock',
    slug: 'me'
  }
]
