
import { getColumnName } from '@/utils/logbook'

import ApproachesSelect from '@/components/manual-flight/Approaches/Select'
import CheckboxWithInput from '@/components/simulator-flight/CheckboxWithInput'
import IncreaseDecrease from '@/components/manual-flight/AddLogbook/IncreaseDecrease'
import SelectInstructor from '@/components/select-instructor/index.vue'
import SimpleTextarea from '@/components/global/Form/SimpleTextarea'

const checkboxes = [
  'total_time',
  'dual_received_time',
  'dual_given_time',
  'imc_simulated_time'
]

const increaseDecreaseOptions = [
  'holds'
]

export default {
  name: 'AddLogbook',
  components: {
    ApproachesSelect,
    CheckboxWithInput,
    IncreaseDecrease,
    SelectInstructor,
    SimpleTextarea
  },
  props: {
    time: {
      type: [String, Number],
      default: 0
    }
  },
  data () {
    return {
      approaches: [],
      checkboxesOptions: [],
      instructor: null,
      selected: '',
      value: 0,
      notes: '',
      increaseDecreaseOptions: increaseDecreaseOptions.map((option) => {
        return {
          label: getColumnName(option),
          slug: option,
          value: 0
        }
      })
    }
  },
  mounted () {
    this.checkboxesOptions = checkboxes.map((option) => {
      return {
        value: this.time,
        option,
        label: getColumnName(option),
        selected: option === 'total_time' || option === 'dual_received_time'
      }
    })
  },
  methods: {
    handleInput (value) {
      this.value = value
      this.checkboxesOptions = this.checkboxesOptions.map((option) => {
        if (option.option === this.selected) {
          option.value = value
        }

        return option
      })

      this.$emit('checkboxes', this.checkboxesOptions)
    },
    handleOptionClick (option) {
      this.selected = option
      this.checkboxesOptions = this.checkboxesOptions.map((option) => {
        if (option.option === this.selected) {
          option.selected = !option.selected
        }

        return option
      })
      this.$emit('checkboxes', this.checkboxesOptions)
    },
    onSaveApproaches (approaches) {
      this.approaches = approaches
      this.$emit('approaches', approaches)
    },
    onSelectInstructor (value) {
      this.instructor = value
      this.$emit('instructor', value)
    },
    handleNotes (value) {
      this.notes = value
      this.$emit('notes', value)
    },
    optionExists (slug) {
      return this.increaseDecreaseOptions.filter(option => option.slug === slug).length > 0
    },
    onIncreaseDecreaseAdd (slug) {
      if (!this.optionExists(slug)) {
        return
      }

      this.increaseDecreaseOptions = this.increaseDecreaseOptions.map((option) => {
        if (option.slug === slug) {
          option.value += 1
        }

        return option
      })

      this.$emit('update:increaseDecreaseOptions', this.increaseDecreaseOptions)
    },
    onIncreaseDecreaseDelete (slug) {
      if (!this.optionExists(slug)) {
        return
      }

      this.increaseDecreaseOptions = this.increaseDecreaseOptions.map((option) => {
        if (option.slug === slug && option.value > 0) {
          option.value -= 1
        }

        return option
      })

      this.$emit('update:increaseDecreaseOptions', this.increaseDecreaseOptions)
    }
  }
}
