export const state = () => ({
  count: 0,
  posts: [],
  response: {}
})

export const getters = {
  getPosts: state => state.posts,
  getCount: state => state.count
}

export const mutations = {
  setPosts (state, value) {
    state.posts = value
  },

  setCount (state, value) {
    state.count = value
  },

  setResponse (state, value) {
    state.response = value
  }
}

export const actions = {
  async fetchPosts ({ commit }, { airportId, offset = 0, limit = 0 }) {
    await this.$axios.$get(`/airports/${airportId}/feed/`, {
      params: {
        offset: offset || 0,
        limit: limit || 0
      }
    })
      .then((response) => {
        commit('setPosts', response.results || {})
        commit('setCount', response.count || 0)
        commit('setResponse', response || {})
      })
  }
}
