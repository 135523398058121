

export default {
  name: 'SelectMenuItem',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      required: true
    },
    showActiveIcon: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    iconSizeClasses () {
      const sizeMappings = {
        default: 'w-5 h-5',
        description: 'w-6 h-6'
      }

      return this.description ? sizeMappings.description : sizeMappings.default
    },
    activeState () {
      return this.isActive ? 'bg-primary bg-opacity-5 text-primary' : ''
    }
  }
}
