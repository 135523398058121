
export default {
  name: 'AvatarImage',
  props: {
    size: {
      type: Object,
      default () {
        return {
          h: '10',
          w: '10'
        }
      }
    },
    imgUrl: {
      type: String,
      default: ''
    },
    imgAlt: {
      type: String,
      default: ''
    },
    linkToProfile: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      sizeClasses: [`h-${this.size.h}`, `w-${this.size.w}`]
    }
  }
}
