
export default {
  name: 'Or',
  props: {
    text: {
      type: String,
      required: false,
      default: 'OR'
    }
  }
}
