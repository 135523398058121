import Vue from 'vue'
import Share from '@/components/global/share'
import ConfirmEdit from '@/components/feed/confirm-edit'
import DeletePost from '@/components/feed/delete-post'
import DeletePostComment from '@/components/feed/delete-post-comment'
import ReportAbuse from '@/components/global/report-abuse'
import BlockUser from '@/components/global/block-user'
import InstructorModal from '@/components/feed/create-post/InstructorWarningModal'

const modals = {
  Share,
  ConfirmEdit,
  DeletePost,
  DeletePostComment,
  ReportAbuse,
  BlockUser,
  InstructorModal
}

Object.entries(modals).forEach(([name, component]) => {
  Vue.component(name, component)
})
