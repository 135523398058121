
import AddGroundTime from '@/components/ground-time/index'
import ManualFlight from '@/components/manual-flight/'
import SimulatorFlight from '@/components/simulator-flight/index'

const FlightNavigationItems = [
  {
    id: 0,
    name: 'Add Manual Flight',
    icon: 'plane',
    modal: 'manualFlight'
  },
  {
    id: 1,
    name: 'Add Simulator Flight',
    icon: 'send-filled',
    modal: 'simulatorAdd'
  },
  {
    id: 2,
    name: 'Add Ground Time',
    icon: 'menu-book',
    modal: 'groundTime'
  }
]

export default {
  name: 'NavigationAddFlight',
  components: {
    AddGroundTime,
    ManualFlight,
    SimulatorFlight
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      items: FlightNavigationItems,
      selected: -1,
      modals: {
        groundTime: false,
        manualFlight: false,
        simulatorAdd: false
      }
    }
  },
  methods: {
    backModal (modal) {
      this.modals[modal] = false
      this.$emit('update:open', true)
    }
  }
}
