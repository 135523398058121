import { Device } from '@capacitor/device'
import NotificationItem from '~/components/notification/NotificationItem'

let connected = false

function initConnection (context) {
  if (context.app.$auth.loggedIn) {
    connectToWebSocket(context)
  }
}

const connectToWebSocket = (context) => {
  const {
    app,
    isDev,
    store,
    redirect
  } = context

  if (connected) {
    return
  }

  app.$axios.$post('/auth/otp').then((response) => {
    const ws = new WebSocket(
      `${app.$config.notificationsWebsocketUrl}subscribe`
    )

    ws.onopen = async () => {
      const info = await Device.getInfo()
      response.fcm_token = store.getters['notifications/getFCMToken']
      response.device_type = info.platform
      ws.send(JSON.stringify(response))
      connected = true
      // eslint-disable-next-line no-console
      isDev && console.log('[WebSocket] connection opened')
    }

    ws.onclose = () => {
      initConnection(context)
      // eslint-disable-next-line no-console
      isDev && console.log('[WebSocket] connection closed, reconnecting...')
    }

    ws.onmessage = (response) => {
      const message = JSON.parse(response.data)

      // eslint-disable-next-line no-console
      isDev && console.log('[WebSocket] message received', message)

      if (message.code === 1001) {
        store.commit('notifications/addNotification', message.body)
        store.commit('notifications/increaseCount')
        app.$toast.info(
          {
            component: NotificationItem,
            props: {
              isToast: true,
              notification: store.getters['notifications/getLatestNotification']
            },
            listeners: {
              toastClicked: url => redirect(url)
            }
          },
          {
            containerClassName: 'notification-toast',
            position: 'bottom-left',
            icon: false
          }
        )
      }

      if (message.code === 4000) {
        app.$sentry.captureException(new Error(message.data))
      }

      if (message.code === 5000) {
        connected = false
        initConnection(context)
      }
    }

    ws.onerror = (error) => {
      connected = false
      // eslint-disable-next-line no-console
      isDev && console.error(`[WebSocket] error: ${error}`)
    }
  })
}

export default (context, inject) => {
  inject('websocket', () => initConnection(context))
}
