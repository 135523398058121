export const actions = {
  async uploadPhoto ({ commit }, { postId, type = 'like' }) {
    return await this.$axios.$post(`/user/post/${postId}/photo/`, {
      type
    }).then((response) => {
      commit('addReaction', response)
      return response
    })
  },
  async deletePhoto ({ commit }, { postId, guid }) {
    return await this.$axios.$delete(`/user/post/${postId}/photo/${guid}/`)
      .then((response) => {
        return response
      })
  }
}
