export const state = () => ({
  data: {},
  items: [],
  nextUrl: null,
  prevUrl: null,
  count: 0
})

export const getters = {
  getItems: state => state.feed,
  getNextUrl: state => state.nextUrl
}

export const mutations = {
  setCount: (state, count) => {
    state.count = count
  },
  setData: (state, data) => {
    state.data = data
  },
  setItems: (state, data) => {
    state.items = data
  },
  setNextUrl: (state, nextUrl) => {
    state.nextUrl = nextUrl
  },
  setPrevUrl: (state, prevUrl) => {
    state.prevUrl = prevUrl
  }
}

export const actions = {
  async getInstructors ({ commit, state }, loadNext) {
    let url = '/user/instructor/'
    if (loadNext && state.nextUrl) {
      url = state.nextUrl
    }

    return await this.$axios.$get(url).then((response) => {
      commit('setCount', response.count || 0)
      commit('setData', response || {})
      commit('setNextUrl', response?.next)
      commit('setPrevUrl', response?.previous)

      if (loadNext) {
        commit('setItems', [...state.items, ...response.results])
      } else {
        commit('setItems', response.results)
      }

      return response
    })
  }
}
