
import { v4 as uuidv4 } from 'uuid'
import { formatDate } from '@/utils/date'

export default {
  name: 'TimeField',
  props: {
    value: {
      type: [String, Date],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    iconPosition: {
      type: String,
      default: 'left',
      validator: iconPosition => ['left', 'right'].includes(iconPosition)
    }
  },
  data () {
    return {
      id: null,
      formatDate
    }
  },
  computed: {
    iconPositionClasses () {
      const iconClasses = {
        left: 'pl-10',
        right: 'pr-10'
      }

      return iconClasses[this.iconPosition] || iconClasses.left
    }
  },
  mounted () {
    this.id = uuidv4()
  }
}
