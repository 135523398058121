export const state = () => ({
  count: 0,
  reactions: [],
  response: {}
})

export const getters = {
  getReactions: state => state.reactions
}

export const mutations = {
  setReactions (state, value) {
    state.reactions = value
  },

  setCount (state, value) {
    state.count = value
  },

  setResponse (state, value) {
    state.response = value
  },

  addReaction (state, value) {
    state.reactions.push(value)
  }
}

export const actions = {
  async fetchReactions ({ commit }, { postId, offset = 0, limit = 0 }) {
    await this.$axios.$get(`/user/post/${postId}/reaction/`, {
      params: {
        offset: offset || 0,
        limit: limit || 0
      }
    })
      .then((response) => {
        commit('setReactions', response.results || {})
        commit('setCount', response.count || 0)
        commit('setResponse', response || {})
      })
  },
  async addReaction ({ commit }, { postId, type = 'like' }) {
    return await this.$axios.$post(`/user/post/${postId}/reaction/`, {
      type
    }).then((response) => {
      commit('addReaction', response)
      return response
    })
  },
  async deleteReaction ({ commit }, { postId }) {
    return await this.$axios.$delete(`/user/post/${postId}/reaction/last/`)
  }
}
