
export const state = () => ({
  priceReport: null

})
export const getters = {
  getPriceReport: state => state.priceReport
}

export const mutations = {
  setPriceReport: (state, priceReport) => {
    state.priceReport = priceReport
  }
}
export const actions = {
  async fetchPriceReport ({ commit }, locationId) {
    return await this.$axios.$get(`/business-locations/${locationId}/price-reports/`).then((response) => {
      commit('setPriceReport', response.results || [])
      return response
    })
  }
}
