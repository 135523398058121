
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'Checkbox',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    isRound: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      id: null
    }
  },
  computed: {
    typeOfMarker () {
      return this.isRound ? 'focus:ring-primary rounded' : ''
    }
  },
  mounted () {
    this.id = uuidv4()
  },
  methods: {
    handleInput () {
      this.$emit('input', this.model)
    }
  }
}
