
import AvatarWithInfo from '@/components/global/Avatar/AvatarWithInfo'

export default {
  name: 'FriendsTagItem',
  components: {
    AvatarWithInfo
  },
  props: {
    userId: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    linkToProfile: {
      type: String,
      required: false,
      default: '#'
    },
    fullName: {
      type: String,
      required: true,
      default: null
    },
    description: {
      type: String,
      required: false,
      default: null
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    select () {
      this.$emit('selected', this.user)
    }
  }
}
