export const notificationMutations = {
  setNotificationSettings (state, key) {
    state.auth.user.notification_preferences[key] = !state.auth.user.notification_preferences[key]
  }
}

export const notificationActions = {
  async updateNotificationSetting ({ commit, state }, key) {
    const notificationPreferences = {}
    notificationPreferences[key] = !state.auth.user.notification_preferences[key]

    await this.$axios.patch('/user/profile/', {
      notification_preferences: notificationPreferences
    }).then(() => {
      commit('setNotificationSettings', key)
    }).catch((e) => {
      // eslint-disable-next-line
      console.error('Error', e.response.data)
    })
  }
}
