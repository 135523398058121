
import Alert from '~/components/global/Alert'
import Button from '~/components/global/Button.vue'
import CopyField from '~/components/global/share/copy-field'

export default {
  name: 'InstructionWarningModal',
  components: {
    Alert,
    Button,
    CopyField
  },
  computed: {
    open () {
      return this.$store.state.modals.instructorModal.open
    },
    shareUrl () {
      return this.$store.state.modals.instructorModal?.data?.shareUrl || ''
    },
    title () {
      return this.$store.state.modals.instructorModal?.data?.title
    },
    content () {
      return this.$store.state.modals.instructorModal?.data?.content
    }
  },
  methods: {
    toggle () {
      this.$store.commit('toggleModalState', { key: 'instructorModal', value: false })
    }
  }
}
