
export default {
  name: 'Instructor',
  props: {
    selected: {
      type: Boolean,
      default: false
    },

    user: {
      type: [Object],
      required: true
    },
    mode: {
      type: String,
      default: 'normal' // edit
    }
  }
}
