
import { getColumnName } from '@/utils/logbook'
import Checkbox from '@/components/manual-flight/AddLogbook/Checkbox'
import InputWithLabel from '@/components/global/Form/InputWithLabel'

export default {
  name: 'CheckboxWithInput',
  components: { Checkbox, InputWithLabel },
  props: {
    option: {
      type: String,
      default: ''
    },
    selected: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Number, String],
      default: 0
    }
  },
  data () {
    return {
      getColumnName
    }
  },
  methods: {
    handleInput (value) {
      this.$emit('input', value)
    },
    select (option) {
      this.$emit('selected', option)
    }
  }
}
