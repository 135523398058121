
import { mapActions, mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import AirplaneItem from './AirplaneItem'
import Button from '@/components/global/Button'
import DropDownIcon from '~/assets/images/icons/dropdown.svg?inline'
import InputWithIcon from '@/components/global/Form/InputWithIcon'
import Modal from '@/components/global/Modal'
import AddAirplaneModal from '@/components/my-profile/MyAirplanes/AddNewAircraft/AddAirplaneModal'

export default {
  name: 'SelectAirplaneType',
  components: {
    AirplaneItem,
    Button,
    InputWithIcon,
    DropDownIcon,
    Modal,
    AddAirplaneModal
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    airplane: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      selectedAirplane: null,
      id: null,
      modals: {
        airplaneType: false
      },
      search: '',
      make: '',
      shortName: '',
      model: ''
    }
  },
  computed: {
    ...mapGetters('aircraft-type', {
      aircraftsCount: 'getCount',
      aircrafts: 'getItems'
    })
  },
  watch: {
    airplane (value) {
      if (value?.type) {
        this.selectedAirplane = this.airplane?.type || this.airplane
      }
    }
  },
  mounted () {
    this.id = uuidv4()
  },
  created () {
    this.fetchAircrafts()
  },
  methods: {
    handleInput (value) {
      this.$emit('input', value)
      this.fetchAircrafts(value)
    },
    ...mapActions('aircraft-type', [
      'fetchAircrafts'
    ]),
    onSelectedAirplane (airplane) {
      this.selectedAirplane = airplane
      this.modals.airplaneType = false
      this.$emit('select', airplane)
    }
  }
}
