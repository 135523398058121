export const state = () => ({
  count: 0,
  comments: [],
  response: {}
})

export const getters = {
  getComments: state => state.comments
}

export const mutations = {
  setComments (state, value) {
    state.comments = value
  },

  setCount (state, value) {
    state.count = value
  },

  setResponse (state, value) {
    state.response = value
  },

  addComment (state, { parent, value }) {
    if (parent) {
      const index = state.comments.findIndex((comment) => {
        return comment.id === parent
      })
      state.comments[index].child_comments.push(value)
    } else { state.comments.push(value) }
  },

  deleteComment (state, commentId) {
    state.comments = state.comments.filter(
      comment =>
        comment.id !== commentId
    )
  },

  setDeletedText (state, { commentId, isChildComment }) {
    if (isChildComment) {
      let childIndex = -1
      const indexParent = state.comments.findIndex((comment) => {
        const cIndex = comment.child_comments.findIndex((child) => {
          return child.id === commentId
        })
        childIndex = cIndex
        return cIndex !== -1
      })

      if (indexParent === -1 || childIndex === -1) {
        return
      }

      state.comments[indexParent].child_comments[childIndex].text = '[deleted]'
      state.comments[indexParent].child_comments[childIndex].deleted = true

      return
    }

    const index = state.comments.findIndex((comment) => {
      return comment.id === commentId
    })

    if (index === -1) {
      return
    }

    state.comments[index].text = '[deleted]'
    state.comments[index].deleted = true
  },

  addReaction (state, { commentId, reaction, isChildComment }) {
    if (isChildComment) {
      let childIndex = -1
      const indexParent = state.comments.findIndex((comment) => {
        const cIndex = comment.child_comments.findIndex((child) => {
          return child.id === commentId
        })
        childIndex = cIndex
        return cIndex !== -1
      })
      state.comments[indexParent].child_comments[childIndex].comment_reactions.push(reaction)
    } else {
      const index = state.comments.findIndex((comment) => {
        return comment.id === commentId
      })
      state.comments[index].comment_reactions.push(reaction)
    }
  },

  removeReaction (state, { commentId, userId, isChildComment }) {
    if (isChildComment) {
      let childIndex = -1
      const indexParent = state.comments.findIndex((comment) => {
        const cIndex = comment.child_comments.findIndex((child) => {
          return child.id === commentId
        })
        childIndex = cIndex
        return cIndex !== -1
      })
      state.comments[indexParent].child_comments[childIndex].comment_reactions =
      state.comments[indexParent].child_comments[childIndex].comment_reactions.filter((reaction) => {
        return reaction.user.id !== userId
      })
    } else {
      const commentIndex = state.comments.findIndex((comment) => {
        return comment.id === commentId
      })
      state.comments[commentIndex].comment_reactions = state.comments[commentIndex].comment_reactions.filter((reaction) => {
        return reaction.user.id !== userId
      })
    }
  }
}

export const actions = {
  async fetchComments ({ commit }, { postId, offset = 0, limit = 0 }) {
    await this.$axios.$get(`/user/post/${postId}/comment/`, {
      params: {
        offset: offset || 0,
        limit: limit || 0
      }
    })
      .then((response) => {
        commit('setComments', response.results || {})
        commit('setCount', response.count || 0)
        commit('setResponse', response || {})
      })
  },

  async addComment ({ commit }, { postId, text, parentCommentId = 0, deleted = false }) {
    return await this.$axios.$post(`/user/post/${postId}/comment/`, {
      ...(parentCommentId && { parent_comment_id: parentCommentId }),
      text
    }).then((response) => {
      if (parentCommentId) {
        commit('addComment', { parent: parentCommentId, value: response })
      } else {
        commit('addComment', { parent: null, value: response })
      }
    })
  },

  async deleteComment ({ commit }, { postId, commentId, isChildComment = false }) {
    return await this.$axios.$delete(`/user/post/${postId}/comment/${commentId}/`).then(() => {
      commit('setDeletedText', { commentId, isChildComment })
    })
  },

  async reportComment  ({ commit }, params) {
    return await this.$axios.$post('/abuse/', params).then((response) => {
      return response
    })
  },

  async addReaction ({ commit }, { postId, commentId, type = 'like', isChildComment = false }) {
    return await this.$axios.$post(`/user/post/${postId}/comment/${commentId}/reaction/`, {
      type
    }).then((response) => {
      commit('addReaction', { commentId, reaction: response, isChildComment })
    })
  },

  async deleteReaction ({ commit }, { postId, commentId, userId, isChildComment = false }) {
    return await this.$axios.$delete(`/user/post/${postId}/comment/${commentId}/reaction/last/`)
      .then(() => {
        commit('removeReaction', { commentId, userId, isChildComment })
      })
  }
}
