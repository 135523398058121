
import { substractTwoDates } from '@/utils/date'
import Calendar from '@/components/manual-flight/TimeSelector/Calendar'
import ErrorIcon from '@/components/my-profile/ErrorIcon.vue'
import Modal from '@/components/global/Modal'
import TimeField from '@/components/manual-flight/TimeSelector/TimeField'

export default {
  name: 'TimeSelector',
  components: {
    Calendar,
    Modal,
    TimeField
  },
  props: {
    defaultStartDate: {
      type: [String, Date],
      required: false,
      default: ''
    },
    defaultEndDate: {
      type: [String, Date],
      required: false,
      default: ''
    }
  },
  data () {
    return {
      substractTwoDates,
      modals: {
        endDateCalendar: false,
        startDateCalendar: false
      },
      startDate: '',
      endDate: ''
    }
  },
  mounted () {
    if (this.defaultStartDate instanceof Date) {
      this.startDate = this.defaultStartDate
    }

    if (this.defaultEndDate instanceof Date) {
      this.endDate = this.defaultEndDate
    }
  },
  methods: {
    handleStartDate (value) {
      this.startDate = value
      this.endDate = ''
    },
    handleEndDate (value) {
      this.endDate = value
    },
    close () {
      if (!(this.endDate instanceof Date)) {
        this.$toast.error('Please select a valid end date.', {
          icon: ErrorIcon
        })
        return
      }

      if (!(this.startDate instanceof Date)) {
        this.$toast.error('Please select a valid start date.', {
          icon: ErrorIcon
        })
        return
      }

      if (this.endDate < this.startDate) {
        this.$toast.error('End date must be greater than start date', {
          icon: ErrorIcon
        })
        return
      }

      this.$emit('startDate', this.startDate)
      this.$emit('endDate', this.endDate)
      this.$emit('save', this.startDate, this.endDate)
      this.$emit('close')
    },
    openEndDateModal () {
      if (!(this.startDate instanceof Date)) {
        this.$toast.error('Please select start date first', {
          icon: ErrorIcon
        })

        return
      }

      this.modals.endDateCalendar = true
    }
  }
}
