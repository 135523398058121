
export default {
  name: 'Alert',
  props: {
    title: {
      type: String,
      required: false,
      default: 'Something went wrong'
    },
    messages: {
      type: [Array, String],
      required: true
    }
  },
  computed: {
    isMultipleMessages () {
      return Array.isArray(this.messages)
    }
  }
}
