
import EditMyAirplaneModal from '@/components/my-profile/MyAirplanes/EditMyAirplaneModal'

export default {
  name: 'AirplaneItem',
  components: {
    EditMyAirplaneModal
  },
  props: {
    tag: {
      type: String,
      required: true
    },
    engineType: {
      type: String,
      required: true
    },
    planeName: {
      type: String,
      required: true
    },
    img: {
      type: String,
      required: true
    },
    id: {
      type: [Number, String],
      required: true
    },
    retractable: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      modal: false
    }
  }
}
