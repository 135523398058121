
import { mapActions } from 'vuex'
import Button from '@/components/global/Button'
import CheckIcon from '~/components/my-profile/CheckIcon'
import ErrorIcon from '~/components/my-profile/ErrorIcon'

export default {
  name: 'PendingFollowerRequest',
  components: {
    Button
  },
  props: {
    userId: {
      type: Number,
      default: 0
    },
    notificationId: {
      type: Number,
      default: 0
    }
  },
  methods: {
    ...mapActions('user/followers', [
      'deleteFollower',
      'followUser'
    ]),
    ...mapActions('notifications', [
      'deleteNotification'
    ]),
    deleteFollowerAction (userId) {
      this.deleteFollower(userId).then((response) => {
        this.deleteNotification(this.notificationId)

        if (response.success) {
          this.$toast.success(response.message, {
            icon: CheckIcon
          })
        } else {
          this.$toast.error(response.message, {
            icon: ErrorIcon
          })
        }
      })
    },
    followUserAction (userId) {
      this.followUser({ userId }).then((response) => {
        this.deleteNotification(this.notificationId)

        if (response.success) {
          this.$toast.success(response.message, {
            icon: CheckIcon
          })
        } else {
          this.$toast.error(response.message, {
            icon: ErrorIcon
          })
        }
      })
    }
  }
}
