import { getAircraftData } from '~/utils/helpers'

export const state = () => ({
  count: 0,
  aircraft: [],
  response: {}
})

export const getters = {
  getAircraft: state => (searchString = '') => {
    let aircraft = state.aircraft.map(airplane => getAircraftData(airplane))

    if (searchString !== '') {
      aircraft = aircraft.filter(
        airplane =>
          airplane.planeName.toLowerCase().includes(searchString.toLowerCase()) ||
          airplane.engineType.toLowerCase().includes(searchString.toLowerCase()) ||
          airplane.tag.toLowerCase().includes(searchString.toLowerCase())
      )
    }

    return aircraft
  },
  getRawAircraft: state => (searchString = '') => {
    let aircraft = state.aircraft

    if (searchString !== '') {
      aircraft = aircraft.filter(
        airplane =>
          airplane.engine_type.toLowerCase().includes(searchString.toLowerCase()) ||
          airplane?.type?.make.toLowerCase().includes(searchString.toLowerCase()) ||
          airplane?.type?.model.toLowerCase().includes(searchString.toLowerCase()) ||
          airplane?.tail_number.toLowerCase().includes(searchString.toLowerCase())
      )
    }

    return aircraft
  },
  getMyAircraftTypes: (state) => {
    return state.aircraft.map(aircraft => aircraft.type)
  },
  getAircraftSmallList: state => state.aircraft
    .filter((i, index) => (index < 5))
    .map(airplane => getAircraftData(airplane)),
  getCount: state => state.count,
  getResponse: state => state.response
}

export const mutations = {
  setAircraft (state, value) {
    state.aircraft = value
  },

  setCount (state, value) {
    state.count = value
  },

  setResponse (state, value) {
    state.response = value
  }
}

export const actions = {
  async fetchAircraft ({ commit }) {
    await this.$axios.$get('/user/aircraft/')
      .then((response) => {
        commit('setAircraft', response.results || {})
        commit('setCount', response.count || 0)
        commit('setResponse', response || {})
      })
  },
  async addAircraft ({ commit }, data) {
    return await this.$axios.$post('/user/aircraft/', data)
      .then(res => res)
      .catch((e) => {
        // eslint-disable-next-line
        console.error('Error', e.response.data)
      })
  },
  async addAircraftPatch ({ commit, dispatch }, { aircraftId, data }) {
    return await this.$axios.$patch(`/user/aircraft/${aircraftId}/`, data)
      .then((res) => {
        dispatch('fetchAircraft')
        return res
      })
      .catch((e) => {
        // eslint-disable-next-line
        console.error('Error', e.response.data)
      })
  },
  async getAircraft ({ commit }, id) {
    return await this.$axios.$get(`/user/aircraft/${id}/`)
      .then(res => res)
  }
}
