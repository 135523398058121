
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { parseEndpointError } from '~/utils/helpers'
import AppleIcon from '~/assets/images/icons/AppleIcon.svg?inline'
import FacebookIcon from '~/assets/images/icons/FacebookIcon.svg?inline'
import FacebookWhiteIcon from '~/assets/images/icons/FacebookWhiteIcon.svg?inline'
import GoogleIcon from '~/assets/images/icons/GoogleIcon.svg?inline'

export default {
  name: 'SocialProviders',
  components: { AppleIcon, FacebookIcon, FacebookWhiteIcon, GoogleIcon },
  props: {
    monochrome: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    deviceType () {
      return this.$deviceType()
    }
  },
  methods: {
    async loginWithGoogle () {
      if (this.deviceType === 'web') {
        this.$auth.loginWith('google')
      } else {
        const result = await FirebaseAuthentication.signInWithGoogle()
        if (result?.credential?.accessToken) {
          this.signInSocial('googleAndroid', result.credential.accessToken)
        }
      }
    },
    async loginWithFacebook () {
      if (this.deviceType === 'web') {
        this.$auth.loginWith('facebook')
      } else {
        const result = await FirebaseAuthentication.signInWithFacebook()
        if (result?.credential?.accessToken) {
          this.signInSocial('facebookAndroid', result.credential.accessToken)
        }
      }
    },
    signInSocial (strategy, token) {
      this.$auth.loginWith(strategy, {
        data: { access_token: token }
      }).then(async (res) => {
        const token = res.data.token
        await this.$auth.setStrategy('local')
        await this.$auth.setUserToken(token, this.$auth.strategy.refreshToken)
        const user = await this.$auth.fetchUser()
        if (user.data) {
          await this.$auth.setUser(user.data)
        }
        this.$router.push('/feed/')
      }).catch((e) => {
        // eslint-disable-next-line
        console.error('Error', e)
        if (e.response) {
          this.error = e?.response?.data ? parseEndpointError(e.response.data) : (e?.message || 'Something went wrong.')
        } else {
          this.error = 'Something went wrong. Please try again later.'
        }
      })
    },
    // TODO: Implement Apple sign in with Nuxt Auth
    async singInApple () {
      const data = await window.AppleID.auth.signIn()
      if (data?.authorization) {
        const { authorization } = data
        // eslint-disable-next-line camelcase
        const { id_token } = authorization

        await this.$axios.$post('/user/social-login/apple-id/', {
          // eslint-disable-next-line camelcase
          access_token: id_token
        }).then(async (response) => {
          const token = await response.token
          if (typeof token === 'undefined' || !token.length) {
            return
          }

          await this.$auth.setStrategy('local')
          await this.$auth.setUserToken(token, this.$auth.strategy.refreshToken)

          // Redirect user reset password page to set the password after password reset.
          if (this.$store.state.isResetPassword) {
            await this.$router.push('/settings/password')
            return
          }

          // if redirect available
          if (this.$store.state.signinRedirect) {
            await this.$router.push(this.$store.state.signinRedirect)
            return
          }

          await this.$router.push('/feed/')
        }).catch((e) => {
          this.$auth.getStrategy().logout()
          // eslint-disable-next-line no-console
          console.error(e)
        })
      } else {
        // eslint-disable-next-line
        console.error('Error', data)
        this.error = 'Something went wrong. Please try again later.'
      }
    }
  }
}
