
import { mapActions, mapMutations } from 'vuex'
import { datesToDecimal, substractTwoDates } from '@/utils/date'
import { parseEndpointError } from '@/utils/helpers'
import { simulatorTypes } from '@/config/simulator-types'

import { privacy } from '@/config/privacy'
import AddLogbook from '@/components/simulator-flight/AddLogbook'
import AddNewAircraft from '@/components/my-profile/MyAirplanes/AddNewAircraft'
import CheckIcon from '@/components/my-profile/CheckIcon'
import ChooseAirplane from '@/components/manual-flight/ChooseAirplane'
import ChooseAirport from '@/components/manual-flight/ChooseAirport'
import ErrorIcon from '@/components/my-profile/ErrorIcon'
import TimeSelector from '@/components/manual-flight/TimeSelector/index'
import SimulatorType from '@/components/simulator-flight/SimulatorType'
import RouteField from '@/components/manual-flight/RouteField'

export default {
  name: 'SimulatorFlight',
  components: {
    AddLogbook,
    AddNewAircraft,
    ChooseAirplane,
    ChooseAirport,
    RouteField,
    TimeSelector,
    SimulatorType
  },
  data () {
    return {
      substractTwoDates,
      step: 1,
      aircraft: null,
      privacy,
      simulatorType: null,
      startTime: '',
      endTime: '',
      dateNow: new Date(),
      airports: {
        from: null,
        to: null
      },
      flightOptions: [],
      modals: {
        chooseAircraft: false,
        addNewAircraft: false,
        chooseFromAirport: false,
        chooseToAirport: false,
        chooseViaAirport: false,
        time: false,
        simulatorType: false
      },
      approaches: [],
      instructor: null,
      checkboxes: [],
      increaseDecreaseOptions: [],
      submitButtonActive: true,
      submitButtonText: 'Save',
      routes: [],
      showRouteField: false
    }
  },
  methods: {
    ...mapActions('user/simulator', [
      'postSimulatorFlight'
    ]),
    ...mapMutations([
      'setModalData'
    ]),
    openSimulatorTypeModal () {
      this.modals.simulatorType = true
      this.simulatorType = simulatorTypes[0]
    },
    updateAircraft (aircraft) {
      this.aircraft = aircraft
      this.modals.chooseAircraft = false
    },
    updateDestinationAirport (airport) {
      this.airports.to = airport
    },
    updateFromAirport (airport) {
      this.airports.from = airport
    },
    handleStartDate (value) {
      this.startTime = value
    },
    handleEndDate (value) {
      this.endTime = value
    },
    updateSimulatorType (value) {
      if (typeof value === 'object' && value !== null) {
        this.simulatorType = value
      } else {
        this.simulatorType = simulatorTypes.find(type => type.label === value)
      }
    },
    handleFlightOptions (values) {
      this.flightOptions = values
      this.step = 2
    },
    goBack () {
      this.step = 2
      this.showCreatePost = false
    },
    timeInDecimals () {
      return datesToDecimal(this.endTime, this.startTime)
    },
    setAirplaneType (airplaneType) {
      this.aircraft = airplaneType
    },
    handleApproaches (value) {
      this.approaches = value
    },
    handleInstructor (value) {
      this.instructor = value
    },
    handleCheckboxes (value) {
      this.checkboxes = value
    },
    handleNotes (value) {
      this.notes = value
    },
    handleIncreaseDecreaseOptions (value) {
      this.increaseDecreaseOptions = value
    },
    async handleSubmit () {
      if (!this.submitButtonActive) {
        return
      }

      this.submitButtonText = 'Saving...'
      this.submitButtonActive = false
      const date = new Date()

      const args = {
        post: {
          type: 'manual',
          time: date.toISOString()
        },
        type: this.simulatorType?.value || simulatorTypes[0].value,
        notes: this.notes
      }

      if (this.startTime instanceof Date) {
        args.start_time = this.startTime.toISOString()
      }

      if (this.endTime instanceof Date) {
        args.end_time = this.endTime.toISOString()
      }

      if (this.aircraft?.id) {
        args.aircraft_id = this.aircraft.id
      }

      if (this.airports.to?.id) {
        args.landing_airport = this.airports.to.id
      }

      if (this.airports.from?.id) {
        args.takeoff_airport = this.airports.from.id
      }

      if (this.approaches.length > 0) {
        args.approaches = this.approaches.map((approach) => {
          return {
            type: approach
          }
        })
      }

      if (this.instructor) {
        args.signature = {
          instructor_id: this.instructor?.id,
          instructor_number: this.instructor?.instructor_number,
          certificate_expiration: this.instructor?.certificate_expiration
        }
      }

      this.flightOptions.forEach((option) => {
        if (option.selected) {
          args[option.option] = option.value
        }
      })

      if (this.checkboxes.length > 0) {
        this.checkboxes.forEach((option) => {
          if (option.value > 0) {
            args[option.slug] = option.value
          }
        })
      }

      if (this.increaseDecreaseOptions.length > 0) {
        this.increaseDecreaseOptions.forEach((option) => {
          if (option.value > 0) {
            args[option.slug] = option.value
          }
        })
      }
      if (this.routes.length > 0) {
        args.intermediate_airports = this.routes.join(',')
      }

      try {
        await this.postSimulatorFlight({ args })
          .then((res) => {
            if (this.instructor && res?.share_url) {
              this.setModalData({
                key: 'instructorModal',
                value: {
                  open: true,
                  data: {
                    shareUrl: res.share_url,
                    title: 'Simulator Flight Posted'
                  }
                }
              })
            } else {
              this.$toast.success('Simulator flight has been added successfully.', {
                icon: CheckIcon
              })
            }

            this.$emit('close')
          })
          .catch((e) => {
            const error = e?.response?.data ? parseEndpointError(e?.response?.data) : (e.message || 'Something went wrong')

            this.$toast.error(error, {
              icon: ErrorIcon
            })
          })
      } catch (e) {
        this.$toast.error(e, {
          icon: ErrorIcon
        })
      }
    },
    onRouteInput (value) {
      this.routes = value
    }
  }
}
