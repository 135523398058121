export const timeOptions = [
  'total_time',
  'imc_actual_time',
  'ccross_time',
  'imc_simulated_time',
  'dual_received_time',
  'dual_given_time',
  'night_time',
  'pic_time',
  'sic_time',
  'solo_time'
]

export const landingOptions = [
  'day_landings',
  'night_landings',
  'day_takeoffs',
  'night_takeoffs',
  'holds'
]
