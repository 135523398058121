
export default {
  name: 'FilePreview',
  props: {
    file: { type: Object, required: true },
    customClass: { type: String, default: '' }
  },
  data () {
    return {
      showPlayButton: true
    }
  },
  mounted () {
    if (this.file.type !== 'video') {
      return
    }

    if (!this.$refs?.video || !(this.$refs.video instanceof HTMLVideoElement)) {
      return
    }

    this.$refs.video.addEventListener('ended', () => {
      this.showPlayButton = true
    })

    this.$refs.video.addEventListener('pause', () => {
      this.showPlayButton = true
    })

    this.$refs.video.addEventListener('play', () => {
      this.showPlayButton = false
    })
  },
  methods: {
    playVideo () {
      if (!this.$refs?.video || !(this.$refs.video instanceof HTMLVideoElement)) {
        return
      }

      const video = this.$refs.video
      if (video.paused) {
        this.showPlayButton = false
        video.play()
      } else {
        this.showPlayButton = true
        video.pause()
      }
    }
  }
}
