export const actions = {
  async postFlight ({ commit }, { args }) {
    return await this.$axios.$post('/user/flight/', args).then((response) => {
      return response
    })
  },
  async patchFlight ({ commit }, { postId, args }) {
    return await this.$axios.$patch(`/user/flight/${postId}/`, args).then((response) => {
      return response
    })
  },
  async deleteFlight ({ commit }, { postId }) {
    return await this.$axios.$patch(`/user/post/${postId}/`, {
      deleted: true
    }).then((response) => {
      return response
    })
  }
}
