
import EditAirplane from '@/components/my-profile/MyAirplanes/AddNewAircraft/EditAirplane'

export default {
  name: 'AirplaneItem',
  components: {
    EditAirplane
  },
  props: {
    airplane: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    select () {
      this.$emit('selected', this.airplane)
    }
  }
}
