
import { mapActions } from 'vuex'
import Profile from '~/components/endorsements/profile.vue'
import InputWithIcon from '~/components/global/Form/InputWithIcon.vue'
import Button from '~/components/global/Button.vue'
import AddInstructor from '~/components/endorsements/request/add-instructor'
import { parseEndpointError } from '~/utils/helpers'
import ErrorIcon from '~/components/my-profile/ErrorIcon.vue'
import CheckIcon from '~/components/my-profile/CheckIcon.vue'
export default {
  name: 'ChooseInstructor',
  components: { InputWithIcon, Profile, Button, AddInstructor },
  props: {
    instructors: {
      type: [Array],
      required: true
    },
    selectedInstructor: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      mode: 'normal', // edit, normal
      modals: {
        addInstructor: false,
        editInstructor: false
      },
      searchKeyword: '',
      selected: this.selectedInstructor || '',
      list: this.instructors,
      instructorToEdit: null
    }
  },
  computed: {
    header () {
      return this.mode === 'normal' ? 'Choose Instructor' : 'Edit Instructor'
    }
  },
  watch: {
    searchKeyword (newValue) {
      if (newValue === '' || newValue.length === 0) {
        this.list = this.instructors
      } else {
        this.list = this.instructors.filter((instructor) => {
          return instructor.first_name.toUpperCase()?.includes(newValue.toUpperCase()) || instructor.last_name.toUpperCase()?.includes(newValue.toUpperCase())
        })
      }
    },
    instructors (newValue) {
      this.list = newValue
    }
  },
  beforeMount () {
    if (this.instructors &&
      this.selectedInstructor &&
      this.instructors.filter(e => e.id === this.selectedInstructor.id).length > 0) {
      this.selected = this.selectedInstructor
    } else {
      this.selected = ''
    }
  },
  methods: {
    ...mapActions('instructor', ['deleteInstructor']),
    search (key) {
      this.searchKeyword = key
    },
    onInstructorSelected (instructor) {
      if (this.mode === 'normal') { this.selected = instructor }
    },
    onEditMode () {
      this.mode = 'edit'
      this.selected = ''
    },
    onEditInstructor (instructor) {
      this.instructorToEdit = instructor
      this.modals.addInstructor = true
    },
    onAddInstructor () {
      this.instructorToEdit = null
      this.modals.addInstructor = true
    },
    onDelete (instructor) {
      this.deleteInstructor(instructor).then(() => {
        this.$toast.success('Deleted Instructor', {
          icon: CheckIcon
        })
      }).catch((e) => {
        this.$toast.error(parseEndpointError(e.response.data), {
          icon: ErrorIcon
        })
      })
    }
  }
}
