
import AvatarImage from '@/components/global/Avatar/AvatarImage'
import PendingFollowerRequest from '@/components/notification/PendingFollowerRequest'
import EmptyComponent from '@/components/notification/EmptyComponent'
import FollowBack from '@/components/notification/FollowBack'
import ImageContent from '@/components/notification/ImageContent'

export default {
  name: 'BaseNotificationItem',
  components: {
    AvatarImage,
    PendingFollowerRequest,
    EmptyComponent,
    FollowBack
  },
  props: {
    isToast: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    verb: {
      type: String,
      default: 'wants to follow'
    },
    timestamp: {
      type: [String, Number],
      default: 0
    },
    image: {
      type: String,
      default: ''
    },
    imageUrl: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'pending_follower_request'
    },
    unread: {
      type: Boolean,
      default: false
    },
    userId: {
      type: [Number, String],
      default: 0
    },
    notificationId: {
      type: Number,
      default: 0
    },
    url: {
      type: String,
      default: ''
    },
    notification: {
      type: Object,
      required: true
    }
  },
  computed: {
    mappedComponents () {
      const type = this.type
      const pairDict = {
        pending_follower_request: PendingFollowerRequest,
        follow_back: FollowBack,
        like_info: ImageContent,
        post_comment: ImageContent,
        update_location: ImageContent
      }
      return pairDict?.[type] ? pairDict[type] : EmptyComponent
    }
  },
  methods: {
    goToUrl () {
      this.$emit('close-toast')
      this.$emit('toast-clicked', this.url)
    }
  }
}
