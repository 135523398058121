
import { mapActions } from 'vuex'
import moment from 'moment'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import CustomRadio from './custom-radio.vue'
import { countryCodes } from '~/config/country-codes'
import customInput from '~/components/endorsements/custom-input.vue'
import DateSelectModal from '~/components/global/Form/DateSelectModal.vue'
import { parseEndpointError, validEmail } from '~/utils/helpers'
import ErrorIcon from '~/components/my-profile/ErrorIcon.vue'
import CheckIcon from '~/components/my-profile/CheckIcon.vue'
import Modal from '@/components/global/Modal/'
export default {
  name: 'AddInstructor',
  components: { customInput, CustomRadio, DateSelectModal, VuePhoneNumberInput, Modal },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    instructor: {
      type: Object,
      default: null
    },
    mode: {
      type: String,
      default: 'normal' // normal, edit
    }
  },
  data () {
    return {
      method: this.instructor?.phone_number ? 'SMS' : 'Email',
      busyState: false,
      modals: {
        date: false
      },
      errors: {
        email: false,
        firstName: false,
        lastName: false,
        expiration: false,
        instructorNumber: false,
        phone: false
      },
      name: {
        firstName: this.instructor?.first_name || '',
        lastName: this.instructor?.last_name || ''
      },
      instructorNumber: this.instructor?.instructor_number || '',
      expiration: this.instructor?.certificate_expiration || 0,
      internationalNumber: this.instructor?.phone || 0,
      email: this.instructor?.email || '',
      // phone: this.instructor?.phone || ''
      phone: {
        code: this.instructor?.phone_country_code || '',
        number: this.instructor?.phone_number || '',
        internationalNumber: '',
        nationalNumber: this.instructor?.phone_number || '',
        isValid: false

      }
    }
  },
  computed: {
    header () {
      return this.mode === 'normal' ? 'Add Instructor' : 'Edit Instructor'
    },
    disabled () {
      return !this.name.firstName || !this.name.lastName || !this.instructorNumber || this.busyState
    }
  },
  methods: {
    ...mapActions('instructor', ['addInstructor', 'updateInstructor']),

    formatedDate (Ts) {
      if (!Ts) { return '' }
      return moment(new Date(Ts * 1000)).format('MMM DD, YYYY')
    },
    onPhoneNumberInput (e) {
      this.errors.phone = false
      this.phone.code = e.countryCallingCode
      this.phone.isValid = e.isValid
      this.phone.nationalNumber = e.nationalNumber
      this.phone.internationalNumber = e.formattedNumber
    },
    validateForm () {
      this.resetAllErrors()
      if (!this.name.firstName) {
        this.errors.firstName = 'Please enter first name'
        return false
      }
      if (!this.name.lastName) {
        this.errors.lastName = 'Please enter last name'
        return false
      }
      if (!this.instructorNumber) {
        this.errors.lastName = 'Please enter instructor number'
        return false
      }
      if (this.instructorNumber.length < 2) {
        this.errors.instructorNumber = 'Please enter a valid instructor number'
        return false
      }
      if (!this.expiration || this.expiration === 0) {
        this.errors.expiration = 'Please enter Expiration date'
        return false
      }
      if (this.method === 'Email') {
        if (!this.email) {
          this.errors.email = 'Please enter Email address'
          return false
        }
        if (!validEmail(this.email)) {
          this.errors.email = 'Please enter Email a valid email address'
          return false
        }
      }
      if (this.method === 'SMS' && !this.phone.isValid) {
        this.errors.phone = 'Please enter Email a valid phone number'
        return false
      }
      return true
    },
    resetAllErrors () {
      this.errors = {
        email: false,
        firstName: false,
        lastName: false,
        expiration: false,
        instructorNumber: false,
        phone: false
      }
    },
    getCountryCode (d) {
      return countryCodes[d?.replaceAll('+', '')] || null
    },
    onSaveClicked () {
      if (!this.validateForm()) {
        return
      }
      const payload = {
        first_name: this.name.firstName,
        last_name: this.name.lastName,
        ...(this.method === 'SMS' && {
          phone_number: this.phone.nationalNumber,
          phone_country_code: '+' + this.phone.code
        }),
        ...(this.method === 'Email' && {
          email: this.email
        }),
        instructor_number: this.instructorNumber,
        certificate_expiration: this.expiration
      }
      this.busyState = true
      if (this.mode === 'normal') {
        this.addInstructor(payload).then(() => {
          this.$toast.success('Added Instructor', {
            icon: CheckIcon
          })
          this.$emit('close')
        }).catch((e) => {
          this.$toast.error(parseEndpointError(e.response.data), {
            icon: ErrorIcon
          })
        }).finally(() => {
          this.busyState = false
        })
      } else {
        payload.id = this.instructor.id
        this.updateInstructor(payload).then(() => {
          this.$toast.success('Updated Instructor', {
            icon: CheckIcon
          })
          this.$emit('close')
        }).catch((e) => {
          this.$toast.error(parseEndpointError(e.response.data), {
            icon: ErrorIcon
          })
        }).finally(() => {
          this.busyState = false
        })
      }
    }
  }
}
