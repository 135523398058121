

import { mapGetters } from 'vuex'

export default {
  name: 'Textarea',
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      textareaText: ''
    }
  },
  computed: {
    ...mapGetters([
      'firstName'
    ])
  },
  watch: {
    text (val) {
      this.textareaText = val
      this.onInput()
    }
  },
  mounted () {
    this.textareaText = this.text
    setTimeout(() => {
      if (this.$refs?.message) {
        this.$refs.message.click()
      }
    }, 0)
  },
  methods: {
    onInput () {
      if (!this.$refs?.message) {
        return
      }

      const element = this.$refs.message

      element.style.height = '40px'
      element.style.height = element.scrollHeight + 'px'

      this.$emit('input', this.textareaText)
    }
  }
}
