
import { setHours as setTime } from '@/utils/date'
import ErrorIcon from '@/components/my-profile/ErrorIcon'
import PickTime from '@/components/manual-flight/TimeSelector/PickTime'

export default {
  name: 'Calendar',
  components: {
    PickTime
  },
  props: {
    customDate: {
      type: [String, Date],
      default: ''
    },
    minDate: {
      type: [String, Date],
      default: ''
    }
  },
  data () {
    return {
      date: '',
      hour: '12',
      minute: '00',
      ampm: 'AM',
      attrs: [
        {
          key: 'today',
          dot: true,
          dates: new Date()
        },
        {
          highlight: {
            start: {
              style: {
                backgroundColor: '#2957E7'
              },
              contentStyle: {
                color: '#ffffff'
              }
            },
            base: {
              style: {
                backgroundColor: '#D3EAF1'
              }
            },
            end: {
              style: {
                backgroundColor: '#2957E7'
              },
              contentStyle: {
                color: '#ffffff'
              }
            }
          }
        }
      ],
      attribute: {
        highlight: {
          start: {
            style: {
              backgroundColor: '#2957E7',
              height: '40px',
              width: '40px',
              fontSize: '16px'
            },
            contentStyle: {
              color: '#ffffff',
              height: '40px',
              width: '40px',
              fontSize: '16px'
            }
          },
          base: {
            style: {
              backgroundColor: '#2957E7',
              height: '40px',
              width: '40px',
              fontSize: '16px'
            }
          },
          end: {
            style: {
              backgroundColor: '#2957E7',
              height: '40px',
              width: '40px',
              fontSize: '16px'
            },
            contentStyle: {
              color: '#ffffff',
              height: '40px',
              width: '40px',
              fontSize: '16px'
            }
          }
        }
      },
      locale: {
        masks: {
          weekdays: 'WWW'
        }
      }
    }
  },
  mounted () {
    if (this.customDate instanceof Date) {
      const hour = this.customDate.getHours() > 12 ? (this.customDate.getHours() - 12) : this.customDate.getHours()
      this.date = this.customDate
      this.hour = hour.toString()
      this.minute = this.customDate.getMinutes().toString()
      this.ampm = this.customDate.getHours() > 12 ? 'PM' : 'AM'
    } else {
      this.date = new Date()
    }
  },
  methods: {
    handleInput () {
      this.date = setTime(this.date, this.hour, this.minute, this.ampm)
    },
    close () {
      if (!(this.date instanceof Date)) {
        this.$toast.error('Please select a date', {
          icon: ErrorIcon
        })

        return
      }
      if (this.minDate instanceof Date) {
        if (this.minDate.getTime() > this.date.getTime()) {
          this.$toast.error('End date must be greater than start date', {
            icon: ErrorIcon
          })

          return
        }
      }

      this.$emit('input', setTime(this.date, this.hour, this.minute, this.ampm))
      this.$emit('cancel')
    },
    handleHour (value) {
      this.hour = value
      this.date = setTime(this.date, this.hour, this.minute, this.ampm)
    },
    handleMinute (value) {
      this.minute = value
      this.date = setTime(this.date, this.hour, this.minute, this.ampm)
    },
    handleAmPm (value) {
      this.ampm = value
      this.date = setTime(this.date, this.hour, this.minute, this.ampm)
    }
  }
}
