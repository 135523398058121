
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Notify',
  props: {
    url: {
      type: String,
      default: '#'
    }
  },
  computed: {
    ...mapGetters('notifications', {
      count: 'getCount'
    })
  },
  created () {
    this.getNotificationsCount()
  },
  methods: {
    ...mapActions('notifications', [
      'getNotificationsCount'
    ])
  }
}
