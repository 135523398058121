export const allowedFileTypes = () => {
  const fileTypes = [
    '.jpeg',
    '.jpg',
    '.png',
    'image/jpeg',
    'image/png',
    'video/mp4',
    'video/x-m4v',
    'video/*'
  ]

  return fileTypes.join(',')
}
