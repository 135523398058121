import { groupCertificates } from '~/utils/helpers'

export const state = () => ({
  certificates: [],
  response: {}
})

export const getters = {
  getRawCertificates: state => state.certificates,
  getCertificates (state) {
    const certificates = state.certificates
    return groupCertificates(certificates)
  },
  getResponse: state => state.certificates
}

export const mutations = {
  setCertifications (state, value) {
    state.certificates = value
  },

  setResponse (state, value) {
    state.response = value
  }
}

export const actions = {
  async fetchCertificates ({ commit }) {
    await this.$axios.$get('/user/certificate/')
      .then((response) => {
        commit('setCertifications', response.results || {})
        commit('setResponse', response || {})
      })
  },
  async bulkUpdateCertificates ({ commit, dispatch }, payload) {
    await this.$axios.put('/user/certificate/bulk_update/', payload).then(() => {
      dispatch('fetchCertificates')
    })
  }
}
