export const simulatorTypes = [
  {
    value: 'ftd',
    label: '(FTD) Flight Training Device'
  },
  {
    value: 'aatd',
    label: '(AATD) An Aviation Training Device'
  },
  {
    value: 'ffs',
    label: '(FFS) Full Flight Simulator'
  }
]

export function getSimulatorTypeLabel (value) {
  const simulatorType = simulatorTypes.find(simulatorType => simulatorType.value === value)
  return simulatorType ? simulatorType.label : ''
}
