
import { v4 as uuidv4 } from 'uuid'

import Modal from '@/components/global/Modal'

export default {
  name: 'DateSelectModal',
  components: {
    Modal
  },
  props: {
    date: {
      type: Number,
      default: 0
    },
    open: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Choose Date'
    }
  },
  data () {
    return {
      id: null,
      dateModal: false,
      calenderDate: this.date ? this.date : new Date().getTime() / 1000,
      selected: {
        year: this.date ? new Date(this.date * 1000).getUTCFullYear() : new Date().getUTCFullYear(),
        month: this.date ? new Date(this.date * 1000).getUTCMonth() : new Date().getUTCMonth(),
        day: this.date ? new Date(this.date * 1000).getUTCDate() : new Date().getUTCDate()
      }
    }
  },
  computed: {
    iconPositionClasses () {
      const iconClasses = {
        left: 'pl-10',
        right: 'pr-10'
      }

      return iconClasses[this.iconPosition] || iconClasses.left
    },
    calender () {
      const firstDayOfMonth = (year, month) => {
        const tempDate = new Date(year, month, 1).getDay()
        if (tempDate === 0) {
          return 7
        }
        return tempDate
      }
      const calculateLastDay = (month) => {
        return new Date(2022, month + 1, 0).getUTCDate()
      }

      const dateToUse = new Date(this.calenderDate * 1000)

      const calender = {
        start: firstDayOfMonth(dateToUse.getUTCFullYear(), dateToUse.getUTCMonth()),
        end: calculateLastDay(dateToUse.getUTCMonth()),
        month: dateToUse.toLocaleString('default', {
          month: 'long'
        }),
        year: dateToUse.getUTCFullYear()
      }

      return calender
    }
  },
  mounted () {
    this.id = uuidv4()
  },
  methods: {
    changeMonth (amount) {
      const currentDisplay = new Date(this.calenderDate * 1000)
      const newDate = new Date(currentDisplay.getUTCFullYear(), currentDisplay.getUTCMonth() + amount, currentDisplay.getUTCDate())

      this.calenderDate = newDate.getTime() / 1000
    },
    changeYear (amount) {
      const currentDisplay = new Date(this.calenderDate * 1000)
      const newDate = new Date(currentDisplay.getUTCFullYear() + amount, currentDisplay.getUTCMonth(), currentDisplay.getUTCDate())

      this.calenderDate = newDate.getTime() / 1000
    },
    updateYear (e) {
      const today = new Date()
      // prevents year from being greater than or less than todays date + 100
      let value = e.target.value
      if (value > today.getUTCFullYear() + 100) {
        value = today.getUTCFullYear() + 100
      } else if (value < today.getUTCFullYear() - 100) {
        value = today.getUTCFullYear() - 100
      }
      e.target.value = value
      const currentDisplay = new Date(this.calenderDate * 1000)
      this.calenderDate = new Date(value, currentDisplay.getUTCMonth(), currentDisplay.getUTCDate()).getTime() / 1000
    }
  }
}
