
import { mapGetters } from 'vuex'

import '~/assets/images/icons/AccountMenu.svg?sprite'
import AccountMenuItem from '@/components/header/AccountMenuItem'
import AvatarImage from '@/components/global/Avatar/AvatarImage'

export default {
  name: 'Account',
  components: {
    AccountMenuItem,
    AvatarImage
  },
  props: {
    fullName: {
      type: String,
      default: ''
    },
    imgUrl: {
      type: String,
      default: ''
    },
    smallNav: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isActive: false
    }
  },
  computed: {
    ...mapGetters([
      'username'
    ]),
    menuList () {
      if (this.smallNav) {
        return [
          {
            linkUrl: '/logout',
            linkText: 'Logout',
            linkIcon: 'Logout',
            linkColor: ''
          }
        ]
      } else {
        return [
          {
            linkUrl: `/profile/${this.username}`,
            linkText: 'My Account',
            linkIcon: 'Profile',
            linkColor: ''
          },
          {
            linkUrl: '/settings/privacy',
            linkText: 'Settings & Privacy',
            linkIcon: 'Setting',
            linkColor: ''
          },
          {
            linkUrl: `/profile/${this.username}`,
            linkText: 'Share My Profile',
            linkIcon: 'Send',
            linkAction: 'share',
            linkColor: ''
          },
          {
            linkUrl: '/suggestions',
            linkText: 'Follow & Invite People',
            linkIcon: 'Message',
            linkColor: ''
          },
          {
            linkUrl: '/logout',
            linkText: 'Logout',
            linkIcon: 'Logout',
            linkColor: 'text-red-500'
          }
        ]
      }
    }
  },
  methods: {
    onClickOutside () {
      this.isActive = false
    }
  }
}
