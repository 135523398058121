
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'InputWithIcon',
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    isClickableIcon: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text',
      validator: type => ['text', 'email', 'number', 'password'].includes(type)
    },
    iconPosition: {
      type: String,
      default: 'left',
      validator: iconPosition => ['left', 'right'].includes(iconPosition)
    }
  },
  data () {
    return {
      id: null
    }
  },
  computed: {
    iconPositionClasses () {
      const iconClasses = {
        left: 'pl-10',
        right: 'pr-10'
      }

      return iconClasses[this.iconPosition] || iconClasses.left
    }
  },
  mounted () {
    this.id = uuidv4()
  },
  methods: {
    handleInput (e) {
      this.$emit('input', e.target.value)
    }
  }
}
