
export default {
  name: 'LazyImage',
  props: {
    src: {
      type: String,
      required: true
    }
  }
}
