
import { PushNotifications } from '@capacitor/push-notifications'
import { Toast } from '@capacitor/toast'
import { Capacitor } from '@capacitor/core'
import { mapMutations } from 'vuex'

export default {
  name: 'NotificationSubscriber',
  async mounted () {
    // setup fcm
    const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications')
    if (isPushNotificationsAvailable) {
      let permStatus = await PushNotifications.checkPermissions()
      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions()
      }

      if (permStatus.receive !== 'granted') {
        await Toast.show({
          text: 'Notifications permission denied!'
        })
        this.$websocket()
      } else {
        await PushNotifications.addListener('registration', (token) => {
          this.setFCMToken(token.value)
          this.$websocket()
        })
        await PushNotifications.register()
      }
    } else {
      this.$websocket()
    }
  },
  methods: {
    ...mapMutations('notifications', [
      'setFCMToken'
    ])
  }
}
