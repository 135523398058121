
import { landingOptions } from '~/utils/post-options'

import { getColumnName } from '@/utils/logbook'
import AddLogbookHeader from '@/components/manual-flight/AddLogbook/Header'
import ApproachesSelect from '@/components/manual-flight/Approaches/Select'
import SmallCheckbox from '@/components/manual-flight/AddLogbook/SmallCheckbox'
import IncreaseDecrease from '@/components/manual-flight/AddLogbook/IncreaseDecrease'
import InputWithLabel from '~/components/global/Form/InputWithLabel'
import SelectInstructor from '~/components/select-instructor/index.vue'
import SimpleTextarea from '@/components/global/Form/SimpleTextarea'

export default {
  name: 'AddLogbookDetails',
  components: {
    AddLogbookHeader,
    ApproachesSelect,
    IncreaseDecrease,
    InputWithLabel,
    SelectInstructor,
    SimpleTextarea,
    SmallCheckbox
  },
  data () {
    return {
      approaches: [],
      getColumnName,
      flight_number: '',
      flight_instructing_time: 'no',
      cross_country_flight: 'no',
      aircraft_class: 'asel',
      instructor: null,
      notes: '',
      options: landingOptions.map((option) => {
        return {
          label: getColumnName(option),
          slug: option,
          value: 0
        }
      })
    }
  },
  methods: {
    optionExists (slug) {
      return this.options.filter(option => option.slug === slug).length > 0
    },
    onAdd (slug) {
      if (!this.optionExists(slug)) {
        return
      }

      this.options = this.options.map((option) => {
        if (option.slug === slug) {
          option.value += 1
        }

        return option
      })

      this.$emit('options', this.options)
    },
    onDelete (slug) {
      if (!this.optionExists(slug)) {
        return
      }

      this.options = this.options.map((option) => {
        if (option.slug === slug && option.value > 0) {
          option.value -= 1
        }

        return option
      })

      this.$emit('options', this.options)
    },
    setCrossCountryFlight (value) {
      this.cross_country_flight = value
      this.$emit('ccFlight', value)
    },
    setAircraftClass (value) {
      this.aircraft_class = value
    },
    handleFlightNumber (value) {
      this.flight_number = value
      this.$emit('flightNumber', value)
    },
    onSaveApproaches (approaches) {
      this.approaches = approaches
      this.$emit('approaches', approaches)
    },
    onSelectInstructor (value) {
      this.instructor = value
      this.$emit('instructor', value)
    },
    handleNotes (value) {
      this.notes = value
      this.$emit('notes', value)
    }
  }
}
