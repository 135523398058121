
import Glass from '~/assets/images/icons/Glass.svg?inline'
import SelectMenuItem from '@/components/feed/create-post/SelectMenuItem'

const options = [
  {
    isActive: true,
    label: 'People',
    icon: 'account-circle',
    slug: 'people'
  },
  {
    isActive: false,
    label: 'Airports',
    icon: 'plane',
    slug: 'airports'
  }
]

export default {
  name: 'SearchField',
  components: {
    Glass,
    SelectMenuItem
  },
  props: {
    placeholder: {
      type: String,
      default: 'Search Following'
    }
  },
  data () {
    let selected = options[0]

    if (this.$route.name === 'search-airports') {
      selected = options[1]
    }

    return {
      isActive: false,
      isMenuActive: false,
      q: '',
      menuItems: options,
      selected
    }
  },
  mounted () {
    this.q = this.$route.query.q || ''
  },
  methods: {
    onSubmit () {
      this.$router.push(`/search/${this.selected?.slug || 'people'}/?q=${this.q}`)
    },
    onClickOutside () {
      this.isActive = false
      this.isMenuActive = false
    },
    setSelected (options) {
      const activeIndex = this.menuItems.findIndex(item => item.label === options)
      this.selected = this.menuItems[activeIndex]

      this.menuItems.map((item) => {
        item.isActive = item.label === options
        return item
      })

      this.isMenuActive = false
      this.$refs.searchField.focus()
    },
    setActive () {
      this.isActive = true
    }
  }
}
