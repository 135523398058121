
export default {
  name: 'FlightRoute',
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      chips: [],
      currentInput: ''
    }
  },
  mounted () {
    if (this.value.length > 0) {
      this.chips = this.value
    } else if (this.$refs.input instanceof HTMLInputElement) {
      this.$refs.input.focus()
    }
  },
  methods: {
    saveChip () {
      if (this.currentInput === '') {
        return
      }

      if (this.chips.includes(this.currentInput)) {
        this.currentInput = ''
        return
      }

      this.chips.push(this.currentInput)
      this.currentInput = ''

      this.$emit('input', this.chips)
    },
    deleteChip (index) {
      this.chips.splice(index, 1)
      this.$emit('input', this.chips)
    },
    backspaceDelete ({ which }) {
      which === 8 &&
      this.currentInput === '' &&
      this.chips.splice(this.chips.length - 1) &&
      this.$emit('input', this.chips)
    }
  }
}
