export const ANTARCTIC_BERMUDA_GEOJSON = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        type: 'antarctica',
        title: 'Antarctica',
        description: 'People who have turned off Current Airport'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -16.5234375,
              -78.83606545333527
            ],
            [
              20.0390625,
              -78.83606545333527
            ],
            [
              20.0390625,
              -74.1160468394894
            ],
            [
              -16.5234375,
              -74.1160468394894
            ],
            [
              -16.5234375,
              -78.83606545333527
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        type: 'bermuda',
        title: 'Bermuda',
        description: 'People who haven’t updated their Current Airport in the last 7 days.'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [
              // eslint-disable-next-line no-loss-of-precision
              -64.86465454101562,
              32.219772236460905
            ],
            [
              -78.9697265625,
              29.916852233070173
            ],
            [
              -66.412353515625,
              18.70869162255995
            ],
            [
              // eslint-disable-next-line no-loss-of-precision
              -64.86465454101562,
              32.219772236460905
            ]
          ]
        ]
      }
    }
  ]
}

export const DEFAULT_POLYGON_LAYER_STYLE = {
  type: 'fill',
  paint: {
    'fill-color': '#2957e7',
    'fill-opacity': 0.3
  },
  filter: ['==', '$type', 'Polygon']
}

export const DEFAULT_POINT_LAYER_STYLE = {
  type: 'circle',
  paint: {
    'circle-color': '#2957e7',
    'circle-opacity': 1,
    'circle-radius': 1
  },
  filter: ['!', ['has', 'point_count']]
}

export default {
  ANTARCTIC_BERMUDA_GEOJSON,
  DEFAULT_POLYGON_LAYER_STYLE,
  DEFAULT_POINT_LAYER_STYLE
}
