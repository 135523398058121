
import { approaches as allApproaches } from '@/config/approaches'
import DropDownIcon from '~/assets/images/icons/dropdown.svg?inline'
import IncreaseDecrease from '@/components/manual-flight/AddLogbook/IncreaseDecrease'
import ApproachesModal from '@/components/manual-flight/Approaches/Modal.vue'

export default {
  name: 'ApproachesSelect',
  components: {
    ApproachesModal,
    DropDownIcon,
    IncreaseDecrease
  },
  props: {
    approachesVal: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      allApproaches,
      approaches: [],
      isMultiSelectOpen: false,
      modal: false,
      multiSelectOptions: [],
      multiSelectOpenFor: ''
    }
  },
  watch: {
    approachesVal (val) {
      this.approaches = val
      val.forEach((approach) => {
        this.onApproachClicked(approach, false)
      })
    }
  },
  mounted () {
    this.approaches = this.approachesVal
    this.approachesVal.forEach((approach) => {
      this.onApproachClicked(approach, false)
    })
  },
  methods: {
    onAdd () {
      const approaches = this.allApproaches.filter(appr => !this.approaches.includes(appr))
      if (approaches.length === 0) {
        return
      }
      const apprToAdd = approaches[0]
      const newAppr = [...this.approaches]
      newAppr.push(apprToAdd)
      this.approaches = newAppr
      this.$emit('update:approaches', this.approaches)
    },

    onDelete () {
      if (this.approaches.length === 0) {
        return
      }

      this.approaches.pop()
      this.$emit('update:approaches', this.approaches)
    },

    onApproachClicked (approach, openModal = true) {
      const approacesToSelect = []
      approacesToSelect.push(approach)
      approacesToSelect.push(...this.allApproaches.filter(appr => !this.approaches.includes(appr)))

      this.multiSelectOpenFor = approach
      this.multiSelectOptions = [...approacesToSelect]
      this.isMultiSelectOpen = true
      this.modal = openModal
    },

    saveNewApproach (approach) {
      const index = this.approaches.indexOf(this.multiSelectOpenFor)
      this.approaches[index] = approach
    }
  }
}
