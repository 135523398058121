import { render, staticRenderFns } from "./AddLogbook.vue?vue&type=template&id=13cb2eb0&"
import script from "./AddLogbook.vue?vue&type=script&lang=js&"
export * from "./AddLogbook.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SelectInstructor: require('/opt/buildhome/repo/components/select-instructor/index.vue').default})
