/**
 *
 * util to access localStorgae
 */

// ACCESS KEYS
const getKeyForLogbookFilters = () => {
  return 'logbook-filters'
}
const getKeyForLogbookDateRange = () => {
  return 'logbook-date-range'
}
const getKeyForLogbookCustomDateRange = () => {
  return 'logbook-custom-date-range'
}
export function getKeyForLogbookColumns () {
  return 'defaultLogbookCol'
}

// get date from local storage
const getDataFromLocalStorage = (key) => {
  if (process.client) {
    const data = localStorage.getItem(key)
    if (data) {
      return JSON.parse(data)
    } else {
      return null
    }
  }
}
// add data to local storage
const saveItemInLocalStorage = (key, data) => {
  localStorage?.setItem(key, JSON.stringify(data))
}
// getters
export const getSavedLogbookDateRange = () => {
  return getDataFromLocalStorage(getKeyForLogbookDateRange())
}
export const getSavedLogbookFilters = () => {
  return getDataFromLocalStorage(getKeyForLogbookFilters())
}
export const getSavedLogbookCustomDateRange = () => {
  return getDataFromLocalStorage(getKeyForLogbookCustomDateRange())
}
export const getSavedLogbookColumns = () => {
  return getDataFromLocalStorage(getKeyForLogbookColumns())
}

// setters
export const saveLogbookFiltersInCache = (data) => {
  saveItemInLocalStorage(getKeyForLogbookFilters(), data)
}
export const saveLogbookDateRange = (data) => {
  saveItemInLocalStorage(getKeyForLogbookDateRange(), data)
}
export const savedLogbookCustomDateRange = (data) => {
  saveItemInLocalStorage(getKeyForLogbookCustomDateRange(), data)
}
export const savedLogbookColumns = (data) => {
  saveItemInLocalStorage(getKeyForLogbookColumns(), data)
}
