
import OptionPicker from '@/components/manual-flight/OptionPicker'

export default {
  name: 'ApproachesModal',
  components: {
    OptionPicker
  },
  props: {
    selected: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onSelected (value) {
      this.$emit('select', value)
    },
    close () {
      this.$emit('cancel')
    }
  }
}
