import { parseEndpointError } from '~/utils/helpers'

export const state = () => ({
  suggestions: [],
  followers: []
})

export const getters = {
  getSuggestions: state => state.suggestions,
  followers: state => state.followers
}

export const mutations = {
  setSuggestions (state, value) {
    state.suggestions = value
  },
  setFollowers (state, followers) {
    state.followers = followers
  },
  removeFollower (state, userId) {
    state.followers = state.followers.filter((follower) => {
      return follower.id !== userId
    })
  }
}

export const actions = {
  async fetchSuggested ({ commit }, limit = 100) {
    await this.$axios.$get('/user/following/suggested/', { params: { limit } })
      .then((response) => {
        commit('setSuggestions', [...response.results] || [])
      })
  },
  async deleteFollower ({ commit, state }, userId) {
    return await this.$axios.$delete(`/user/followers/${userId}/`).then(() => {
      commit('removeFollower', userId)
      return {
        success: true,
        message: 'Unfollowed successfully'
      }
    }).catch((e) => {
      const error = parseEndpointError(e.response.data)
      return {
        success: false,
        message: error
      }
    })
  },
  async followUser ({ commit, state }, { userId, status = 'accepted' }) {
    return await this.$axios.$patch(`/user/followers/${userId}/`, {
      status
    }).then(() => {
      return {
        success: true,
        message: 'Follower accepted'
      }
    }).catch((e) => {
      const error = parseEndpointError(e.response.data)

      return {
        success: false,
        message: error
      }
    })
  },
  async fetchFollowers  ({ commit }, limit = 100) {
    return await this.$axios.$get('/user/followers/')
      .then((response) => {
        commit('setFollowers', [...response.results] || [])
      })
  }
}
