import { parseEndpointError } from '~/utils/helpers'

export const state = () => ({
  searchResults: []
})

export const getters = {
  getSearchResults: state => state.searchResults
}

export const mutations = {
  setSearchResults (state, value) {
    state.searchResults = value
  }
}

export const actions = {
  async fetchSearchResults ({ commit }, query) {
    await this.$axios.$get('/user/public/search/', { params: { q: query, limit: 100 } })
      .then((response) => {
        commit('setSearchResults', [...response.results] || [])
      }).catch((e) => {
        const error = parseEndpointError(e.response.data)

        return {
          success: false,
          message: error
        }
      })
  }
}
