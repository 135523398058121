
import { mapMutations } from 'vuex'
import HomeLoginModal from './HomeLoginModal'
import LogoWhite from '~/assets/images/logo-white.svg?inline'
import LogoPrimary from '~/assets/images/logo-blue.svg?inline'
import MenuIcon from '~/assets/images/icons/MenuIcon.svg?inline'
import XIcon from '~/assets/images/icons/XIcon.svg?inline'

const navigation = [
  { name: 'Features', href: '/features' },
  { name: 'What\'s new', href: 'https://feedback.followingpilots.com/' },
  { name: 'About Us', href: '/about-us' },
  { name: 'Support', href: 'https://support.followingpilots.com/' }
]

export default {
  name: 'HomeHero',
  components: {
    MenuIcon,
    XIcon,
    LogoWhite,
    LogoPrimary,
    HomeLoginModal
  },
  props: {
    background: {
      type: [String],
      default: 'tranparent'
    }
  },
  data () {
    return {
      openMenu: false,
      navigation,
      siteName: this.title
    }
  },
  computed: {
    textColorClass () {
      return this.background === 'white' ? 'text-neutral-600' : 'text-white'
    },
    redirectURL () {
      return this.$route.fullPath
    }
  },
  methods: {
    ...mapMutations(['setSigninRedirect']),
    onLoginClicked () {
      this.setSigninRedirect(this.redirectURL)
      this.$router.push('/login/')
    }
  }
}
