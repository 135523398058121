
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'SelectNative',
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    selected: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      id: null
    }
  },
  mounted () {
    this.id = uuidv4()
  },
  methods: {
    handleInput (e) {
      this.$emit('input', e.target.value)
    }
  }
}
