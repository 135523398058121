
import { mapMutations } from 'vuex'
import LoginForm from '~/components/auth/LoginForm'

export default {
  name: 'HomeLoginModal',
  components: {
    LoginForm
  },
  data () {
    return {
      showModal: false
    }
  },
  computed: {
    isHome () {
      return this.$route.path === '/'
    },
    redirectURL () {
      return this.$route.fullPath
    }
  },
  methods: {
    ...mapMutations(['setSigninRedirect']),
    onLogin () {
      this.setSigninRedirect(this.redirectURL)
      this.showModal = true
    }
  }
}
