
import Checkbox from '@/components/global/Form/Checkbox'
import InputWithLabel from '@/components/global/Form/InputWithLabel'

export default {
  name: 'CheckboxWithInput',
  components: { Checkbox, InputWithLabel },
  props: {
    label: {
      type: String,
      default: ''
    },
    option: {
      type: String,
      default: ''
    },
    selected: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Number, String],
      default: 0
    }
  },
  methods: {
    handleInput (value) {
      this.$emit('input', value)
      this.$emit('inputWithOption', value, this.option)
    },
    select (option) {
      this.$emit('selected', this.option)
    }
  }
}
