export const aircraftClasses = [
  {
    value: 'airship',
    label: 'Airship'
  },
  {
    value: 'amel',
    label: 'Amel'
  },
  {
    value: 'ames',
    label: 'Ames'
  },
  {
    value: 'asel',
    label: 'Asel'
  },
  {
    value: 'ases',
    label: 'Ases'
  },
  {
    value: 'balloon',
    label: 'Balloon'
  },
  {
    value: 'glider',
    label: 'Glider'
  },
  {
    value: 'gyroplane',
    label: 'Gyroplane'
  },
  {
    value: 'helicopter',
    label: 'Helicopter'
  },
  {
    value: 'powered_lift',
    label: 'Powered Lift'
  },
  {
    value: 'powered_parachute_land',
    label: 'Powered Parachute Land'
  },
  {
    value: 'powered_parachute_sea',
    label: 'Powered Parachute Sea'
  },
  {
    value: 'weight_shift_control_land',
    label: 'Weight Shift Control Land'
  },
  {
    value: 'weight_shift_control_sea',
    label: 'Weight Shift Control Sea'
  }
]
