
export default {
  name: 'CustomInput',
  props: {
    showLabel: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      default: 'input-field'
    },
    value: {
      type: [String, Number],
      required: true
    },
    placeholder: {
      type: String,
      default: 'Input...'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: [Object, String, Boolean],
      default: false
    },
    icon: {
      type: String,
      default: ''
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
