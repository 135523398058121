
import { email, required } from 'vuelidate/lib/validators'
import EmailIcon from '~/assets/images/icons/EmailIcon.svg?inline'

export default {
  name: 'Email',
  components: {
    EmailIcon
  },
  props: {
    submitted: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  validations: {
    email: { required, email }
  },
  computed: {
    email: {
      get () {
        return this.$store.state.auth.email
      },
      set (value) {
        this.$store.commit('setAuthEmail', value)
      }
    }
  }
}
