
import { mapActions } from 'vuex'

import SelectAirplaneType from './AddNewAircraft/SelectAirplaneType'

import { aircraftClasses } from '@/config/aircraft-classes'
import { gearConfig as getGearConfigs } from '@/config/gear-config'
import { engineTypes } from '@/config/engine-types'
import SelectNative from '@/components/global/Form/SelectNative'
import InputWithLabel from '@/components/global/Form/InputWithLabel'
import ToggleWithLabel from '@/components/global/Form/ToggleWithLabel'
import Button from '@/components/global/Button'
import Preloader from '@/components/global/Preloader'
import { imageFileTypes, validFileType } from '@/helpers/files'
import CheckIcon from '@/components/my-profile/CheckIcon'
import ErrorIcon from '@/components/my-profile/ErrorIcon'
import Modal from '@/components/global/Modal'
import Spinner from '@/components/global/Spinner'

export default {
  name: 'EditMyAirplaneModal',
  components: {
    SelectNative,
    InputWithLabel,
    Button,
    ToggleWithLabel,
    Preloader,
    SelectAirplaneType,
    Modal,
    Spinner
  },
  props: {
    airplaneId: {
      type: Number,
      required: true
    },
    modal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      airplaneType: null,
      tailNumber: '',
      gearConfig: '',
      aircraftClass: '',
      engineType: '',
      highPerformance: false,
      complex: false,
      taa: false,
      tailWheel: false,
      file: '',
      fileData: '',
      loading: false,
      engineTypes,
      getGearConfigs,
      aircraftClasses
    }
  },
  mounted () {
    this.getAircraft(this.airplaneId).then((aircraft) => {
      this.make = aircraft?.type?.make
      this.shortName = aircraft?.type?.short_name
      this.model = aircraft?.type?.model
      this.gearConfig = aircraft?.gear_config
      this.aircraftClass = aircraft?.aircraft_class
      this.engineType = aircraft?.engine_type
      this.tailNumber = aircraft.tail_number
      this.airplaneType = aircraft
      this.taa = aircraft?.taa
      this.highPerformance = aircraft?.high_performance
      this.complex = aircraft?.complex
      this.file = aircraft?.image
      this.loading = false
    })
  },
  methods: {
    uploadFile (e) {
      this.loading = true
      setTimeout(() => {
        const file = e.target.files[0]
        if (!file && !validFileType({ file, imageFileTypes })) {
          return
        }
        this.fileData = file
        this.file = URL.createObjectURL(file)
        this.loading = false
        const filename = file.name.split('.').slice(0, -1).join('.') + Date.now()
        const options = {
          timestamp: Date.now(),
          public_id: filename
        }
        this.$emit('file-uploaded', options.public_id)
      }, 600)
    },
    removeImage () {
      this.file = ''
      this.fileData = ''
    },
    setAirplaneType (airplane) {
      this.airplaneType = airplane
      this.gearConfig = airplane?.gear_config
      this.aircraftClass = airplane?.aircraft_class
      this.engineType = airplane?.engine_type
      this.taa = airplane?.taa
      this.highPerformance = airplane?.high_performance
      this.complex = airplane?.complex
      this.tailWheel = airplane?.retractable
      this.$emit('airplaneType', airplane)
    },
    goToPrev () {
      this.$router.go(-1)
    },
    update () {
      if (this.tailNumber === '') {
        this.$toast.error('Tail number is required', {
          icon: ErrorIcon
        })
        return
      }
      if (this.aircraftClass === '') {
        this.$toast.error('Aircraft class is required', {
          icon: ErrorIcon
        })
        return
      }
      if (this.gearConfig === '') {
        this.$toast.error('Gear config is required', {
          icon: ErrorIcon
        })
        return
      }

      if (this.engineType === '') {
        this.$toast.error('Engine type is required', {
          icon: ErrorIcon
        })
        return
      }

      const args = {
        aircraft_class: this.aircraftClass,
        gear_config: this.gearConfig,
        tail_number: this.tailNumber,
        engine_type: this.engineType,
        high_performance: this.highPerformance,
        complex: this.complex,
        taa: this.taa,
        type_id: this.airplaneType.type.id,
        retractable: this.tailWheel
      }

      const file = this.fileData

      this.addAircraftPatch({
        aircraftId: this.airplaneId,
        data: args
      }).then((res) => {
        this.$toast.success('Airplane has been changed!', {
          icon: CheckIcon
        })

        if (file !== '') {
          const formData = new FormData()
          formData.append('image', file, file.name)
          this.addAircraftPatch({
            aircraftId: res.id,
            data: formData
          })
        } else {
          this.fetchAircraft()
        }

        this.$emit('cancel')
      })
    },
    handleTailNumber (value) {
      this.tailNumber = value
    },
    handleGearConfig (value) {
      this.gearConfig = value
    },
    handleAircraftClass (value) {
      this.aircraftClass = value
    },
    handleEngineType (value) {
      this.engineType = value
    },
    handleHighPerformance (value) {
      this.highPerformance = value
    },
    handleComplex (value) {
      this.complex = value
    },
    handleTaa (value) {
      this.taa = value
    },
    handleTailWheel (value) {
      this.tailWheel = value
    },
    ...mapActions('user/aircraft', [
      'addAircraftPatch',
      'getAircraft',
      'fetchAircraft'
    ])
  }
}
