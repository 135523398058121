
import { timeOptions } from '~/utils/post-options'
import AddLogbookHeader from '@/components/manual-flight/AddLogbook/Header'
import CheckboxWithInput from '@/components/manual-flight/AddLogbook/CheckboxWithInput'

export default {
  name: 'AddLogbook',
  components: { AddLogbookHeader, CheckboxWithInput },
  props: {
    time: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      checkboxesOptions: [],
      selected: '',
      value: 0
    }
  },
  mounted () {
    this.checkboxesOptions = timeOptions.map((option) => {
      return {
        value: this.time,
        label: option,
        selected: option === 'total_time'
      }
    })
  },
  methods: {
    handleInput (value) {
      this.value = value
      this.checkboxesOptions = this.checkboxesOptions.map((option) => {
        if (option.label === this.selected) {
          option.value = value
        }

        return option
      })
    },
    handleOptionClick (option) {
      this.selected = option
      this.checkboxesOptions = this.checkboxesOptions.map((option) => {
        if (option.label === this.selected) {
          option.selected = !option.selected
        }

        return option
      })
    },
    handleSubmit () {
      this.$emit('submit', this.checkboxesOptions)
    }
  }
}
