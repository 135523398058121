
import { mapActions } from 'vuex'

import SelectAirplaneType from './SelectAirplaneType'

import { aircraftClasses } from '@/config/aircraft-classes'
import { gearConfig as getGearConfigs } from '@/config/gear-config'
import { engineTypes } from '@/config/engine-types'
import SelectNative from '@/components/global/Form/SelectNative'
import InputWithLabel from '@/components/global/Form/InputWithLabel'
import ToggleWithLabel from '@/components/global/Form/ToggleWithLabel'
import Button from '@/components/global/Button'
import Preloader from '@/components/global/Preloader'
import { imageFileTypes, validFileType } from '@/helpers/files'
import CheckIcon from '@/components/my-profile/CheckIcon'
import ErrorIcon from '@/components/my-profile/ErrorIcon'

export default {
  name: 'AddNewAircraft',
  components: {
    SelectNative,
    InputWithLabel,
    Button,
    ToggleWithLabel,
    Preloader,
    SelectAirplaneType
  },
  props: {
    isSimulator: {
      type: Boolean,
      default: false
    },
    tail: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      airplaneType: null,
      tailNumber: this.tail || '',
      gearConfig: '',
      aircraftClass: '',
      engineType: '',
      highPerformance: false,
      complex: false,
      taa: false,
      tailWheel: false,
      file: '',
      fileData: '',
      loading: false,
      engineTypes,
      getGearConfigs,
      aircraftClasses
    }
  },
  methods: {
    uploadFile (e) {
      this.loading = true
      setTimeout(() => {
        const file = e.target.files[0]
        if (!file && !validFileType({ file, imageFileTypes })) {
          return
        }
        this.fileData = file
        this.file = URL.createObjectURL(file)
        this.loading = false
        const filename = file.name.split('.').slice(0, -1).join('.') + Date.now()
        const options = {
          timestamp: Date.now(),
          public_id: filename
        }
        this.$emit('file-uploaded', options.public_id)
      }, 600)
    },
    removeImage () {
      this.file = ''
      this.fileData = ''
    },
    setAirplaneType (airplane) {
      this.airplaneType = airplane
      this.gearConfig = airplane ? airplane.gear_config : undefined
      this.aircraftClass = airplane ? airplane.aircraft_class : undefined
      this.engineType = airplane ? airplane.engine_type : undefined
      this.taa = airplane ? airplane.taa : undefined
      this.highPerformance = airplane ? airplane.high_performance : undefined
      this.complex = airplane ? airplane.complex : undefined
      this.$emit('airplaneType', airplane)
    },
    goToPrev () {
      this.$router.go(-1)
    },
    update () {
      if (this.tailNumber === '') {
        this.$toast.error('Tail number is required', {
          icon: ErrorIcon
        })
        return
      }
      if (this.aircraftClass === '') {
        this.$toast.error('Aircraft class is required', {
          icon: ErrorIcon
        })
        return
      }
      if (this.gearConfig === '') {
        this.$toast.error('Gear config is required', {
          icon: ErrorIcon
        })
        return
      }

      if (this.engineType === '') {
        this.$toast.error('Engine type is required', {
          icon: ErrorIcon
        })
        return
      }

      const args = {
        aircraft_class: this.aircraftClass,
        gear_config: this.gearConfig,
        tail_number: this.tailNumber,
        engine_type: this.engineType,
        high_performance: this.highPerformance,
        is_simulator: this.isSimulator,
        complex: this.complex,
        taa: this.taa,
        retractable: this.airplaneType ? this.airplaneType.retractable : undefined,
        type_id: this.airplaneType ? this.airplaneType.id : undefined
      }

      const file = this.fileData

      this.addAircraft(args).then((res) => {
        this.$toast.success('Airplane has been added!', {
          icon: CheckIcon
        })

        if (file !== '') {
          const formData = new FormData()
          formData.append('image', file, file.name)
          this.addAircraftPatch({
            aircraftId: res.id,
            data: formData
          })
        } else {
          this.fetchAircraft()
        }

        this.$emit('update:aircraft', res)
        this.$emit('newPlaneAdded', res)
        this.$emit('cancel')
      })
    },
    handleTailNumber (value) {
      this.tailNumber = value
    },
    handleGearConfig (value) {
      this.gearConfig = value
    },
    handleAircraftClass (value) {
      this.aircraftClass = value
    },
    handleEngineType (value) {
      this.engineType = value
    },
    handleHighPerformance (value) {
      this.highPerformance = value
    },
    handleComplex (value) {
      this.complex = value
    },
    handleTaa (value) {
      this.taa = value
    },
    handleTailWheel (value) {
      this.tailWheel = value
    },
    ...mapActions('user/aircraft', [
      'addAircraft',
      'addAircraftPatch',
      'fetchAircraft'
    ])
  }
}
