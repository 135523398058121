import { parseEndpointError } from '@/utils/helpers'

export const state = () => ({
  data: {},
  items: [],
  nextUrl: '',
  prevUrl: '',
  count: 0
})

export const getters = {
  getFollowing: state => state.items
}

export const mutations = {
  setCount: (state, count) => {
    state.count = count
  },
  setData: (state, data) => {
    state.data = data
  },
  setFollowing: (state, data) => {
    state.items = data
  },
  setNextUrl: (state, nextUrl) => {
    state.nextUrl = nextUrl
  },
  setPrevUrl: (state, prevUrl) => {
    state.prevUrl = prevUrl
  }
}

export const actions = {
  async fetchFollowing ({ commit }, q = '') {
    await this.$axios.$get('/user/following/', {
      params: {
        q: q || '',
        offset: 0,
        limit: 0,
        status: 'accepted'
      }
    }).then((response) => {
      commit('setCount', response.count || 0)
      commit('setData', response || {})
      commit('setFollowing', response.results || [])
      commit('setNextUrl', response.next || '')
      commit('setPrevUrl', response.previous || '')
    })
  },

  async getFriendsLocations ({ commit, state }) {
    try {
      const response = await this.$axios.$get('/user/following/locations/')
      return response.results
    } catch (e) {
      throw parseEndpointError(e.response.data)
    }
  },

  async searchFriends ({ commit },
    {
      query = '',
      limit = 20,
      offset = 0,
      status = 'accepted'
    }) {
    try {
      const response = await this.$axios.$get('/user/following/', { params: { q: query, limit, offset, status } })
      return response.results
    } catch (e) {
      throw parseEndpointError(e.response.data)
    }
  }
}
