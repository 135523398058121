export const state = () => ({
  notificationOtp: null
})

export const mutations = {
  setNotificationOtp: (state, response) => {
    state.notificationOtp = response
  }
}

export const actions = {
  async fetchNotificationOtp ({ state, commit }, force = false) {
    if (state.notificationOtp !== null && !force) {
      return state.notificationOtp
    }

    return await this.$axios.$post('/auth/otp').then((response) => {
      commit('setNotificationOtp', response)
      return response
    })
  }
}
