export default async function ({ app }) {
  if (!app.$auth.loggedIn) {
    return
  }

  const auth = app.$auth
  const authStrategy = auth.strategy.name

  if (authStrategy === 'facebook' || authStrategy === 'google') {
    try {
      const strategyToken = await auth.strategy.token.get()
      if (typeof strategyToken === 'undefined' || !strategyToken.length) {
        return
      }

      const token = strategyToken.split(' ')[1]

      let url

      if (authStrategy === 'google') {
        url = '/user/social-login/google-ios/'
      } else {
        url = `/user/social-login/${authStrategy}/`
      }

      await app.$axios.$post(url, {
        access_token: token
      }).then(async (response) => {
        const token = await response.token
        if (typeof token === 'undefined' || !token.length) {
          return
        }

        await auth.setStrategy('local')
        await auth.setUserToken(token, auth.strategy.refreshToken)

        const user = await auth.fetchUser()
        if (user.data) {
          await auth.setUser(user.data)
        }
      }).catch((e) => {
        auth.getStrategy().logout()
        // eslint-disable-next-line no-console
        console.error(e)
      })
    } catch (e) {
      auth.getStrategy().logout()
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }
}
