
import FlightRoute from '~/components/global/FlightRoute'

export default {
  name: 'RouteField',
  components: {
    FlightRoute
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showInput: {
      get () {
        return this.show
      },
      set (value) {
        this.$emit('show', value)
      }
    }
  },
  methods: {
    onInput (value) {
      this.$emit('input', value)
    }
  }
}
