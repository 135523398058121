
import Header from '~/components/header/Header'

export default {
  components: {
    Header
  },
  data () {
    return {
      currentYear: new Date().getFullYear()
    }
  },
  mounted () {
    this.$websocket()
  }
}
