import { getNotification } from '~/utils/helpers'

/**
 * @returns Group notifications by date.
 * @param notifications
 */
function groupByDate (notifications) {
  const today = new Date()

  const grouped = notifications.reduce((map, notification) => {
    const date = new Date(notification?.timestamp * 1000)
    const diff = today.getTime() - date.getTime()
    let selector = ''

    if (date.getTime() === today.getTime()) {
      selector = 'Today'
    } else if (diff <= (7 * 24 * 60 * 60 * 1000)) {
      selector = 'This week'
    } else {
      const dayName = date.toLocaleString('en-US', { weekday: 'long' }).toString()
      const month = date.toLocaleString('en-US', { month: 'long' }).toString()

      selector = `${dayName}, ${date.getDate()} ${month}`
    }

    notification = getNotification(notification)

    map[selector] = map[selector] ? [...(map[selector]), notification] : [notification]

    return map
  }, {})

  return Object.keys(grouped).map(key => ({
    key,
    values: grouped[key]
  }))
}

export const state = () => ({
  data: {},
  latestNotification: {},
  notifications: [],
  fcmToken: null,
  count: 0
})
export const getters = {
  getCount: state => state.count,

  getNext: state => state.data?.next || null,

  getNotifications: state => (filterBy) => {
    let notifications = state.notifications
    if (!notifications.length) {
      return []
    }

    if (filterBy === 'unread') {
      notifications = notifications.filter(notification => notification.unread)
    }

    if (!notifications.length) {
      return []
    }

    return groupByDate(notifications)
  },

  getLatestNotification: state => getNotification(state.latestNotification),

  getFCMToken: state => state.fcmToken
}

export const mutations = {
  setCount: (state, count) => {
    state.count = count
  },

  increaseCount: (state) => {
    state.count += 1
  },

  setData: (state, data) => {
    state.data = data
  },

  setNotifications: (state, notifications) => {
    state.notifications = notifications
  },

  addNotification: (state, notification) => {
    state.latestNotification = notification
    state.notifications = [notification, ...state.notifications]
  },

  setFCMToken: (state, token) => {
    state.fcmToken = token
  }
}

export const actions = {
  async getNotificationsCount ({ commit }) {
    await this.$axios.$get('/notifications/unread-count/').then((response) => {
      commit('setCount', response.count || 0)
    })
  },
  async getNotifications ({ commit, state }, loadNext = false) {
    let url = '/notifications/?offset=0&limit=20'
    if (loadNext && state.data?.next) {
      url = state.data.next
    }

    return await this.$axios.$get(url).then((response) => {
      commit('setData', response || {})

      if (loadNext) {
        commit('setNotifications', [...state.notifications, ...response.results])
      } else {
        commit('setNotifications', response.results)
      }

      return response
    })
  },
  async markAllAsRead ({ commit }) {
    await this.$axios.$post('/notifications/mark-all-as-read/').then(() => {
      commit('setCount', 0)
    })
  },
  async deleteNotification ({ commit, state, dispatch }, notificationId) {
    dispatch('notifications/getNotifications', '', { root: true })
    return await this.$axios.$delete(`/notifications/${notificationId}/`)
  },
  increaseNotificationsCount ({ commit }) {
    commit('increaseCount')
  }
}
