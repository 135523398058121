export const state = () => ({
  data: {},
  instructors: [],
  nextUrl: '',
  prevUrl: '',
  count: 0
})
export const getters = {
  getInstructors: state => state.instructors
}

export const mutations = {
  setCount: (state, count) => {
    state.count = count
  },
  setData: (state, data) => {
    state.data = data
  },
  setInstructors: (state, data) => {
    state.instructors = data
  },
  setNextUrl: (state, nextUrl) => {
    state.nextUrl = nextUrl
  },
  setPrevUrl: (state, prevUrl) => {
    state.prevUrl = prevUrl
  },
  addInstructor (state, value) {
    state.instructors.unshift(value)
  },
  updateInstructor (state, updatedIns) {
    state.instructors = state.instructors.map((instructor) => {
      if (updatedIns.id === instructor.id) {
        return updatedIns
      } else {
        return instructor
      }
    })
  },
  deleteInstructor (state, instructorId) {
    state.instructors = state.instructors.filter((ins) => {
      return instructorId !== ins.id
    })
  }
}

export const actions = {
  async fetchInstructors ({ commit }, offset = 0, limit = 0) {
    await this.$axios.$get('user/instructor/', {
      params: {
        offset: offset || 0,
        limit: limit || 0
      }
    }).then((response) => {
      commit('setCount', response.count || 0)
      commit('setData', response || {})
      commit('setInstructors', response.results || [])
      commit('setNextUrl', response.next || '')
      commit('setPrevUrl', response.previous || '')
    })
  },
  async fetchUserInstructor ({ commit }) {
    return await this.$axios.$get('user/instructor/me/').then((response) => {
      return response
    })
  },
  async patchUserInstructor ({ commit }, instructor) {
    const id = instructor.id
    delete instructor.id
    return await this.$axios.$put(`user/instructor/${id}/`, instructor).then((response) => {
      return response
    })
  },
  async addInstructor ({ commit }, instructor) {
    return await this.$axios.$post('user/instructor/', instructor).then((response) => {
      commit('addInstructor', response || {})
    })
  },
  async updateInstructor ({ commit }, instructor) {
    return await this.$axios.$patch(`user/instructor/${instructor.id}/`, instructor).then((response) => {
      commit('updateInstructor', response || {})
    })
  },
  async deleteInstructor ({ commit }, instructor) {
    return await this.$axios.$patch(`user/instructor/${instructor.id}/`, {
      deleted: true,
      email: instructor.email,
      first_name: instructor.first_name,
      last_name: instructor.last_name,
      instructor_number: instructor.instructor_number,
      certificate_expiration: instructor.certificate_expiration
    }).then((response) => {
      commit('deleteInstructor', instructor.id)
    })
  }

}
