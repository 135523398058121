
import { mapActions } from 'vuex'
import Button from '~/components/global/Button.vue'

export default {
  name: 'BlockUser',
  components: { Button },

  computed: {
    user () {
      return this.$store.state.modals.blockUser.user
    },
    open () {
      return this.$store.state.modals.blockUser.open
    },
    name () {
      return this.user.first_name
    },
    imgURL () {
      return this.user.picture_url
    }
  },
  methods: {
    ...mapActions([
      'blockUser'
    ]),
    onBlockConfirm () {
      this.blockUser(this.user.id).then((response) => {
        this.toggle(false)
      })
    },
    toggle (state) {
      this.$store.commit('toggleModalState', { key: 'blockUser', value: state })
    }
  }
}
