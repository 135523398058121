export const state = () => ({
  count: 0,
  posts: [],
  response: {}
})

export const getters = {
  getPosts: state => state.posts,
  getCount: state => state.count,
  getNext: state => state.response?.next
}

export const mutations = {
  setPosts (state, value) {
    state.posts = value
  },

  setCount (state, value) {
    state.count = value
  },

  setResponse (state, value) {
    state.response = value
  }
}

export const actions = {
  async fetchPosts ({ commit, state }, { user, limit = 20, offset = 0 }) {
    let url = `/user/public/${user}/posts/?limit=${limit}&offset=${offset}`
    if (state.response?.next) {
      url = state.response.next
    }

    return await this.$axios.$get(url)
      .then((response) => {
        commit('setCount', response.count || 0)

        if (state.response?.next) {
          commit('setPosts', [...state.posts, ...response.results])
        } else {
          commit('setPosts', response.results || [])
        }

        commit('setResponse', response || {})
        return response
      })
  }
}
